<template>
	<div>
		<a-spin :spinning="loading">
		<div class="ui-headerStyle">
			<div class="ui-stepStyle">
				<a-steps :current="current">
					<a-step v-for="(item,index) in steps" :key="item.title" :title="item.title" />
				</a-steps>
			</div>
			<!-- <div class="ui-stepsTip" style="margin-top: 80px;"> -->
			<!-- <div class="ui-stepsTip">
				<a-button type="primary" @click="onPreviewBtn">预览全部/取消预览</a-button>
			</div> -->
			<a-tabs v-model:activeKey="current" style="margin-top: 10px;" v-if="!isPreview">
				<a-tab-pane v-for="(item,index) in steps" :key="index" :tab="item.title"
					:disabled="index > projectStage"></a-tab-pane>
			</a-tabs>
			<!-- <p v-if="!isPreview" style="margin: 0;">单位：元</p> -->
			<!-- </div> -->
		</div>
		<a-back-top />
		<div class="ui-height2"></div>
		<!-- 整体预览 -->

		<!-- 基本信息录入 -->
		<div class="steps-content" v-if="steps[current].key == 'addInfo'">
			<!-- :rules="formProjectInfo_rules" -->
			<a-spin :spinning="baseInfoLoading">
				<a-form :model="formProjectInfo" ref="formProjectInfoRef" :rules="formProjectInfoRules">
					<h3 style="text-align: center;">项目基本情况</h3>
					<a-row :gutter="24">
						<a-col :span="12">
							<a-form-item label="项目名称" name="name">
								<!--  && current < projectStage -->
								<a-input placeholder="请输入项目名称" v-model:value="formProjectInfo.name"
									:disabled="isModify && current < projectStage"></a-input>
							</a-form-item>
							<a-form-item label="主管单位" name="mangerUnitId">
								<a-select v-model:value="formProjectInfo.mangerUnitId" placeholder="请选择单位"
									:disabled="isModify && current < projectStage">
									<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id"
										:value="item.id">{{ item.name }}</a-select-option>
								</a-select>
							</a-form-item>
							<a-form-item label="主管单位联系人电话" name="mangerUserPhone">
								<a-input placeholder="请输入主管单位联系人电话" v-model:value="formProjectInfo.mangerUserPhone"
									:disabled="isModify && current < projectStage"></a-input>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="项目分类" required name="classify">
								<a-cascader v-model:value="formProjectInfo.classify" :options="options"
									placeholder="请选择分类" :disabled="isModify && current < projectStage" />
								<!-- <a-cascader v-model:value="formProjectInfo.classify" :options="options" placeholder="请选择分类"
									:disabled="isModify" /> -->
							</a-form-item>
							<a-form-item label="主管单位联系人" required name="mangerUsername">
								<a-input placeholder="请输入主管单位联系人姓名" v-model:value="formProjectInfo.mangerUsername"
									:disabled="isModify && current < projectStage"></a-input>
							</a-form-item>
							<a-form-item label="启动时间" required name="bootTime">
								<a-date-picker placeholder="同意启动的会议或文件时间" v-model:value="formProjectInfo.bootTime"
									:disabled="isModify && current < projectStage"></a-date-picker>
							</a-form-item>
						</a-col>
					</a-row>
					<a-form-item label="项目基本情况" name="description">
						<a-textarea v-model:value="formProjectInfo.description" placeholder="请填写项目主要建设内容" :rows="4"
							:disabled="isModify && current < projectStage" />
					</a-form-item>
					<h3 style="text-align: center;">实施单位信息</h3>
					<a-row :gutter="24">
						<a-col :span="12">
							<a-form-item label="实施单位" required name="executeUnitId">
								<a-select v-model:value="formProjectInfo.executeUnitId" placeholder="请选择实施单位" disabled>
									<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id"
										:value="item.id">{{ item.name }}</a-select-option>
								</a-select>
								<!-- <a-select v-model:value="formProjectInfo.conductUnit" placeholder="请选择主管单位" :disabled="isModify && current < projectStage">
				                    <a-select-option value="单位1">单位1</a-select-option>
				                    <a-select-option value="单位2">单位2</a-select-option>
				                    <a-select-option value="单位3">单位3</a-select-option>
				                </a-select> -->
							</a-form-item>
							<a-form-item label="项目负责人姓名" name="headUsername">
								<a-input v-model:value="formProjectInfo.headUsername" placeholder="请输入项目负责人姓名"
									:disabled="isModify && current < projectStage"></a-input>
							</a-form-item>
							<a-form-item label="分管领导姓名" name="leaderUsername">
								<a-input v-model:value="formProjectInfo.leaderUsername" placeholder="请输入分管领导姓名"
									:disabled="isModify && current < projectStage"></a-input>
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item></a-form-item>
							<a-form-item label="项目负责人电话" name="headUserPhone">
								<a-input v-model:value="formProjectInfo.headUserPhone" placeholder="请输入项目负责人电话"
									:disabled="isModify && current < projectStage"></a-input>
							</a-form-item>
							<a-form-item label="分管领导电话" name="leaderPhone">
								<a-input v-model:value="formProjectInfo.leaderPhone" placeholder="请输入分管领导电话"
									:disabled="isModify && current < projectStage"></a-input>
							</a-form-item>
						</a-col>
					</a-row>
				</a-form>

			</a-spin>
		</div>
		<!-- 建设用地储备 -->
		<div class="steps-content" v-if="steps[current].key == 'land'">
			<a-spin :spinning="landInfoLoading">
				<!-- <a-form :model="formUseLand" ref="formUseLandRef" :rules="formUseLandRules"> -->
				<a-form :model="formUseLand" ref="formUseLandRef" :rules="formUseLandRules" scrollToFirstError>
					<div>
						<p class="ui-title">
							<span class="ui-tipsStyle">*</span>
							是否涉及新增用地
							<Icon icon="QuestionCircleOutlined" class="ui-icon-style" />
						</p>
						<a-form-item>
							<a-radio-group v-model:value="formUseLand.flag.buildLandFlagHasStage"
								:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'buildLandFlagHasStage')">
								<a-radio :value="0">涉及新增用地</a-radio>
								<a-radio :value="1">不涉及新增用地</a-radio>
								<a-radio :value="2">存量建设用地</a-radio>
							</a-radio-group>
							<a-textarea style="margin-top:20px;" v-if="formUseLand.flag.buildLandFlagHasStage !== 0" v-model:value="formUseLand.flag.buildLandFlagNotes" placeholder="请输入备注" :rows="4"
							:disabled="isModify && current < projectStage" />
						</a-form-item>
					</div>
					<div v-show="formUseLand.flag.buildLandFlagHasStage == 0">
						<!-- 1、建设用地来源 -->
					<div>
						<p class="ui-title">
							<span class="ui-tipsStyle">*</span>
							1、建设用地来源
							<Icon icon="QuestionCircleOutlined" class="ui-icon-style" />
						</p>
						<!-- <a-form-item name="buildLandSourceType" :rules="[{required:true,message:'请选择'}]"> -->
						<a-form-item :name="['source', 'buildLandSourceType']">
							<a-radio-group v-model:value="formUseLand.source.buildLandSourceType"
								:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'buildLandSourceType')">
								<a-radio :value="0">乡镇征收</a-radio>
								<a-radio :value="1">自然资源储备中心</a-radio>
								<a-radio :value="2">从单位或公司划拨</a-radio>
							</a-radio-group>
						</a-form-item>
						<div class="ui-styleBox"
							v-if="formUseLand.source.buildLandSourceType == 0 || formUseLand.source.buildLandSourceType == null">
							<a-row>
								<a-col :span="8">
									<a-form-item label="请上传征收方案" :name="['source', 'buildLandSourcePlanFile']" :rules="[{ required: true, message: '请上传征收方案' }]">
										<div style="display: flex;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formUseLand.source.buildLandSourcePlanFile"
												name="建设用地征收方案"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="乡镇是否是项目实施单位">
										<a-radio-group v-model:value="formUseLand.source.buildLandUniType"
											:disabled="isModify && current < projectStage">
											<a-radio :value="0">是</a-radio>
											<a-radio :value="1">否</a-radio>
										</a-radio-group>
									</a-form-item>
								</a-col>
								<a-col :span="8">
									<a-form-item label="请上传征地影像示意图" :name="['source','buildLandSourceImageFile']"
										:rules="[{ required: true, message: '请上传征地影像示意图' }]">
										<div style="display: flex;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formUseLand.source.buildLandSourceImageFile"
												name="建设用地征地影像示意图"
											>
											</filesUpload>
										</div>
									</a-form-item>
								</a-col>
							</a-row>

							<p class="ui-styleBox-title" v-if="formUseLand.source.buildLandUniType == 0">征拆资金凭证</p>
							<p class="ui-styleBox-title" v-if="formUseLand.source.buildLandUniType == 1">
								乡镇与项目实施单位移交建设用地的手续</p>
							<div class="ui-contentBorder" v-if="formUseLand.source.buildLandUniType == 0">
								<a-row :gutter="24">
									<a-col :span="8">
										<a-form-item label="征拆总金额">
											{{ inputNumberFormat(countAllPrice('buildLandSourceTotalCost','source','buildLandSourceSpecialCost','buildLandSourceSourceCost','buildLandSourceCompanyCost','buildLandSourceOtherCost')) }}
										</a-form-item>
										<a-form-item label="上级专项资金" :name="['source','buildLandSourceSpecialCost']"
											required>
											<a-input-number :min="0"
												v-model:value="formUseLand.source.buildLandSourceSpecialCost"
												class="ui-inputNumWidth"
												:formatter="inputNumberFormat"
												:disabled="isModify && current < projectStage">
											</a-input-number>元
										</a-form-item>
										<a-form-item label="县本级财政资金" :name="['source','buildLandSourceSourceCost']"
											required>
											<a-input-number :min="0"
												v-model:value="formUseLand.source.buildLandSourceSourceCost"
												class="ui-inputNumWidth"
												:formatter="inputNumberFormat"
												:disabled="isModify && current < projectStage">
											</a-input-number>元
										</a-form-item>
										<a-form-item label="请上传支付凭证" :name="['source','buildLandSourcePaymentFile']"
											required>
											<div style="display: flex;">
												<Icon icon="ExclamationCircleOutlined" class="ui-icon-style" />
												<filesUpload :disabled="isModify && current < projectStage"
													v-model:value="formUseLand.source.buildLandSourcePaymentFile"
													name="建设用地支付凭证"
												>
												</filesUpload>
											</div>
										</a-form-item>
									</a-col>
									<a-col :span="8">
										<a-form-item label=""></a-form-item>
										<a-form-item label="县国资公司" :name="['source','buildLandSourceCompanyCost']"
											required>
											<a-input-number :min="0"
												v-model:value="formUseLand.source.buildLandSourceCompanyCost"
												class="ui-inputNumWidth"
												:formatter="inputNumberFormat"
												:disabled="isModify && current < projectStage">
											</a-input-number>元
										</a-form-item>
										<a-form-item label="其他来源资金" :name="['source','buildLandSourceOtherCost']"
											required>
											<a-input-number :min="0"
												v-model:value="formUseLand.source.buildLandSourceOtherCost"
												class="ui-inputNumWidth"
												:formatter="inputNumberFormat"
												:disabled="isModify && current < projectStage">
											</a-input-number>元
										</a-form-item>
									</a-col>
								</a-row>
							</div>
							<div class="ui-contentBorder" v-if="formUseLand.source.buildLandUniType == 1">
								<a-row :gutter="24">
									<a-col :span="6">
										<a-form-item label="请上传建设用地交接文书"
											:name="['source','buildLandSourceHandoverFile']" required>
											<div style="display: flex;">
												<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
												<filesUpload :disabled="isModify && current < projectStage"
													v-model:value="formUseLand.source.buildLandSourceHandoverFile"
													name="建设用地交接文书"
												>
												</filesUpload>
											</div>
										</a-form-item>
									</a-col>
									<a-col :span="6">
										<a-form-item label="请上传征拆资金支出汇总表"
											:name="['source','buildLandSourceSummaryFile']" required>
											<div style="display: flex;">
												<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
												<filesUpload :disabled="isModify && current < projectStage"
													v-model:value="formUseLand.source.buildLandSourceSummaryFile"
													name="建设用地征拆资金支出汇总表"
												>
												</filesUpload>
											</div>
										</a-form-item>
									</a-col>
								</a-row>
							</div>
						</div>
						<div class="ui-contentBorder" v-if="formUseLand.source.buildLandSourceType == 1 ">
							<a-form-item label="请上传征地影像示意图" :name="['source','buildLandSourceImageFile']" required>
								<div style="display: flex;">
									<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
									<filesUpload :disabled="isModify && current < projectStage"
										v-model:value="formUseLand.source.buildLandSourceImageFile"
										name="建设用地征地影像示意图"
									>
									</filesUpload>
								</div>
							</a-form-item>
						</div>
						<div class="ui-contentBorder" v-if="formUseLand.source.buildLandSourceType == 2 ">
							<a-form-item label="请上传土地划拨文件" :name="['source','buildLandSourceTransferFile']" required>
								<div style="display: flex;">
									<Icon icon="ExclamationCircleOutlined" class="ui-icon-style" />
									<filesUpload :disabled="isModify && current < projectStage"
										v-model:value="formUseLand.source.buildLandSourceTransferFile"
										name="建设用地土地划拨文件"
									>
									</filesUpload>
								</div>
							</a-form-item>
							<a-form-item label="请上传征地影像示意图" :name="['source','buildLandSourceImageFile']" required>
								<div style="display: flex;">
									<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
									<filesUpload :disabled="isModify && current < projectStage"
										v-model:value="formUseLand.source.buildLandSourceImageFile"
										name="建设用地征地影像示意图"
									>
									</filesUpload>
								</div>
							</a-form-item>
						</div>
					</div>
					<!-- 2.土地性质 -->
					<div>
						<p class="ui-title">
							<span class="ui-tipsStyle">*</span>
							2、土地性质
							<Icon icon="QuestionCircleOutlined" class="ui-icon-style" />
						</p>
						<a-form-item :name="['nature', 'buildLandNatureHasForestry']">
							<a-radio-group v-model:value="formUseLand.nature.buildLandNatureHasForestry"
								:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'buildLandNatureHasForestry')">
								<a-radio :value="0">涉及林业用地</a-radio>
								<a-radio :value="1">不涉及林业用地</a-radio>
							</a-radio-group>
						</a-form-item>
						<div class="ui-styleBox"
							v-if="formUseLand.nature.buildLandNatureHasForestry == 0 || formUseLand.nature.buildLandNatureHasForestry == null">
							<a-row>
								<a-col :span="8">
									<a-form-item label="请上传使用林地可行性报告" :name="['nature', 'buildLandNatureReportFile']" :rules="[{ required: true, message: '请上传使用林地可行性报告' }]">
										<div style="display: flex;">
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formUseLand.nature.buildLandNatureReportFile"
												name="土地性质使用林地可行性报告"
											>
											</filesUpload>
										</div>
									</a-form-item>
								</a-col>
								<a-col :span="8">
									<a-form-item label="请上传使用林地审核同意书" :name="['nature','buildLandNatureAuditFile']"
										:rules="[{ required: true, message: '请上传使用林地审核同意书' }]">
										<div style="display: flex;">
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formUseLand.nature.buildLandNatureAuditFile"
												name="土地性质使用林地审核同意书" :limit="1"
											>
											</filesUpload>
											<a-form-item>
												<a-date-picker style="width: 180px" v-model:value="formUseLand.nature.buildLandNatureAuditTime" :value-format="'X'"
												:disabled="(!formUseLand.nature?.buildLandNatureAuditFile) || formUseLand.nature?.buildLandNatureAuditFile.length == 0"/>
											</a-form-item>
										</div>
									</a-form-item>
								</a-col>
							</a-row>
							<p class="ui-styleBox-title">林地测绘费用</p>
							<div class="ui-contentBorder">
								<a-row :gutter="24">
									<a-col :span="8">
										<a-form-item label="测绘费用总金额" :name="['nature','buildLandNatureMapCost']">
											{{ inputNumberFormat(countAllPrice('buildLandNatureMapCost','nature','buildLandNatureMapSpecialCost','buildLandNatureMapCompanyCost','buildLandNatureMapSourceCost','buildLandNatureMapOtherCost')) }}元
										</a-form-item>
										<a-form-item label="上级专项资金" :name="['nature','buildLandNatureMapSpecialCost']"
											required>
											<a-input-number :min="0"
												v-model:value="formUseLand.nature.buildLandNatureMapSpecialCost"
												class="ui-inputNumWidth"
												:formatter="inputNumberFormat"
												:disabled="isModify && current < projectStage">
											</a-input-number>元
										</a-form-item>
										<a-form-item label="县本级财政资金" :name="['nature','buildLandNatureMapSourceCost']"
											required>
											<a-input-number :min="0"
												v-model:value="formUseLand.nature.buildLandNatureMapSourceCost"
												class="ui-inputNumWidth"
												:formatter="inputNumberFormat"
												:disabled="isModify && current < projectStage">
											</a-input-number>元
										</a-form-item>
										<a-form-item label="请上传发票" :name="['nature','buildLandNatureMapTicket']" required>
											<div style="display: flex;">
												<filesUpload :disabled="isModify && current < projectStage"
													v-model:value="formUseLand.nature.buildLandNatureMapTicket"
													name="林地测绘费用发票"
												>
												</filesUpload>
											</div>
										</a-form-item>
									</a-col>
									<a-col :span="8">
										<a-form-item></a-form-item>
										<a-form-item label="县国资公司" :name="['nature','buildLandNatureMapCompanyCost']" required>
											<a-input-number :min="0"
												v-model:value="formUseLand.nature.buildLandNatureMapCompanyCost"
												class="ui-inputNumWidth"
												:formatter="inputNumberFormat"
												:disabled="isModify && current < projectStage">
											</a-input-number>元
										</a-form-item>
										<a-form-item label="其他来源资金" :name="['nature','buildLandNatureMapOtherCost']" required>
											<a-input-number :min="0"
												v-model:value="formUseLand.nature.buildLandNatureMapOtherCost"
												class="ui-inputNumWidth"
												:formatter="inputNumberFormat"
												:disabled="isModify && current < projectStage">
											</a-input-number>元
										</a-form-item>
										<a-form-item label="请上传支付凭证" :name="['nature','buildLandNatureMapPaymentFile']" required>
											<div style="display: flex;">
												<filesUpload :disabled="isModify && current < projectStage"
													v-model:value="formUseLand.nature.buildLandNatureMapPaymentFile"
													name="测绘费用支付凭证"
												>
												</filesUpload>
											</div>
										</a-form-item>
							        </a-col>
								</a-row>
							</div>
							<p class="ui-styleBox-title1">林地报批费用</p>
							<div class="ui-contentBorder">
								<a-row :gutter="24">
									<a-col :span="8">
										<a-form-item label="林地报批总金额" :name="['nature','buildLandNatureApprovalCost']">
											{{ inputNumberFormat(countAllPrice('buildLandNatureApprovalCost','nature','buildLandNatureApprovalSpecialCost','buildLandNatureApprovalCompanyCost','buildLandNatureApprovalSourceCost','buildLandNatureApprovalOtherCost')) }}元
										</a-form-item>
										<a-form-item label="上级专项资金" :name="['nature','buildLandNatureApprovalSpecialCost']"
											required>
											<a-input-number :min="0"
												v-model:value="formUseLand.nature.buildLandNatureApprovalSpecialCost"
												class="ui-inputNumWidth"
												:formatter="inputNumberFormat"
												:disabled="isModify && current < projectStage">
											</a-input-number>元
										</a-form-item>
										<a-form-item label="县本级财政资金" :name="['nature','buildLandNatureApprovalCompanyCost']"
											required>
											<a-input-number :min="0"
												v-model:value="formUseLand.nature.buildLandNatureApprovalCompanyCost"
												class="ui-inputNumWidth"
												:formatter="inputNumberFormat"
												:disabled="isModify && current < projectStage">
											</a-input-number>元
										</a-form-item>
										<a-form-item label="请上传发票" :name="['nature','buildLandNatureApprovalTicket']"
											required>
											<div style="display: flex;">
												<filesUpload :disabled="isModify && current < projectStage"
													v-model:value="formUseLand.nature.buildLandNatureApprovalTicket"
													name="林地报批发票"
												>
												</filesUpload>
											</div>
										</a-form-item>
									</a-col>
									<a-col :span="6">
										<a-form-item></a-form-item>
										<a-form-item label="县国资公司" :name="['nature','buildLandNatureApprovalSourceCost']" required>
											<a-input-number :min="0"
												v-model:value="formUseLand.nature.buildLandNatureApprovalSourceCost"
												class="ui-inputNumWidth"
												:formatter="inputNumberFormat"
												:disabled="isModify && current < projectStage">
											</a-input-number>元
										</a-form-item>
										<a-form-item label="其他来源资金" :name="['nature','buildLandNatureApprovalOtherCost']" required>
											<a-input-number :min="0"
												v-model:value="formUseLand.nature.buildLandNatureApprovalOtherCost"
												class="ui-inputNumWidth"
												:formatter="inputNumberFormat"
												:disabled="isModify && current < projectStage">
											</a-input-number>元
										</a-form-item>
										<a-form-item label="请上传支付凭证" :name="['nature','buildLandNatureApprovalPaymentFile']"
											required>
											<div style="display: flex;">
												<filesUpload :disabled="isModify && current < projectStage"
													v-model:value="formUseLand.nature.buildLandNatureApprovalPaymentFile"
													name="林地报批支付凭证"
												>
												</filesUpload>
											</div>
										</a-form-item>
									</a-col>
								</a-row>
							</div>
						</div>
					</div>
					<!--3、土地报批 -->
					<div>
						<p class="ui-title">
							<span class="ui-tipsStyle">*</span>
							3、土地报批
							<Icon icon="QuestionCircleOutlined" class="ui-icon-style" />
						</p>
						<div class="ui-contentBorder">
							<a-row :gutter="24">
							<a-col :span="8">
									<a-form-item label="报批费用金额" :name="['approval','buildLandApprovalCost']">
										{{ inputNumberFormat(countAllPrice('buildLandApprovalCost','approval','buildLandApprovalSpecialCost','buildLandApprovalCompanyCost','buildLandApprovalSourceCost','buildLandApprovalOtherCost')) }}元
									</a-form-item>
									<a-form-item label="上级专项资金" :name="['approval','buildLandApprovalSpecialCost']"
										required>
										<a-input-number :min="0"
											v-model:value="formUseLand.approval.buildLandApprovalSpecialCost"
											class="ui-inputNumWidth"
											:formatter="inputNumberFormat"
											:disabled="isModify && current < projectStage">
										</a-input-number>元
									</a-form-item>
									<a-form-item label="县本级财政资金" :name="['approval','buildLandApprovalSourceCost']"
										required>
										<a-input-number :min="0"
											v-model:value="formUseLand.approval.buildLandApprovalSourceCost"
											class="ui-inputNumWidth"
											:formatter="inputNumberFormat"
											:disabled="isModify && current < projectStage">
										</a-input-number>元
									</a-form-item>
									<a-form-item label="请上传发票" :name="['approval','buildLandApprovalTicket']" required>
										<div style="display: flex;">
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formUseLand.approval.buildLandApprovalTicket"
												name="土地报批费用发票"
											>
											</filesUpload>
										</div>
									</a-form-item>
									</a-col>
							<a-col :span="8">
								<a-form-item></a-form-item>
								<a-form-item label="县国资公司" :name="['approval','buildLandApprovalCompanyCost']" required>
									<a-input-number :min="0"
										v-model:value="formUseLand.approval.buildLandApprovalCompanyCost"
										class="ui-inputNumWidth"
										:formatter="inputNumberFormat"
										:disabled="isModify && current < projectStage">
									</a-input-number>元
								</a-form-item>
								<a-form-item label="其他来源资金" :name="['approval','buildLandApprovalOtherCost']" required>
									<a-input-number :min="0"
										v-model:value="formUseLand.approval.buildLandApprovalOtherCost"
										class="ui-inputNumWidth"
										:formatter="inputNumberFormat"
										:disabled="isModify && current < projectStage">
									</a-input-number>元
								</a-form-item>
								<a-form-item label="请上传支付凭证" :name="['approval','buildLandApprovalPaymentFile']" required>
									<div style="display: flex;">
										<filesUpload :disabled="isModify && current < projectStage"
											v-model:value="formUseLand.approval.buildLandApprovalPaymentFile"
											name="土地报批支付凭证"
										>
										</filesUpload>
									</div>
								</a-form-item>
							</a-col>
						</a-row>
						</div>
					</div>
					<div>
						<p class="ui-title">
							<span class="ui-tipsStyle">*</span>
							4、建设用地取得方式
							<Icon icon="QuestionCircleOutlined" class="ui-icon-style" />
						</p>
						<a-form-item>
							<a-radio-group v-model:value="formUseLand.gain.buildLandGainType"
								:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'buildLandGainType')">
								<a-radio :value="0">摘牌</a-radio>
								<a-radio :value="1">有偿划拨</a-radio>
								<a-radio :value="2">无偿划拨</a-radio>
								<a-radio :value="3">协议转让</a-radio>
							</a-radio-group>
						</a-form-item>
						<div class="ui-styleBox" v-if="formUseLand.gain.buildLandGainType != 2">
							<a-row
								v-if="formUseLand.gain.buildLandGainType == 0 || formUseLand.gain.buildLandGainType == null">
								<a-col :span="8">
									<a-form-item label="请上传土地成交确认书" :name="['gain','buildLandGainConfirmFile']"
										:rules="[{ required: true, message: '请上传土地成交确认书' }]">
										<div style="display: flex;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formUseLand.gain.buildLandGainConfirmFile"
												name="建设用地取得土地成交确认书"
											>
											</filesUpload>
										</div>
									</a-form-item>
								</a-col>
								<a-col :span="8">
									<a-form-item label="请上传出让合同" :name="['gain','buildLandGainContractFile']" :rules="[{ required: true, message: '请上传出让合同' }]">
										<div style="display: flex;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formUseLand.gain.buildLandGainContractFile"
												name="建设用地取得出让合同"
											>
											</filesUpload>
										</div>
									</a-form-item>
								</a-col>
							</a-row>
							<a-form-item label="请上传国有建设用地使用权划拨决定书" :name="['gain','buildLandGainChangeFile']" :rules="[{ required: true, message: '请上传国有建设用地使用权划拨决定书' }]"
								v-if="formUseLand.gain.buildLandGainType == 1">
								<div style="display: flex;">
									<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
									<filesUpload :disabled="isModify && current < projectStage"
										v-model:value="formUseLand.gain.buildLandGainChangeFile"
										name="国有建设用地使用权划拨决定书"
									></filesUpload>
								</div>
							</a-form-item>
							<a-form-item label="请上传转让合同" v-if="formUseLand.gain.buildLandGainType == 3"
								:name="['gain','buildLandGainContractFile']" :rules="[{ required: true, message: '请上传转让合同' }]">
								<div style="display: flex;">
									<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
									<filesUpload :disabled="isModify && current < projectStage"
										v-model:value="formUseLand.gain.buildLandGainContractFile"
										name="建设用地取得转让合同"
									>
									</filesUpload>
								</div>
							</a-form-item>
							<p
								v-if="formUseLand.gain.buildLandGainType == 0 || formUseLand.gain.buildLandGainType == null">
								出让金支付凭证</p>
							<p v-if="formUseLand.gain.buildLandGainType == 1">支付资金的凭证</p>
							<p v-if="formUseLand.gain.buildLandGainType == 3">转让金支付凭证</p>
							<div class="ui-contentBorder">
								<a-row :gutter="24">
									<a-col :span="8">
										<a-form-item label="土地出让金总金额(含滞纳金)"
											v-if="formUseLand.gain.buildLandGainType == 0 || formUseLand.gain.buildLandGainType == null">
											{{ inputNumberFormat(countAllPrice('buildLandGainTotalCost','gain','buildLandGainSpecialCost','buildLandGainCompanyCost','buildLandGainSourceCost','buildLandGainOtherCost')) }}元
										</a-form-item>
										<a-form-item label="支付资金总额" v-if="formUseLand.gain.buildLandGainType == 1">
											{{ inputNumberFormat(countAllPrice('buildLandGainTotalCost','gain','buildLandGainSpecialCost','buildLandGainCompanyCost','buildLandGainSourceCost','buildLandGainOtherCost')) }}元
										</a-form-item>
										<a-form-item label="土地转让金总金额" v-if="formUseLand.gain.buildLandGainType == 3">
											{{ inputNumberFormat(countAllPrice('buildLandGainTotalCost','gain','buildLandGainSpecialCost','buildLandGainCompanyCost','buildLandGainSourceCost','buildLandGainOtherCost')) }}元
										</a-form-item>
										<a-form-item label="上级专项资金" :name="['gain','buildLandGainSpecialCost']"
											required>
											<a-input-number :min="0"
												v-model:value="formUseLand.gain.buildLandGainSpecialCost"
												class="ui-inputNumWidth"
												:formatter="inputNumberFormat"
												:disabled="isModify && current < projectStage">
											</a-input-number>元
										</a-form-item>
										<a-form-item label="县本级财政资金" :name="['gain','buildLandGainSourceCost']"
											required>
											<a-input-number :min="0"
												v-model:value="formUseLand.gain.buildLandGainSourceCost"
												class="ui-inputNumWidth"
												:formatter="inputNumberFormat"
												:disabled="isModify && current < projectStage">
											</a-input-number>元
										</a-form-item>
										<a-form-item label="请上传发票" :name="['gain','buildLandGainTicketFile']" required>
											<div style="display: flex;">
												<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
												<filesUpload :disabled="isModify && current < projectStage"
													v-model:value="formUseLand.gain.buildLandGainTicketFile"
													name="土地出让金发票"
												>
												</filesUpload>
											</div>
										</a-form-item>
									</a-col>
									<a-col :span="8">
										<a-form-item label=""></a-form-item>
										<a-form-item label="县国资公司" :name="['gain','buildLandGainCompanyCost']" required>
											<a-input-number :min="0"
												v-model:value="formUseLand.gain.buildLandGainCompanyCost"
												class="ui-inputNumWidth"
												:formatter="inputNumberFormat"
												:disabled="isModify && current < projectStage">
											</a-input-number>元
										</a-form-item>
										<a-form-item label="其他来源资金" :name="['gain','buildLandGainOtherCost']" required>
											<a-input-number :min="0"
												v-model:value="formUseLand.gain.buildLandGainOtherCost"
												class="ui-inputNumWidth"
												:formatter="inputNumberFormat"
												:disabled="isModify && current < projectStage">
											</a-input-number>元
										</a-form-item>
										<a-form-item label="请上传支付凭证" :name="['gain','buildLandGainPaymentFile']"
											required>
											<div style="display: flex;">
												<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
												<filesUpload :disabled="isModify && current < projectStage"
													v-model:value="formUseLand.gain.buildLandGainPaymentFile"
													name="土地出让金支付凭证"
												>
												</filesUpload>
											</div>
										</a-form-item>
									</a-col>
								</a-row>
							</div>
						</div>
						<div class="ui-contentBorder" v-else>
							<a-form-item label="请上传国有建设用地使用权划拨决定书" :name="['gain','buildLandGainChangeFile']" required>
								<div style="display: flex;">
									<!-- <Icon icon="ExclamationCircleOutlined" name="buildLandGainChangeFile"
										class="ui-icon-style" /> -->
									<filesUpload :disabled="isModify && current < projectStage"
										v-model:value="formUseLand.gain.buildLandGainChangeFile"
										name="国有建设用地使用权划拨决定书"
									></filesUpload>
								</div>
							</a-form-item>
						</div>
					</div>
					<!-- 5、前置办理不动产权登记证 -->
					<div>
						<p class="ui-title">
							<span class="ui-tipsStyle">*</span>
							5、前置办理不动产权登记证
							<Icon icon="QuestionCircleOutlined" class="ui-icon-style" />
						</p>
						<a-radio-group v-model:value="formUseLand.register.buildLandRegisterStatus"
							:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'buildLandRegisterStatus')">
							<a-radio :value="0">已办理</a-radio>
							<a-radio :value="1">未办理</a-radio>
							<a-radio :value="2">不需要</a-radio>
						</a-radio-group>
						<div class="ui-styleBox"
							v-if="formUseLand.register.buildLandRegisterStatus == 0 || formUseLand.register.buildLandRegisterStatus == null">
							<a-row :gutter="24">
								<a-col :span="8">
									<a-form-item label="请上传用地规划许可证" :name="['register','buildLandRegisterPermitFile']"
										:rules="[{ required: true, message: '请上传用地规划许可证' }]">
										<div style="display: flex;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formUseLand.register.buildLandRegisterPermitFile"
												:limit="1"
												name="用地规划许可证"
											>
											</filesUpload>
											<a-form-item style="margin-left: 5px;" :name="['register','buildLandRegisterPermitTime']">
												<a-date-picker style="width: 150px;" v-model:value="formUseLand.register.buildLandRegisterPermitTime" :value-format="'X'"
												:disabled="(!formUseLand.register?.buildLandRegisterPermitFile) || formUseLand.register?.buildLandRegisterPermitFile.length == 0"/>
											</a-form-item>
										</div>
									</a-form-item>
								</a-col>
								<a-col :span="10">
									<a-form-item label="请上传不动产权登记证（土地）"
										:name="['register','buildLandRegisterRegisterFile']" :rules="[{ required: true, message: '请上传不动产权登记证（土地）' }]">
										<div style="display: flex;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formUseLand.register.buildLandRegisterRegisterFile"
												name="不动产权登记证"
												:limit="1"
											>
											</filesUpload>
											<a-form-item style="margin-left: 5px;" :name="['register','buildLandRegisterRegisterTime']">
												<a-date-picker style="width: 150px;" v-model:value="formUseLand.register.buildLandRegisterRegisterTime" :value-format="'X'"
												:disabled="(!formUseLand.register?.buildLandRegisterRegisterFile) || formUseLand.register?.buildLandRegisterRegisterFile.length == 0" />
											</a-form-item>
										</div>
									</a-form-item>
								</a-col>
							</a-row>
							<p>办证费用支付凭证</p>
							<div class="ui-contentBorder">
								<a-row :gutter="24">
									<a-col :span="8">
										<a-form-item label="办证费用总金额">
											{{ inputNumberFormat(countAllPrice('buildLandRegisterTotalCost','register','buildLandRegisterSpecialCost','buildLandRegisterCompanyCost','buildLandRegisterSourceCost','buildLandRegisterOtherCost')) }}元
										</a-form-item>
										<a-form-item label="上级专项资金" :name="['register','buildLandRegisterSpecialCost']"
											required>
											<a-input-number :min="0"
												v-model:value="formUseLand.register.buildLandRegisterSpecialCost"
												class="ui-inputNumWidth"
												:formatter="inputNumberFormat"
												:disabled="isModify && current < projectStage">
											</a-input-number>元
										</a-form-item>
										<a-form-item label="县本级财政资金" :name="['register','buildLandRegisterSourceCost']"
											required>
											<a-input-number :min="0"
												v-model:value="formUseLand.register.buildLandRegisterSourceCost"
												class="ui-inputNumWidth"
												:formatter="inputNumberFormat"
												:disabled="isModify && current < projectStage">
											</a-input-number>元
										</a-form-item>
										<a-form-item label="请上传发票" :name="['register','buildLandRegisterTicketFile']"
											required>
											<div style="display: flex;">
												<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
												<filesUpload :disabled="isModify && current < projectStage"
													v-model:value="formUseLand.register.buildLandRegisterTicketFile"
													name="办理不动产权登记证发票"
												>
												</filesUpload>
											</div>
										</a-form-item>
									</a-col>
									<a-col :span="10">
										<a-form-item label=""></a-form-item>
										<a-form-item label="县国资公司" :name="['register','buildLandRegisterCompanyCost']"
											required>
											<a-input-number :min="0"
												v-model:value="formUseLand.register.buildLandRegisterCompanyCost"
												class="ui-inputNumWidth"
												:formatter="inputNumberFormat"
												:disabled="isModify && current < projectStage">
											</a-input-number>元
										</a-form-item>
										<a-form-item label="其他来源资金" :name="['register','buildLandRegisterOtherCost']"
											required>
											<a-input-number :min="0"
												v-model:value="formUseLand.register.buildLandRegisterOtherCost"
												class="ui-inputNumWidth"
												:formatter="inputNumberFormat"
												:disabled="isModify && current < projectStage">
											</a-input-number>元
										</a-form-item>
										<a-form-item label="请上传支付凭证" :name="['register','buildLandRegisterPaymentFile']"
											required>
											<div style="display: flex;">
												<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
												<filesUpload :disabled="isModify && current < projectStage"
													v-model:value="formUseLand.register.buildLandRegisterPaymentFile"
													name="办理不动产权登记证支付凭证"
												>
												</filesUpload>
											</div>
										</a-form-item>
									</a-col>
								</a-row>
							</div>
						</div>
					</div>
					<!-- 6、三通一平支出 -->
					<div>
						<p class="ui-title">
							<span class="ui-tipsStyle">*</span>
							6、三通一平支出
							<Icon icon="QuestionCircleOutlined" class="ui-icon-style" />
						</p>
						<a-radio-group v-model:value="formUseLand.speed.buildLandSpeedStatus"
							:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'buildLandSpeedStatus')">
							<a-radio :value="0">有支出</a-radio>
							<a-radio :value="1">无支出</a-radio>
							<a-radio :value="2">不需要</a-radio>
						</a-radio-group>
						<div v-for="(item,index) in formUseLand.speed.buildLandSpeed" :key="index">
							<!-- 大数据盒子 -->
							<div class="ui-SerChildBorderStyle" v-if="formUseLand.speed.buildLandSpeedStatus == 0">
								<div class="ui-SerChildStyleMini">
									<a-row :gutter="24" style="width: 100%">
										<a-col :span="7">
											<a-form-item label="施工单位名称" :name="['speed','buildLandSpeed',index,'unitName']" :rules="[{required:true,message:'请输入施工单位'}]">
												<a-input v-model:value="item.unitName"
													placeholder="请输入施工单位名称" class="ui-inputNumWidth"
													:disabled="isModify && current < projectStage">
												</a-input>
											</a-form-item>
											<a-form-item label="请上传施工合同" :name="['speed','buildLandSpeed',index,'contract']"
											:rules="[{required:true,message:'请上传中标通知书'}]">
												<div style="display: flex;height: 30px;width:200px">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="isModify && current < projectStage"
														v-model:value="item.contract"
														name="三通一平施工合同"
														:index="index+1"
													>
													</filesUpload>
												</div>
											</a-form-item>
											<a-form-item label="合同金额" :name="['speed','buildLandSpeed',index,'cost']" required>
												<a-input-number :min="0" placeholder="请输入合同金额"
													v-model:value="item.cost"
													class="ui-inputNumWidth"
													:formatter="inputNumberFormat"
													:disabled="isModify && current < projectStage">
												</a-input-number>元
										    </a-form-item>
											<a-form-item label="工程完成量" :name="['speed','buildLandSpeed',index,'completedPercent']" required>
											<!-- :max="formUseLand.speed.buildLandSpeedTotalCost" -->
											<a-input-number :min="0" placeholder="请输入工程完成量"
												:max="item.cost"
												v-model:value="item.completedPercent"
												class="ui-inputNumWidth"
												:formatter="inputNumberFormat"
												:disabled="isModify && current < projectStage">
											</a-input-number>元
										</a-form-item>
										</a-col>
										<a-col :span="6">
											<a-form-item label="请上传中标通知书" :name="['speed','buildLandSpeed',index,'notice']" :rules="[{required:true,message:'请上传中标通知书'}]">
												<div style="display: flex;height: 30px;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="isModify && current < projectStage"
														v-model:value="item.notice"
														name="三通一平中标通知书"
														:index="index+1"
													>
													</filesUpload>
												</div>
											</a-form-item>
											<a-form-item label="请上传会议纪要或文件" :name="['speed','buildLandSpeed',index,'summary']"
											:rules="[{required:true,message:'请上传会议纪要或文件'}]">
												<div style="display: flex;height: 30px;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="isModify && current < projectStage"
														v-model:value="item.summary"
														name="三通一平会议文件"
														:index="index+1"
													>
													</filesUpload>
												</div>
											</a-form-item>
											<a-form-item>
												<span>审计金额：{{ inputNumberFormat(auditCost(item)) }}元</span>
											</a-form-item>
											<a-form-item>
												<span>工程进度百分比：{{ item.completedPercent ? Number(item.completedPercent/(countOnesAuditPrice(item)?countOnesAuditPrice(item):item.cost) * 100).toFixed(2) : '0.00' }}%</span>
											</a-form-item>
										</a-col>
										<a-col :span="6">
											<a-form-item>
											</a-form-item>
											<a-form-item>
											</a-form-item>
											<a-form-item>
												<div>已拨付金额：{{ inputNumberFormat(paymentedPrice(item)) }}元</div>
											</a-form-item>
											<a-form-item label="项管办核定付款比例">
												<a-select v-model:value="item.payPercent"
													class="ui-inputNumWidth"
													:disabled="isModify && current < projectStage">
													<a-select-option :value="0.7">70%</a-select-option>
													<a-select-option :value="0.8">80%</a-select-option>
													<a-select-option v-if="$store.state.system.userInfo.adjust || formUseLand.speed.buildLandSpeedPayPercent === 0.9" :value="0.9">90%</a-select-option>
													<a-select-option v-if="$store.state.system.userInfo.adjust || formUseLand.speed.buildLandSpeedPayPercent === 1" :value="1">100%</a-select-option>
												</a-select>
										    </a-form-item>
										</a-col>
										<a-col :span="5" style="text-align: left">
											<a-form-item>
											</a-form-item>
											<a-form-item>
											</a-form-item>
											<a-form-item>
												<div>待拨付金额：{{ inputNumberFormat(paymentedAwaitPrice(item)) }}元</div>
											</a-form-item>
										</a-col>
									</a-row>
									<div>
										<div class="ui-contentBorderBox" v-for="(devItem,devIndex) in item.payment" :key="devIndex">
											<div class="ui-contentBorderMini" style="background-color:#FFFFFF">
												<a-form-item label="请选择款项" :rules="[{required:true}]">
													<a-radio-group v-model:value="devItem.type"
														:disabled="(current < projectStage && devItem.isDisabled)">
														<a-radio :value="0">进度款</a-radio>
														<a-radio :value="1" :disabled="onbuildLandSpeedPaymentType.includes(1)">结算款</a-radio>
														<a-radio :value="2">尾款</a-radio>
													</a-radio-group>
												</a-form-item>
												<a-row >
													<a-col :span="12">
														<!-- :name="[ 'speed',devItem.amount,devIndex,'amount']" -->
														<a-form-item label="付款金额"
															:name="['speed','buildLandSpeed',index,'payment',devIndex,'amount']"
															:rules="[{ required: true }]">
															<a-input-number :min="0" placeholder="请输入付款金额"
															@blur="changePriceCount(devItem.type,item.cost,item.completedPercent*item.payPercent,item.payment,devIndex,countOnesAuditPrice(item))"
																v-model:value="devItem.amount" class="ui-inputNumWidth" :formatter="inputNumberFormat"
																:disabled="(current < projectStage && devItem.isDisabled)">
															</a-input-number>元
														</a-form-item>
														<!-- :name="[ 'speed','buildLandSpeedPaymentFile',devIndex,'auditCost']" -->
														<a-form-item label="审计金额"
															:name="['speed','buildLandSpeed',index,'payment',devIndex,'auditCost']"
															:rules="[{ required: true }]" v-if="devItem.type == 1">
															<a-input-number :min="0" v-model:value="devItem.auditCost"
																class="ui-inputNumWidth"
																:formatter="inputNumberFormat"
																:disabled="(current < projectStag && devItem.isDisabled)">
															</a-input-number>元
														</a-form-item>
														<a-form-item label="请上传工程进度报表"
															:name="['speed','buildLandSpeed',index,'payment',devIndex,'scheduleFile']"
															:rules="[{ required: true,message:'请上传工程进度报表' }]" v-if="devItem.type == 0">
															<div style="display: flex;">
																<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
																<filesUpload :disabled="(current < projectStage && devItem.isDisabled)"
																	v-model:value="devItem.scheduleFile"
																	:index="(index+1)+''+devIndex"
																	name="三通一平工程进度报表"
																></filesUpload>
															</div>
														</a-form-item>
														<a-form-item label="请上传发票"
															:name="['speed','buildLandSpeed',index,'payment',devIndex,'ticket']"
															:rules="[{ required: true,message:'请上传发票' }]">
															<div style="display: flex;">
																<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
																<filesUpload :disabled="(current < projectStage && devItem.isDisabled)"
																	v-model:value="devItem.ticket"
																	:index="(index+1)+''+devIndex"
																	name="三通一平发票"
																></filesUpload>
															</div>
														</a-form-item>
														<a-form-item label="请上传审计报告"
															:name="['speed','buildLandSpeed',index,'payment',devIndex,'auditReport']"
															:rules="[{ required: true,message:'请上传审计报告' }]" v-if="devItem.type == 1">
															<div style="display: flex;">
																<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
																<filesUpload :disabled="(current < projectStage && devItem.isDisabled)"
																	v-model:value="devItem.auditReport"
																	:index="(index+1)+''+devIndex"
																	name="三通一平审计报告"
																></filesUpload>
															</div>
														</a-form-item>
													</a-col>
													<a-col :span="10">
														<a-form-item label="资金来源"
															:name="['speed','buildLandSpeed',index,'payment',devIndex,'source']"
															:rules="[{ required: true }]">
															<a-select placeholder="请选择资金来源" v-model:value="devItem.source"
																class="ui-inputNumWidth"
																:disabled="(current < projectStage && devItem.isDisabled)">
																<a-select-option :value="0">上级专项资金</a-select-option>
																<a-select-option :value="1">县国资公司</a-select-option>
																<a-select-option :value="2">县本级财政资金</a-select-option>
																<a-select-option :value="3">其他</a-select-option>
															</a-select>
														</a-form-item>
														<a-form-item label="请上传请款审批表"
															:name="['speed','buildLandSpeed',index,'payment',devIndex,'applyFile']"
															:rules="[{ required: true,message:'请上传请款审批表' }]">
															<div style="display: flex;">
																<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
																<filesUpload :disabled="(current < projectStage && devItem.isDisabled)"
																	v-model:value="devItem.applyFile"
																	:index="(index+1)+''+devIndex"
																	name="三通一平请款审批表"
																></filesUpload>
															</div>
														</a-form-item>
														<a-form-item label="请上传支付凭证"
															:name="['speed','buildLandSpeed',index,'payment',devIndex,'voucher']"
															:rules="[{ required: true }]">
															<div style="display: flex;">
																<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
																<filesUpload :disabled="(current < projectStage && devItem.isDisabled)"
																	v-model:value="devItem.voucher"
																	:index="(index+1)+''+devIndex"
																	name="三通一平支付凭证"
																></filesUpload>
															</div>
														</a-form-item>

													</a-col>
												</a-row>
											</div>
											<div>
												<div class="ui-plusIconBox">
													<Icon width="60" class="ui-icon-plusStyle" icon="PlusOutlined" />
													<a-button type="text" size="large"
														@click="onAddPayRecordList(devItem.amount,Number(item.completedPercent * item.payPercent),item.payment,{type: 0,amount: null,source: null,scheduleFile:[],applyFile:[],ticket: [],voucher: [],auditCost: 0,auditReport: [],isDisabled:false},countOnesAuditPrice(item))">
														新增付款记录</a-button>
												</div>
												<!-- <div class="ui-plusIconBox"><Icon width="60" class="ui-icon-plusStyle" icon="MinusOutlined" /><a-button type="text" size="large" @click="onDeletePayRecord(formUseLand.costAudit.buildLandCostAuditPaymentFile,index)" ghost v-if="formUseLand.costAudit.buildLandCostAuditPaymentFile.length >= 1">删除此记录</a-button></div> -->
											</div>
										</div>
									</div>
								</div>
								<!-- 增加付款记录 -->
								<div>
									<div class="ui-plusIconBox">
										<Icon width="60" class="ui-icon-plusStyle" icon="PlusOutlined" />
										<a-button type="text" size="large"
										@click="onAddPayRecordPing(item.cost,formUseLand.speed.buildLandSpeed,{unitName: '',notice: [],contract: [],summary: [],cost: null,completedPercent: '',payPercent: 0.7,paymentPercentage: '',payment: [{type: 0,amount: null,source: null,scheduleFile:[],applyFile:[],ticket: [],voucher: [],auditCost: 0,auditReport: [],isDisabled:false}],isDisabled:false})">
											新增费用记录</a-button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 7、三通一平审计 -->
					<div>
						<p class="ui-title">
							<span class="ui-tipsStyle">*</span>
							7、三通一平审计
							<Icon icon="QuestionCircleOutlined" class="ui-icon-style" />
						</p>
						<a-radio-group v-model:value="formUseLand.costAudit.buildLandCostAuditStatus"
							:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'buildLandCostAuditStatus')">
							<a-radio :value="0">有审计</a-radio>
							<a-radio :value="1">无审计</a-radio>
							<a-radio :value="2">不需要</a-radio>
						</a-radio-group>
						<div class="ui-styleBox"
							v-if="formUseLand.costAudit.buildLandCostAuditStatus == null || formUseLand.costAudit.buildLandCostAuditStatus == 0">
							<a-row :gutter="24">
								<a-col :span="8">
									<a-form-item label="审计公司名称" :name="['costAudit','buildLandCostAuditCompanyName']"
										required>
										<a-input v-model:value="formUseLand.costAudit.buildLandCostAuditCompanyName"
											placeholder="请输入审计公司名称" class="ui-inputNumWidth"
											:disabled="isModify && current < projectStage">
										</a-input>
									</a-form-item>
									<a-form-item label="请上传会议纪要" :name="['costAudit','buildLandCostAuditSummaryFile']"
										:rules="[{ required: true, message: '请上传会议纪要' }]">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formUseLand.costAudit.buildLandCostAuditSummaryFile"
												name="三通一平审计会议纪要"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="合同金额" :name="['costAudit','buildLandCostAuditTotalCost']" required>
										<a-input-number :min="0" placeholder="请输入合同金额"
											v-model:value="formUseLand.costAudit.buildLandCostAuditTotalCost"
											class="ui-inputNumWidth"
											:formatter="inputNumberFormat"
											:disabled="isModify && current < projectStage">
										</a-input-number>元
								    </a-form-item>
								</a-col>
								<a-col :span="8">
									<a-form-item label="请上传中标通知书或成交确认书"
										:name="['costAudit','buildLandCostAuditNoticeFile']" :rules="[{ required: true, message: '请上传中标通知书或成交确认书' }]">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formUseLand.costAudit.buildLandCostAuditNoticeFile"
												name="三通一平审计中标通知书或成交确认书"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="请上传合同" :name="['costAudit','buildLandCostAuditContractFile']"
										:rules="[{ required: true, message: '请上传合同' }]">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formUseLand.costAudit.buildLandCostAuditContractFile"
												name="三通一平审计合同"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item>
										<span>已支付金额：{{ inputNumberFormat(countPaid('payAuditPrice',formUseLand.costAudit.buildLandCostAuditPaymentFile,'formUseLand')) }}元</span>
									</a-form-item>
								</a-col>
								<a-col>
									<a-form-item>
									</a-form-item>
									<a-form-item>
									</a-form-item>
									<a-form-item>
										<span>待支付金额：{{ inputNumberFormat((formUseLand.costAudit.buildLandCostAuditTotalCost?formUseLand.costAudit.buildLandCostAuditTotalCost:0) - countPaid('payAuditPrice',formUseLand.costAudit.buildLandCostAuditPaymentFile,'formUseLand')) }}元</span>
									</a-form-item>
								</a-col>
							</a-row>

							<!-- <div class="ui-formUseLandPay-line">
								<a-form-item label="合同金额" :name="['costAudit','buildLandCostAuditTotalCost']" required>
									<a-input-number :min="0" placeholder="请输入合同金额"
										v-model:value="formUseLand.costAudit.buildLandCostAuditTotalCost"
										class="ui-inputNumWidth"
										:formatter="inputNumberFormat"
										:disabled="isModify && current < projectStage">
									</a-input-number>元
								</a-form-item>
								<div class="ui-formUseLandPay-price">
									<span>已支付金额：{{ inputNumberFormat(countPaid('payAuditPrice',formUseLand.costAudit.buildLandCostAuditPaymentFile,'formUseLand')) }}元</span>
									<span>待支付金额：{{ inputNumberFormat((formUseLand.costAudit.buildLandCostAuditTotalCost?formUseLand.costAudit.buildLandCostAuditTotalCost:0) - countPaid('payAuditPrice',formUseLand.costAudit.buildLandCostAuditPaymentFile,'formUseLand')) }}元</span>
								</div>
							</div> -->
							<div class="ui-contentBorderBox"
								v-for="(item,index) in formUseLand.costAudit.buildLandCostAuditPaymentFile"
								:key="index">
								<div class="ui-contentBorderMini">
									<a-row>
										<a-col :span="12">
											<a-form-item label="付款金额"
												:name="[ 'costAudit','buildLandCostAuditPaymentFile',index,'amount']"
												:rules="[{ required: true }]">
												<a-input-number :min="0" placeholder="请输入付款金额" :max="countMaxPrice(formUseLand.costAudit.buildLandCostAuditTotalCost)"
													@blur="changePrice(formUseLand.costAudit.buildLandCostAuditTotalCost,formUseLand.costAudit.buildLandCostAuditPaymentFile,index)"
													v-model:value="item.amount" class="ui-inputNumWidth" 
													:formatter="inputNumberFormat"
													:disabled="(item.isDisabled && current < projectStage)">
												</a-input-number>元
											</a-form-item>
											<a-form-item label="请上传请款审批表"
												:name="[ 'costAudit','buildLandCostAuditPaymentFile',index,'applyFile']"
												:rules="[{ required: true, message: '请上传请款审批表' }]">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="(item.isDisabled && current < projectStage)"
														v-model:value="item.applyFile"
														:index="index +1"
														name="三通一平审计请款审批表"
														></filesUpload>
												</div>
											</a-form-item>
											<a-form-item label="请上传支付凭证"
												:name="[ 'costAudit','buildLandCostAuditPaymentFile',index,'voucher']"
												:rules="[{ required: true, message: '请上传支付凭证' }]">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="(item.isDisabled && current < projectStage)"
														v-model:value="item.voucher"
														:index="index +1"
														name="三通一平审计支付凭证"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
										<a-col :span="10">
											<a-form-item label="资金来源"
												:name="[ 'costAudit','buildLandCostAuditPaymentFile',index,'source']"
												:rules="[{ required: true }]">
												<a-select placeholder="请选择资金来源" v-model:value="item.source"
													class="ui-inputNumWidth"
													:disabled="(item.isDisabled && current < projectStage)">
													<a-select-option :value="0">上级专项资金</a-select-option>
													<a-select-option :value="1">县国资公司</a-select-option>
													<a-select-option :value="2">县本级财政资金</a-select-option>
													<a-select-option :value="3">其他</a-select-option>
												</a-select>
											</a-form-item>
											<a-form-item label="请上传发票"
												:name="[ 'costAudit','buildLandCostAuditPaymentFile',index,'ticket']"
												:rules="[{ required: true, message: '请上传发票' }]">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="(item.isDisabled && current < projectStage)"
														v-model:value="item.ticket"
														:index="index +1"
														name="三通一平审计支付发票"
													></filesUpload>
												</div>
											</a-form-item>
											<!-- <a-form-item label="请上传审计报告" :rules="[{ required: true }]">
									            <div style="display: flex;">
									            	<Icon icon="ExclamationCircleOutlined" class="ui-icon-style"/>
									            	<filesUpload :disabled="isModify && current < projectStage" v-model:value="fileList"></filesUpload>
									            </div>
									        </a-form-item> -->
										</a-col>
										<!-- <a-col :span="12">
											<div class="ui-plusIconBox"><Icon width="60" class="ui-icon-plusStyle" icon="PlusOutlined" /><a-button type="text" size="large" @click="onAddPayRecord(formUseLand.costAudit.buildLandCostAuditPaymentFile)" >新增付款记录</a-button></div>
											<div class="ui-plusIconBox"><Icon width="60" class="ui-icon-plusStyle" icon="MinusOutlined" /><a-button type="text" size="large" @click="onDeletePayRecord(formUseLand.costAudit.buildLandCostAuditPaymentFile,index)" ghost v-if="formUseLand.costAudit.buildLandCostAuditPaymentFile.length >= 1">删除此记录</a-button></div>
									        <a-button @click="onAddPayRecord(formUseLand.costAudit.buildLandCostAuditPaymentFile)" type="primary">新增付款记录</a-button>
											<a-button @click="onDeletePayRecord(formUseLand.costAudit.buildLandCostAuditPaymentFile,index)" type="primary" ghost v-if="index !== 0">删除此记录</a-button>
									        <a-button @click="onDeletePayRecord(formUseLand.costAudit.buildLandCostAuditPaymentFile,index)" type="primary" ghost v-if="formUseLand.costAudit.buildLandCostAuditPaymentFile.length > 1">删除此记录</a-button>
									    </a-col> -->
									</a-row>
								</div>
								<div>
									<div class="ui-plusIconBox">
										<Icon width="60" class="ui-icon-plusStyle" icon="PlusOutlined" />
										<a-button type="text" size="large"
											@click="onAddPayRecord(formUseLand.costAudit.buildLandCostAuditTotalCost,formUseLand.costAudit.buildLandCostAuditPaymentFile,{amount:null,source:null,voucher: [],applyFile: [],ticket: [],isDisabled:false})">
											新增付款记录</a-button>
									</div>
									<!-- <div class="ui-plusIconBox"><Icon width="60" class="ui-icon-plusStyle" icon="MinusOutlined" /><a-button type="text" size="large" @click="onDeletePayRecord(formUseLand.costAudit.buildLandCostAuditPaymentFile,index)" ghost v-if="formUseLand.costAudit.buildLandCostAuditPaymentFile.length >= 1">删除此记录</a-button></div> -->
								</div>
							</div>
						</div>
					</div>
					<!-- 8、污染状况调查成果文件、调查支出费用凭证 -->
					<div>
						<p class="ui-title">
							<span class="ui-tipsStyle">*</span>
							8、污染状况调查成果文件、调查支出费用凭证
							<Icon icon="QuestionCircleOutlined" class="ui-icon-style" />
						</p>
						<a-radio-group v-model:value="formUseLand.result.buildLandResultStatus"
							:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'buildLandResultStatus')">
							<a-radio :value="0">有调查</a-radio>
							<a-radio :value="1">无调查</a-radio>
							<a-radio :value="2">不需要</a-radio>
						</a-radio-group>
						<div class="ui-styleBox"
							v-if="formUseLand.result.buildLandResultStatus == null || formUseLand.result.buildLandResultStatus == 0">
							<a-row :gutter="24">
								<a-col :span="8">
									<a-form-item label="施工单位名称" :name="['result','buildLandResultUnitName']" required>
										<a-input v-model:value="formUseLand.result.buildLandResultUnitName"
											placeholder="请输入施工单位名称" class="ui-inputNumWidth"
											:disabled="isModify && current < projectStage">
										</a-input>
									</a-form-item>
									<a-form-item label="请上传中标通知书" :name="['result','buildLandResultNoticeFile']"
										:rules="[{ required: true, message: '请上传中标通知书' }]">
										<div style="display: flex;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formUseLand.result.buildLandResultNoticeFile"
												name="污染状况调查中标通知书"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="请上传会议纪要或文件" :name="['result','buildLandResultSummaryFile']"
										:rules="[{ required: true, message: '请上传会议纪要或文件' }]">
										<div style="display: flex;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formUseLand.result.buildLandResultSummaryFile"
												name="污染状况调查会议纪要或文件"
											>
											</filesUpload>
										</div>
									</a-form-item>
								</a-col>
								<a-col :span="8">
									<a-form-item label="合同金额" :name="['result','buildLandResultTotalCost']" required>
										<a-input-number :min="0" placeholder="请输入合同金额"
											v-model:value="formUseLand.result.buildLandResultTotalCost"
											class="ui-inputNumWidth"
											:formatter="inputNumberFormat"
											:disabled="isModify && current < projectStage"></a-input-number>元
									</a-form-item>
									<a-form-item label="请上传施工合同" :name="['result','buildLandResultContractFile']"
										:rules="[{ required: true, message: '请上传施工合同' }]">
										<div style="display: flex;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formUseLand.result.buildLandResultContractFile"
												name="污染状况调查施工合同"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="请上传调查成果文件" :name="['result','buildLandResultSurveyFile']"
										:rules="[{ required: true, message: '请上传调查成果文件' }]">
										<div style="display: flex;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formUseLand.result.buildLandResultSurveyFile"
												name="污染状况调查成果文件"
											>
											</filesUpload>
										</div>
									</a-form-item>
								</a-col>
								<a-col :span="4">
									<!-- <span>已拨付金额：{{ surveyPayPrice }}</span><br>
				                    <span>待拨付金额：{{ surveyAwaitPayPrice }}</span> -->
									<span>已拨付金额：{{ inputNumberFormat(countPaid('surveyPayPrice',formUseLand.result.buildLandResultPaymentFile,'formUseLand')) }}元</span><br>
									<!-- <span>待拨付金额：{{ countAwaitPrice('formUseLand','surveyPayAwaitPrice','surveyContractPrice','formUseLand') }}</span> -->
								</a-col>
								<a-col :span="4">
									<!-- <span>已拨付金额：{{ surveyPayPrice }}</span><br>
								    <span>待拨付金额：{{ surveyAwaitPayPrice }}</span> -->
									<!-- <span>已拨付金额：{{ countPaid('surveyPayPrice',formUseLand.result.buildLandResultPaymentFile,'formUseLand') }}</span><br> -->
									<span>待拨付金额：{{ inputNumberFormat(countAwaitPrice('formUseLand','result','buildLandResultTotalCost',countPaid('surveyPayPrice',formUseLand.result.buildLandResultPaymentFile,'formUseLand')) || 0) }}元</span>
								</a-col>
							</a-row>
							<div class="ui-contentBorderBox"
								v-for="(item,index) in formUseLand.result.buildLandResultPaymentFile" :key="index">
								<div class="ui-contentBorderMini">
									<a-form-item label="请选择款项" required>
										<a-radio-group v-model:value="item.type"
											:disabled="(isModify && current < projectStage && item.isDisabled) || (item.isDisabled && current !== projectStage)" @change="(e) => onTypeChange(e, 'buildLandResultPaymentFile', index)">
											<a-radio :value="0">进度款</a-radio>
											<!-- <a-radio :value="1" :disabled="buildLandResultPaymentType.includes(1)">结算款</a-radio> -->
											<a-radio :value="1">结算款</a-radio>
											<a-radio :value="2">尾款</a-radio>
										</a-radio-group>
									</a-form-item>
									<a-row>
										<a-col :span="12">
											<a-form-item label="付款金额"
												:name="[ 'result','buildLandResultPaymentFile',index,'amount']"
												required>
												<a-input-number :min="0" placeholder="请输入付款金额"
													v-model:value="item.amount" class="ui-inputNumWidth" :formatter="inputNumberFormat"
													@blur="changePrice(formUseLand.result.buildLandResultTotalCost,formUseLand.result.buildLandResultPaymentFile,index)"
													:disabled="(isModify && current < projectStage && item.isDisabled) || (item.isDisabled && current !== projectStage)">
												</a-input-number>元
											</a-form-item>
											<a-form-item label="请上传请款审批表"
												:name="[ 'result','buildLandResultPaymentFile',index,'applyFile']"
												:rules="[{ required: true, message: '请上传请款审批表' }]">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="(isModify && current < projectStage && item.isDisabled) || (item.isDisabled && current !== projectStage)"
														v-model:value="item.applyFile"
														:index="index +1"
														name="污染状况调查请款审批表"
													></filesUpload>
												</div>
											</a-form-item>
											<!-- <a-form-item label="请上传发票" :rules="[{ required: true }]">
									            <div style="display: flex;">
									            	<Icon icon="ExclamationCircleOutlined" class="ui-icon-style"/>
									            	<filesUpload :disabled="isModify && current < projectStage" v-model:value="item.ticket"></filesUpload>
									            </div>.
									        </a-form-item> -->
											<a-form-item label="请上传支付凭证"
												:name="[ 'result','buildLandResultPaymentFile',index,'voucher']"
												:rules="[{ required: true, message: '请上传支付凭证' }]">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="(isModify && current < projectStage && item.isDisabled) || (item.isDisabled && current !== projectStage)"
														v-model:value="item.voucher"
														:index="index +1"
														name="污染状况调查支付凭证"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
										<a-col :span="10">
											<a-form-item label="资金来源"
												:name="[ 'result','buildLandResultPaymentFile',index,'source']"
												:rules="[{ required: true }]">
												<a-select placeholder="请选择资金来源" v-model:value="item.source"
													class="ui-inputNumWidth"
													:disabled="(isModify && current < projectStage && item.isDisabled) || (item.isDisabled && current !== projectStage)">
													<a-select-option :value="0">上级专项资金</a-select-option>
													<a-select-option :value="1">县国资公司</a-select-option>
													<a-select-option :value="2">县本级财政资金</a-select-option>
													<a-select-option :value="3">其他</a-select-option>
												</a-select>
											</a-form-item>
											<a-form-item label="请上传发票"
												:name="[ 'result','buildLandResultPaymentFile',index,'ticket']"
												:rules="[{ required: true, message: '请上传发票' }]">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="(isModify && current < projectStage && item.isDisabled) || (item.isDisabled && current !== projectStage)"
														v-model:value="item.ticket"
														:index="index +1"
														name="污染状况调查支付发票"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
									</a-row>
								</div>
								<div>
									<div class="ui-plusIconBox">
										<Icon width="60" class="ui-icon-plusStyle" icon="PlusOutlined" />
										<a-button type="text" size="large"
											@click="onAddPayRecord(formUseLand.result.buildLandResultTotalCost,formUseLand.result.buildLandResultPaymentFile,{amount:null,source:null,type: 0,applyFile: [],ticket: [],voucher: [],isDisabled:false})">
											新增付款记录</a-button>
									</div>
								</div>
							</div>
						</div>
					</div>
					</div>
					
				</a-form>
			</a-spin>
		</div>
		<!-- 项目立项 -->
		<div class="steps-content" v-if="steps[current].key == 'confirm'">
			<a-form :model="formApproval" ref="formApprovalRef" scrollToFirstError>
				<!-- 1 是否重大项目-->
				<div>
					<span class="ui-contentMarginTop">
						<span class="ui-tipsStyle">*</span>1、是否重大项目 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></span>
					<!-- <Icon icon="ExclamationCircleOutlined" style="margin-left: 5px;" class="ui-icon-style" /> -->
					<a-select v-model:value="formApproval.important.projectApprovalIsImportant"
						style="margin-left:20px;width:80px;" :disabled="isModify && current < projectStage">
						<a-select-option :value="1">是</a-select-option>
						<a-select-option :value="0">否</a-select-option>
					</a-select>
				</div>
				<div>
					<span class="ui-contentMarginTop">
						<span class="ui-tipsStyle">*</span>2、是否列入投资计划 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></span>
					<a-select v-model:value="formApproval.important.projectApprovalIsPlan"
						style="margin-left:20px;margin-top:10px;width:80px;" :disabled="isModify && current < projectStage">
						<a-select-option :value="0">是</a-select-option>
						<a-select-option :value="1">否</a-select-option>
					</a-select>
				</div>
				<!-- 3 资金来源-->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>3、资金来源 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<a-row class="ui-contentBorder">
						<a-col :span="8">
							<a-form-item label="上级专项资金" :name="['source','projectApprovalSourceSpecialCost']" required>
								<a-input-group compact class="ui-group">
									<a-input-number :min="0"
										v-model:value="formApproval.source.projectApprovalSourceSpecialCost"
										class="ui-inputNumWidth"
										:formatter="inputNumberFormat"
										:disabled="isModify && current < projectStage"></a-input-number>元
									
									<a-form-item-rest>
										<a-input placeholder="请输入指标文件号"
											v-model:value="formApproval.source.projectApprovalSourceFileNum"
											class="ui-inputNumWidth" style="margin-left:10px;"
											:disabled="isModify && current < projectStage">
										</a-input>
									</a-form-item-rest>
								</a-input-group>
							</a-form-item>
							<a-form-item label="县本级财政资金" :name="['source','projectApprovalSourceSourceCost']" required>
								<a-input-number :min="0"
									v-model:value="formApproval.source.projectApprovalSourceSourceCost"
									:formatter="inputNumberFormat"
									class="ui-inputNumWidth" :disabled="isModify && current < projectStage">
								</a-input-number>元
							</a-form-item>
							<!-- <a-form-item label="请上传政府投资计划">
								<div style="display: flex;">
									<filesUpload :disabled="isModify && current < projectStage"
										v-model:value="formApproval.source.projectApprovalSourceGovInvestPlan"
										name="政府投资计划文件"
									>
									</filesUpload>
								</div>
							</a-form-item> -->
							<a-form-item label="请上传县领导审批文件">
								<div style="display: flex;height: 30px;">
									<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
									<filesUpload :disabled="isModify && current < projectStage"
										v-model:value="formApproval.source.projectApprovalSourceCountyFile"
										name="县领导审批文件"
									>
									</filesUpload>
								</div>
							</a-form-item>
							<a-form-item label="请上传县政府常务会议纪要">
								<div style="display: flex;height: 30px;">
									<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
									<filesUpload :disabled="isModify && current < projectStage"
										v-model:value="formApproval.source.projectApprovalSourceSummaryFile"
										name="项目立项县政府常务会议纪要"
									>
									</filesUpload>
								</div>
							</a-form-item>
						</a-col>
						<a-col :span="8">
							<a-form-item label="县国资公司" :name="['source','projectApprovalSourceCompanyCost']" required>
								<a-input-number :min="0"
									v-model:value="formApproval.source.projectApprovalSourceCompanyCost"
									:formatter="inputNumberFormat"
									class="ui-inputNumWidth" :disabled="isModify && current < projectStage">
								</a-input-number>元
							</a-form-item>
							<a-form-item label="其他来源资金" :name="['source','projectApprovalSourceOtherCost']" required>
								<a-input-number :min="0"
									v-model:value="formApproval.source.projectApprovalSourceOtherCost"
									:formatter="inputNumberFormat"
									class="ui-inputNumWidth" :disabled="isModify && current < projectStage">
								</a-input-number>元
							</a-form-item>
							<a-form-item label="请上传专项资金文件">
								<div style="display: flex;height: 30px;">
									<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
									<filesUpload :disabled="isModify && current < projectStage"
										v-model:value="formApproval.source.projectApprovalSourceSpecialFile"
										name="专项资金文件"
									>
									</filesUpload>
								</div>
							</a-form-item>
							<a-form-item label="请上传其他审批文件">
								<div style="display: flex;height: 30px;">
									<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
									<filesUpload :disabled="isModify && current < projectStage"
										v-model:value="formApproval.source.projectApprovalSourceOtherFile"
										name="其他审批文件"
									>
									</filesUpload>
								</div>
							</a-form-item>
						</a-col>
						<a-col :span="8">
							<a-form-item label="">
							</a-form-item>
							<a-form-item label="">
							</a-form-item>
						</a-col>
					</a-row>
				</div>
				<!-- 4 项目建议书批复文件-->
				<div>
					<p class="ui-contentMarginTop">
						<span class="ui-tipsStyle">*</span>4、项目建议书批复文件
						<Icon icon="QuestionCircleOutlined" class="ui-icon-style" />
					</p>
					<div class="ui-contentBorder">
						<a-form-item :name="['review', 'projectApprovalReviewStatus']" :rules="[{ required: true, message: '请选择项目建议书批复文件' }]">
							<a-radio-group v-model:value="formApproval.review.projectApprovalReviewStatus"
								:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'projectApprovalReviewStatus')">
								<a-radio :value="0">已立项</a-radio>
								<a-radio :value="1">未立项</a-radio>
								<a-radio :value="2">不需要</a-radio>
							</a-radio-group>
						</a-form-item>
						<div class="ui-cutApart"></div>
						<a-row
							v-if="formApproval.review.projectApprovalReviewStatus == 0 || formApproval.review.projectApprovalReviewStatus == null">
							<a-col :span="8">
								<a-form-item label="批复的概算金额" :name="['review','projectApprovalReviewTotalCost']" required>
									<a-input-number :min="0" placeholder="请输入批复的概算金额"
										v-model:value="formApproval.review.projectApprovalReviewTotalCost"
										class="ui-inputNumWidth"
										:formatter="inputNumberFormat"
										:disabled="isModify && current < projectStage">
									</a-input-number>元
								</a-form-item>
							</a-col>
							<a-col :span="8">
								<a-form-item label="项目代码" :name="['review','projectApprovalReviewCode']" required>
									<a-input placeholder="请输入项目代码"
										v-model:value="formApproval.review.projectApprovalReviewCode"
										class="ui-inputNumWidth"
										:disabled="isModify && current < projectStage">
									</a-input>
								</a-form-item>
							</a-col>
							<a-col :span="8">
								<a-form-item label="请上传立项批复文件" :name="['review','projectApprovalReviewReviewFile']"
									required>
									<div style="display: flex;">
										<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
										<filesUpload :disabled="isModify && current < projectStage"
											v-model:value="formApproval.review.projectApprovalReviewReviewFile"
											name="项目立项批复文件"
										>
										</filesUpload>
										<a-form-item :name="['review','projectApprovalReviewTime']">
											<a-date-picker v-model:value="formApproval.opinion.projectApprovalReviewTime" :value-format="'X'"
											:disabled="(!formApproval.review.projectApprovalReviewReviewFile) || formApproval.review.projectApprovalReviewReviewFile.length == 0"/>
										</a-form-item>
									</div>
								</a-form-item>
							</a-col>
							<a-col :span="8">
								<a-form-item label="请上传项目建议书" :name="['review','projectApprovalReviewReviewBook']"
									:rules="[{ required: true, message: '请上传项目建议书' }]">
									<div style="display: flex;">
										<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
										<filesUpload :disabled="isModify && current < projectStage"
											v-model:value="formApproval.review.projectApprovalReviewReviewBook"
											name="项目建议书"
										>
										</filesUpload>
									</div>
								</a-form-item>
							</a-col>
						</a-row>
					</div>

				</div>
				<!-- 5 用地选址意见书-->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>5、用地选址意见书 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<!-- <a-row class="ui-contentBorder">
						<a-col :span="12">
							<a-form-item label="请上传用地选址意见书" :name="['opinion','projectApprovalOpinionFile']" :rules="[{ required: true, message: '请上传用地选址意见书' }]">
								<div style="display: flex;">
									<Icon icon="ExclamationCircleOutlined" class="ui-icon-style" />
									<filesUpload :disabled="isModify && current < projectStage"
										v-model:value="formApproval.opinion.projectApprovalOpinionFile"
										name="用地选址意见书"
									>
									</filesUpload>
								</div>
							</a-form-item>
						</a-col>
					</a-row> -->
					<div class="ui-contentBorder">
						<a-form-item :name="['opinion','projectApprovalOpinionFlag']" :rules="[{ required: true, message: '请上传用地选址意见书' }]">
							<a-radio-group v-model:value="formApproval.opinion.projectApprovalOpinionFlag"
								:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'projectApprovalOpinionFlag')">
								<a-radio :value="0">需要</a-radio>
								<a-radio :value="1">不需要</a-radio>
							</a-radio-group>
						</a-form-item>
						<div class="ui-cutApart"></div>
						<a-row
							v-if="formApproval.opinion.projectApprovalOpinionFlag == null || formApproval.opinion.projectApprovalOpinionFlag == 0">
							<a-col :span="12">
								<a-form-item label="请上传用地选址意见书" :name="['opinion','projectApprovalOpinionFile']"
									:rules="[{ required: true, message: '请上传用地选址意见书' }]">
									<div style="display: flex;">
										<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
										<filesUpload :disabled="isModify && current < projectStage"
											v-model:value="formApproval.opinion.projectApprovalOpinionFile"
											name="用地选址意见书"
											:limit="1"
										>
										</filesUpload>
										<a-form-item :name="['opinion','projectApprovalOpinionTime']">
											<a-date-picker v-model:value="formApproval.opinion.projectApprovalOpinionTime" :value-format="'X'"
											:disabled="(!formApproval.opinion?.projectApprovalOpinionFile) || formApproval.opinion?.projectApprovalOpinionFile.length == 0"/>
										</a-form-item>
									</div>
								</a-form-item>
							</a-col>
						</a-row>
					</div>
				</div>
				<!-- 6 可研批复-->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>6、可研批复 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-contentBorder">
						<a-form-item :name="['feasibility', 'projectApprovalHasFeasibility']" :rules="[{ required: true, message: '请选择可研批复' }]">
							<a-radio-group v-model:value="formApproval.feasibility.projectApprovalHasFeasibility"
								:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'projectApprovalHasFeasibility')">
								<a-radio :value="0">有可研</a-radio>
								<a-radio :value="1">无可研</a-radio>
								<a-radio :value="2">不需要</a-radio>
							</a-radio-group>
						</a-form-item>
						<div class="ui-cutApart"></div>
						<a-row
							v-if="formApproval.feasibility.projectApprovalHasFeasibility == null || formApproval.feasibility.projectApprovalHasFeasibility == 0">
							<a-col :span="12">
								<a-form-item label="请上传可研批复文件" :name="['feasibility','projectApprovalFeasibilityFile']"
									:rules="[{ required: true, message: '请上传可研批复文件' }]">
									<div style="display: flex;">
										<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
										<filesUpload :disabled="isModify && current < projectStage"
											v-model:value="formApproval.feasibility.projectApprovalFeasibilityFile"
											name="可研批复文件"
										>
										</filesUpload>
										<a-form-item :name="['feasibility','projectApprovalFeasibilityTime']">
											<a-date-picker v-model:value="formApproval.feasibility.projectApprovalFeasibilityTime" :value-format="'X'"
											:disabled="(!formApproval.feasibility?.projectApprovalFeasibilityFile) || formApproval.feasibility?.projectApprovalFeasibilityFile.length == 0"/>
										</a-form-item>
									</div>
								</a-form-item>
							</a-col>
						</a-row>
					</div>

				</div>
				<!-- 7 用地规划许可证、工程规划许可证-->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>7、用地规划许可证、工程规划许可证 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<a-row class="ui-contentBorder">
						<a-col :span="8">
							<a-form-item label="请上传用地规划许可证" :name="['permit','projectApprovalPermitLandFile']"
								:rules="[{ required: true, message: '请上传用地规划许可证' }]">
								<div style="display: flex;">
									<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
									<filesUpload :disabled="isModify && current < projectStage"
										v-model:value="formApproval.permit.projectApprovalPermitLandFile"
										name="用地规划许可证"
										:limit="1"
									>
									</filesUpload>
									<a-form-item :name="['register','buildLandRegisterPermitTime']">
										<a-date-picker v-model:value="formApproval.permit.projectApprovalPermitLandTime" :value-format="'X'"
										:disabled="(!formApproval.permit?.projectApprovalPermitLandFile) || formApproval.permit?.projectApprovalPermitLandFile.length == 0"/>
									</a-form-item>
								</div>
							</a-form-item>
						</a-col>
						<a-col :span="8">
							<a-form-item label="请上传工程规划许可证" :name="['permit','projectApprovalPermitProjectFIle']"
								:rules="[{ required: true, message: '请上传工程规划许可证' }]">
								<div style="display: flex;">
									<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
									<filesUpload :disabled="isModify && current < projectStage"
										v-model:value="formApproval.permit.projectApprovalPermitProjectFIle"
										name="工程规划许可证"
										:limit="1"
									>
									</filesUpload>
									<a-form-item :name="['permit','projectApprovalPermitProjectTime']">
										<a-date-picker v-model:value="formApproval.permit.projectApprovalPermitProjectTime" :value-format="'X'"
										:disabled="(!formApproval.permit?.projectApprovalPermitProjectFIle) || formApproval.permit?.projectApprovalPermitProjectFIle.length == 0"/>
									</a-form-item>
								</div>
							</a-form-item>
						</a-col>
					</a-row>
				</div>
				<!-- 8社会风险稳定评估 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>8、社会风险稳定评估 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-contentBorder">
						<a-form-item :name="['risk', 'projectApprovalHasRisk']" :rules="[{ required: true, message: '请选择社会风险稳定评估' }]">
							<a-radio-group v-model:value="formApproval.risk.projectApprovalHasRisk"
								:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'projectApprovalHasRisk')">
								<a-radio :value="0">有备案表</a-radio>
								<a-radio :value="1">无备案表</a-radio>
								<a-radio :value="2">不需要</a-radio>
							</a-radio-group>
						</a-form-item>
						<div class="ui-cutApart"></div>
						<a-row
							v-if="formApproval.risk.projectApprovalHasRisk == null || formApproval.risk.projectApprovalHasRisk == 0">
							<a-col :span="12">
								<a-form-item label="请上传社会风险稳定评估备案表" :name="['risk','projectApprovalRiskFile']"
									:rules="[{ required: true, message: '请上传社会风险稳定评估备案表' }]">
									<div style="display: flex;">
										<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
										<filesUpload :disabled="isModify && current < projectStage"
											v-model:value="formApproval.risk.projectApprovalRiskFile"
											name="社会风险稳定评估备案表"
										>
										</filesUpload>
										<a-form-item :name="['risk','projectApprovalRiskTime']">
											<a-date-picker v-model:value="formApproval.risk.projectApprovalRiskTime" :value-format="'X'"
											:disabled="(!formApproval.risk?.projectApprovalRiskFile) || formApproval.risk?.projectApprovalRiskFile.length == 0"/>
										</a-form-item>
									</div>
								</a-form-item>
							</a-col>
						</a-row>
					</div>

				</div>
				<!-- 9 初步设计批复-->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>9、初步设计批复 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-contentBorder">
						<a-form-item :name="['design', 'projectApprovalHasDesign']" :rules="[{ required: true, message: '请选择初步设计批复' }]">
							<a-radio-group v-model:value="formApproval.design.projectApprovalHasDesign"
								:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'projectApprovalHasDesign')">
								<a-radio :value="0">有初步批复</a-radio>
								<a-radio :value="1">无初步批复</a-radio>
								<a-radio :value="2">不需要</a-radio>
							</a-radio-group>
						</a-form-item>
						<div class="ui-cutApart"></div>
						<a-row
							v-if="formApproval.design.projectApprovalHasDesign == null || formApproval.design.projectApprovalHasDesign == 0">
							<a-col :span="12">
								<a-form-item label="请上传初步设计批复" :name="['design','projectApprovalDesignFile']"
									:rules="[{ required: true, message: '请上传初步设计批复' }]">
									<div style="display: flex;">
										<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
										<filesUpload :disabled="isModify && current < projectStage"
											v-model:value="formApproval.design.projectApprovalDesignFile"
											name="初步设计批复"
										>
										</filesUpload>
										<a-form-item :name="['design','projectApprovalDesignTime']">
											<a-date-picker v-model:value="formApproval.design.projectApprovalDesignTime" :value-format="'X'"
											:disabled="(!formApproval.design?.projectApprovalDesignFile) || formApproval.design?.projectApprovalDesignFile == 0"/>
										</a-form-item>
									</div>
								</a-form-item>
							</a-col>
						</a-row>
					</div>

				</div>
				<!-- 10 环境影响评估-->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>10、环境影响评估 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-contentBorder">
						<a-form-item :name="['env', 'projectApprovalHasEnv']" :rules="[{ required: true, message: '请选择环境影响评估' }]">
							<a-radio-group v-model:value="formApproval.env.projectApprovalHasEnv"
								:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'projectApprovalHasEnv')">
								<a-radio :value="0">有环评</a-radio>
								<a-radio :value="1">无环评</a-radio>
								<a-radio :value="2">不需要</a-radio>
							</a-radio-group>
						</a-form-item>
						<div class="ui-cutApart"></div>
						<a-row
							v-if="formApproval.env.projectApprovalHasEnv == null || formApproval.env.projectApprovalHasEnv == 0">
							<a-col :span="8">
								<a-form-item label="请上传环评批复" :name="['env','projectApprovalEnvApprovalFile']"
									:rules="[{ required: true, message: '请上传环评批复' }]">
									<div style="display: flex;">
										<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
										<filesUpload :disabled="isModify && current < projectStage"
											v-model:value="formApproval.env.projectApprovalEnvApprovalFile"
											name="环评批复文件"
										>
										</filesUpload>
										<a-form-item :name="['env','projectApprovalEnvApprovalTime']">
											<a-date-picker v-model:value="formApproval.env.projectApprovalEnvApprovalTime" :value-format="'X'"
											:disabled="(!formApproval.env?.projectApprovalEnvApprovalFile) || formApproval.env?.projectApprovalEnvApprovalFile.length == 0"/>
										</a-form-item>
									</div>
								</a-form-item>
							</a-col>
							<a-col :span="8">
								<a-form-item label="请上传环评备案文件" :name="['env','projectApprovalEnvRecordFile']"
									:rules="[{ required: true, message: '请上传环评备案文件' }]">
									<div style="display: flex;">
										<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
										<filesUpload :disabled="isModify && current < projectStage"
											v-model:value="formApproval.env.projectApprovalEnvRecordFile"
											name="环评备案文件"
										>
										</filesUpload>
									</div>
								</a-form-item>
							</a-col>
						</a-row>
					</div>
				</div>
				<!-- 11、水土保持方案 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>11、水土保持方案 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-contentBorder">
						<a-form-item :name="['keep', 'projectApprovalHasKeep']" :rules="[{ required: true, message: '请选择水土保持方案' }]">
							<a-radio-group v-model:value="formApproval.keep.projectApprovalHasKeep"
								:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'projectApprovalHasKeep')">
								<a-radio :value="0">有水保方案</a-radio>
								<a-radio :value="1">无水保方案</a-radio>
								<a-radio :value="2">不需要</a-radio>
							</a-radio-group>
						</a-form-item>
						<div class="ui-cutApart"></div>
						<a-row :gutter="24"
							v-if="formApproval.keep.projectApprovalHasKeep == null || formApproval.keep.projectApprovalHasKeep ==0">
							<a-col :span="12">
								<a-form-item label="请上传水保方案" :name="['keep','projectApprovalKeepFile']"
									:rules="[{ required: true, message: '请上传水保方案' }]">
									<div style="display: flex;">
										<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
										<filesUpload :disabled="isModify && current < projectStage"
											v-model:value="formApproval.keep.projectApprovalKeepFile"
											name="水保方案"
										>
										</filesUpload>
									</div>
								</a-form-item>
							</a-col>
						</a-row>
					</div>
				</div>
				<!-- 12防洪影响评价 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>12、防洪影响评价 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-contentBorder">
						<a-form-item :name="['evaluate', 'projectApprovalHasEvaluate']" :rules="[{ required: true, message: '请选择防洪影响评价' }]">
							<a-radio-group v-model:value="formApproval.evaluate.projectApprovalHasEvaluate"
								:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'projectApprovalHasEvaluate')">
								<a-radio :value="0">有防洪评价</a-radio>
								<a-radio :value="1">无防洪评价</a-radio>
								<a-radio :value="2">不需要</a-radio>
							</a-radio-group>
						</a-form-item>
						<div class="ui-cutApart"></div>
						<a-row :gutter="24"
							v-if="formApproval.evaluate.projectApprovalHasEvaluate == null || formApproval.evaluate.projectApprovalHasEvaluate == 0">
							<a-col :span="12">
								<a-form-item label="请上传防洪评价" :name="['evaluate','projectApprovalEvaluateFile']"
									:rules="[{ required: true, message: '请上传防洪评价' }]">
									<div style="display: flex;">
										<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
										<filesUpload :disabled="isModify && current < projectStage"
											v-model:value="formApproval.evaluate.projectApprovalEvaluateFile"
											name="防洪评价"
										>
										</filesUpload>
									</div>
								</a-form-item>
							</a-col>
						</a-row>
					</div>
				</div>
				<!-- 13 节能审批-->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>13、节能审批 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-contentBorder">
						<a-form-item :name="['ec', 'projectApprovalHasEc']" :rules="[{ required: true, message: '请选择节能审批' }]">
							<a-radio-group v-model:value="formApproval.ec.projectApprovalHasEc"
								:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'projectApprovalHasEc')">
								<a-radio :value="0">有节能审批</a-radio>
								<a-radio :value="1">无节能审批</a-radio>
								<a-radio :value="2">不需要</a-radio>
							</a-radio-group>
						</a-form-item>
						<div class="ui-cutApart"></div>
						<a-row :gutter="24"
							v-if="formApproval.ec.projectApprovalHasEc == null || formApproval.ec.projectApprovalHasEc == 0">
							<a-col :span="12">
								<a-form-item label="请上传节能审批文件" :name="['ec','projectApprovalEcFIle']"
									:rules="[{ required: true, message: '请上传节能审批文件' }]">
									<div style="display: flex;">
										<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
										<filesUpload :disabled="isModify && current < projectStage"
											v-model:value="formApproval.ec.projectApprovalEcFIle"
											name="节能审批文件"
										>
										</filesUpload>
										<a-form-item :name="['ec','projectApprovalEcTime']">
											<a-date-picker v-model:value="formApproval.ec.projectApprovalEcTime" :value-format="'X'"
											:disabled="(!formApproval.ec?.projectApprovalEcFIle) || formApproval.ec?.projectApprovalEcFIle.length == 0"/>
										</a-form-item>
									</div>
								</a-form-item>
							</a-col>
						</a-row>
					</div>
				</div>
				<!-- 14消防审批 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>14、消防审批 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-contentBorder">
						<a-form-item :name="['fire', 'projectApprovalHasFire']" :rules="[{ required: true, message: '请选择消防审批' }]">
							<a-radio-group v-model:value="formApproval.fire.projectApprovalHasFire"
								:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'projectApprovalHasFire')">
								<a-radio :value="0">有消防审批</a-radio>
								<a-radio :value="1">无消防审批</a-radio>
								<a-radio :value="2">不需要</a-radio>
							</a-radio-group>
						</a-form-item>
						<div class="ui-cutApart"></div>
						<a-row :gutter="24"
							v-if="formApproval.fire.projectApprovalHasFire == null || formApproval.fire.projectApprovalHasFire == 0">
							<a-col :span="12">
								<a-form-item label="请上传消防审批意见文书" :name="['fire','projectApprovalFireFile']"
									:rules="[{ required: true, message: '请上传消防审批意见文书' }]">
									<div style="display: flex;">
										<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
										<filesUpload :disabled="isModify && current < projectStage"
											v-model:value="formApproval.fire.projectApprovalFireFile"
											name="消防审批意见文书"
										>
										</filesUpload>
										<a-form-item :name="['fire','projectApprovalFireTime']">
											<a-date-picker v-model:value="formApproval.fire.projectApprovalFireTime" :value-format="'X'"
											:disabled="(!formApproval.fire?.projectApprovalFireFile) || formApproval.fire?.projectApprovalFireFile.length == 0"/>
										</a-form-item>
									</div>
								</a-form-item>
							</a-col>
						</a-row>
					</div>
				</div>
				<!-- 15人防审批 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>15、人防审批 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-contentBorder">
						<a-form-item :name="['pad', 'projectApprovalHasPad']" :rules="[{ required: true, message: '请选择人防审批' }]">
							<a-radio-group v-model:value="formApproval.pad.projectApprovalHasPad"
								:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'projectApprovalHasPad')">
								<a-radio :value="0">有人防审批</a-radio>
								<a-radio :value="1">无人防审批</a-radio>
								<a-radio :value="2">不需要</a-radio>
							</a-radio-group>
						</a-form-item>
						<div class="ui-cutApart"></div>
						<a-row :gutter="24"
							v-if="formApproval.pad.projectApprovalHasPad == null || formApproval.pad.projectApprovalHasPad == 0">
							<a-col :span="12">
								<a-form-item label="请上传人防审批意见文书" :name="['pad','projectApprovalPadFile']"
									:rules="[{ required: true, message: '请上传人防审批意见文书' }]">
									<div style="display: flex;">
										<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
										<filesUpload :disabled="isModify && current < projectStage"
											v-model:value="formApproval.pad.projectApprovalPadFile"
											name="人防审批意见文书"
										>
										</filesUpload>
										<a-form-item :name="['pad','projectApprovalPadTime']">
											<a-date-picker v-model:value="formApproval.pad.projectApprovalPadTime" :value-format="'X'"
											:disabled="(!formApproval.pad?.projectApprovalPadFile) || formApproval.pad?.projectApprovalPadFile.length == 0"/>
										</a-form-item>
									</div>
								</a-form-item>
							</a-col>
						</a-row>
					</div>
				</div>
				<!-- 16 预算评审-->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>16、预算评审 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-contentBorder">
						<a-row :gutter="24">
							<a-col :span="12">
								<a-form-item label="请上传预算评审报告" :name="['budget','projectApprovalBudgetReport']"
									:rules="[{ required: true, message: '请上传预算评审报告' }]">
									<div style="display: flex;">
										<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
										<filesUpload :disabled="isModify && current < projectStage"
											v-model:value="formApproval.budget.projectApprovalBudgetReport"
											name="预算评审报告"
										>
										</filesUpload>
									</div>
								</a-form-item>
							</a-col>
						</a-row>
					</div>
				</div>
				<!-- 17 班子会会议纪要-->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>17、班子会会议纪要 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-contentBorder">
						<a-form-item label="会议纪要涉及内容" :name="['meeting','projectApprovalMeetingSelected']"
							:rules="[{ required: true }]">
							<a-checkbox-group v-model:value="formApproval.meeting.projectApprovalMeetingSelected"
								name="checkboxgroup" :options="summaryOptions"
								:disabled="isModify && current < projectStage" />
							<!-- <a-input v-if="showOrderInput" class="ui-inputNumWidth" :disabled="isModify && current < projectStage"></a-input> -->
							<a-form-item-rest>
								<a-input class="ui-inputNumWidth"
									v-model:value="formApproval.meeting.projectApprovalMeetingContent"
									placeholder="请输入会议涉及内容" :disabled="isModify && current < projectStage"
									v-if="formApproval.meeting.projectApprovalMeetingSelected == 7">
								</a-input>
							</a-form-item-rest>
						</a-form-item>
						<a-row :gutter="24">
							<a-col :span="12">
								<a-form-item label="请上传项目立项会议纪要" :name="['meeting','projectApprovalMeetingSummary']"
									:rules="[{ required: true, message: '请上传项目立项会议纪要' }]">
									<div style="display: flex;">
										<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
										<filesUpload :disabled="isModify && current < projectStage"
											v-model:value="formApproval.meeting.projectApprovalMeetingSummary"
											name="项目立项会议纪要"
										>
										</filesUpload>
									</div>
								</a-form-item>
							</a-col>
						</a-row>
					</div>
				</div>
				<!-- 18 本阶段聘请中介的中标通知书（或电子卖场或中介超市成交通知书）、合同、费用支付情况-->
				<div>
					<p class="ui-contentMarginTop"><span
							class="ui-tipsStyle">*</span>18、本阶段聘请中介的中标通知书（或电子卖场或中介超市成交通知书）、合同、费用支付情况 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-childBorderStyle">
						<p>18.1、聘请可研编制 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
						<div style="margin-left: 20px;">
							<a-row :gutter="24">
								<a-col :span="6">
									<a-form-item label="请上传中标通知书或成交确认书">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formApproval.speed.feasibility.projectApprovalSpeedFeasibilityNotice"
												name="聘请可研编制中标通知书或成交确认书"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="请上传合同">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formApproval.speed.feasibility.projectApprovalSpeedFeasibilityContract"
												name="聘请可研编制合同"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="合同金额">
										<a-input-number :min="0" placeholder="请输入合同金额"
											v-model:value="formApproval.speed.feasibility.projectApprovalSpeedFeasibilityCost"
											class="ui-inputNumWidth"
											:formatter="inputNumberFormat"
											:disabled="isModify && current < projectStage"></a-input-number>元
									</a-form-item>
								</a-col>
								<a-col :span="6">
									<a-form-item></a-form-item>
									<a-form-item></a-form-item>
									<a-form-item label="已支付金额">
										{{ inputNumberFormat(commonAlreadyPay(formApproval.speed.feasibility.projectApprovalSpeedFeasibilityPayment)) }}元
										<!-- {{ countPaid('employFeasibilityPaid',formApproval.employFeasibilityList,'formApproval') }} -->
										<!-- {{ countPaid('employFeasibilityPaid',formApproval.speed.feasibility.projectApprovalSpeedFeasibilityPayment,'formApproval') }} -->
									</a-form-item>
								</a-col>
								<a-col :span="6">
									<a-form-item label=""></a-form-item>
									<a-form-item label=""></a-form-item>
									<a-form-item label="待支付金额">
										{{ inputNumberFormat(handleAwaitPrice(((formApproval?.speed?.feasibility?.projectApprovalSpeedFeasibilityCost || 0) - commonAlreadyPay(formApproval.speed.feasibility.projectApprovalSpeedFeasibilityPayment)))) }} 元
										<!-- {{ countAwaitPrice('employFeasibilityPaid','employFeasibilityAwaitPaid','employFeasibilityAllPrice','formApproval') }} -->
									</a-form-item>
								</a-col>
							</a-row>
							<p>付款记录：</p>
							<div class="ui-contentBorderBox"
								v-for="(item,index) in formApproval.speed.feasibility.projectApprovalSpeedFeasibilityPayment"
								:key="index">
								<div class="ui-contentWhiteBorder">
									<a-row :gutter="24">
										<a-col :span="10">
											<a-form-item label="付款金额">
												<a-input-number :min="0" placeholder="请输入付款金额"
													v-model:value="item.amount" class="ui-inputNumWidth"
													:formatter="inputNumberFormat"
													@blur="changePrice(formApproval.speed.feasibility.projectApprovalSpeedFeasibilityCost,formApproval.speed.feasibility.projectApprovalSpeedFeasibilityPayment,index)"
													:disabled="(isModify && current < projectStage && item.isDisabled) || (item.isDisabled && current !== projectStage && !item.isNew)">
												</a-input-number>元
											</a-form-item>
											<a-form-item label="请上传发票">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="(isModify && current < projectStage && item.isDisabled) || (item.isDisabled && current !== projectStage)"
														v-model:value="item.ticket"
														:index="index+1"
														name="聘请可研编制支付发票"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
										<a-col :span="10">
											<a-form-item label="资金来源">
												<a-select placeholder="请选择资金来源" v-model:value="item.source"
													class="ui-inputNumWidth"
													:disabled="(isModify && current < projectStage && item.isDisabled) || (item.isDisabled && current !== projectStage)">
													<a-select-option :value="0">上级专项资金</a-select-option>
													<a-select-option :value="1">县国资公司</a-select-option>
													<a-select-option :value="2">县本级财政资金</a-select-option>
													<a-select-option :value="3">其他</a-select-option>
												</a-select>
											</a-form-item>
											<a-form-item label="请上传支付凭证">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="(isModify && current < projectStage && item.isDisabled) || (item.isDisabled && current !== projectStage)"
														v-model:value="item.voucher"
														:index="index+1"
														name="聘请可研编制支付凭证"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
										<!-- <a-col :span="12">
									        <a-button @click="onAddPayRecord(formApproval.speed.feasibility.projectApprovalSpeedFeasibilityPayment,index)" type="primary">新增付款记录</a-button>
									        <a-button @click="onDeletePayRecord(formApproval.speed.feasibility.projectApprovalSpeedFeasibilityPayment,index)" type="primary" ghost v-if="formApproval.speed.feasibility.projectApprovalSpeedFeasibilityPayment.length >= 1">删除此记录</a-button>
									    </a-col> -->
									</a-row>
								</div>
								<div>
									<div class="ui-plusIconBox">
										<Icon width="60" class="ui-icon-plusStyle" icon="PlusOutlined" />
										<a-button type="text" size="large"
											@click="onAddPayRecord(formApproval.speed.feasibility.projectApprovalSpeedFeasibilityCost,formApproval.speed.feasibility.projectApprovalSpeedFeasibilityPayment,{amount: null,source: null,ticket: [],voucher: [],isDisabled:false})">
											新增付款记录</a-button>
									</div>
									<!-- <a-button @click="onAddPayRecord(formApproval.speed.feasibility.projectApprovalSpeedFeasibilityPayment,index)" type="primary">新增付款记录</a-button> -->
								</div>
							</div>
						</div>
					</div>
					<div class="ui-childBorderStyle">
						<p>18.2、聘请施工图设计 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
						<div style="margin-left: 20px;">
							<a-row :gutter="24">
								<a-col :span="6">
									<a-form-item label="请上传中标通知书或成交确认书">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formApproval.speed.designer.projectApprovalSpeedDesignerNotice"
												name="聘请施工图设计中标通知书或成交确认书"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="请上传合同">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formApproval.speed.designer.projectApprovalSpeedDesignerContract"
												name="聘请施工图设计合同"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="合同金额">
										<a-input-number :min="0" placeholder="请输入合同金额"
											v-model:value="formApproval.speed.designer.projectApprovalSpeedDesignerCost"
											class="ui-inputNumWidth"
											:formatter="inputNumberFormat"
											:disabled="isModify && current < projectStage"></a-input-number>元
									</a-form-item>
								</a-col>
								<a-col :span="6">
									<a-form-item label=""></a-form-item>
									<a-form-item label=""></a-form-item>
									<a-form-item label="已支付金额">
										{{ inputNumberFormat(commonAlreadyPay(formApproval.speed.designer.projectApprovalSpeedDesignerPayment)) }}元
										<!-- {{ countPaid('employDesignerPrice',formApproval.employDesignerList,'formApproval') }} -->
									</a-form-item>
								</a-col>
								<a-col :span="6">
									<a-form-item label=""></a-form-item>
									<a-form-item label=""></a-form-item>
									<a-form-item label="待支付金额">
										{{ inputNumberFormat(handleAwaitPrice((formApproval.speed.designer.projectApprovalSpeedDesignerCost - commonAlreadyPay(formApproval.speed.designer.projectApprovalSpeedDesignerPayment)))) }}元
										<!-- {{ countAwaitPrice('employDesignerPrice','employDesignerAwaitPrice','employDesignerAllPrice','formApproval') }} -->
									</a-form-item>
								</a-col>
							</a-row>
							<p>付款记录：</p>
							<div class="ui-contentBorderBox"
								v-for="(item,index) in formApproval.speed.designer.projectApprovalSpeedDesignerPayment"
								:key="index">
								<div class="ui-contentWhiteBorder">
									<a-row :gutter="24">
										<a-col :span="10">
											<a-form-item label="付款金额">
												<a-input-number :min="0" placeholder="请输入付款金额"
													v-model:value="item.amount" class="ui-inputNumWidth"
													:formatter="inputNumberFormat"
													@blur="changePrice(formApproval.speed.designer.projectApprovalSpeedDesignerCost,formApproval.speed.designer.projectApprovalSpeedDesignerPayment,index)"
													:disabled="current < projectStage && item.isDisabled">
												</a-input-number>元
											</a-form-item>
											<a-form-item label="请上传发票">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.ticket"
														:index="index+1"
														name="聘请施工图设计付款发票"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
										<a-col :span="10">
											<a-form-item label="资金来源">
												<a-select placeholder="请选择资金来源" v-model:value="item.source"
													class="ui-inputNumWidth"
													:disabled="current < projectStage && item.isDisabled">
													<a-select-option :value="0">上级专项资金</a-select-option>
													<a-select-option :value="1">县国资公司</a-select-option>
													<a-select-option :value="2">县本级财政资金</a-select-option>
													<a-select-option :value="3">其他</a-select-option>
												</a-select>
											</a-form-item>
											<a-form-item label="请上传支付凭证">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.voucher"
														:index="index+1"
														name="聘请施工图设计支付凭证"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
										<!-- <a-col :span="12">
									            <a-button @click="onAddPayRecord(formApproval.speed.designer.projectApprovalSpeedDesignerPayment,index)" type="primary">新增付款记录</a-button>
									            <a-button @click="onDeletePayRecord(formApproval.speed.designer.projectApprovalSpeedDesignerPayment,index)" type="primary" ghost v-if="formApproval.speed.designer.projectApprovalSpeedDesignerPayment.length >= 1">删除此记录</a-button>
									        </a-col> -->
									</a-row>
								</div>
								<div>
									<!-- <a-button @click="onAddPayRecord(formApproval.speed.designer.projectApprovalSpeedDesignerPayment,index)" type="primary">新增付款记录</a-button> -->
									<div class="ui-plusIconBox">
										<Icon width="60" class="ui-icon-plusStyle" icon="PlusOutlined" />
										<a-button type="text" size="large"
											@click="onAddPayRecord(formApproval.speed.designer.projectApprovalSpeedDesignerCost,formApproval.speed.designer.projectApprovalSpeedDesignerPayment,{amount: null,source: null,ticket: [],voucher: [],isDisabled:false})">
											新增付款记录</a-button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="ui-childBorderStyle">
						<p>18.3、聘请图审 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
						<div style="margin-left: 20px;">
							<a-row :gutter="24">
								<a-col :span="6">
									<a-form-item label="请上传中标通知书或成交确认书">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formApproval.speed.checker.projectApprovalSpeedCheckerNotice"
												name="聘请图审中标通知书或成交确认书"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="请上传合同">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formApproval.speed.checker.projectApprovalSpeedCheckerContract"
												name="聘请图审合同"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="合同金额">
										<a-input-number :min="0" placeholder="请输入合同金额"
											v-model:value="formApproval.speed.checker.projectApprovalSpeedCheckerCost"
											class="ui-inputNumWidth"
											:formatter="inputNumberFormat"
											:disabled="isModify && current < projectStage"></a-input-number>元
									</a-form-item>
								</a-col>
								<a-col :span="6">
									<a-form-item label=""></a-form-item>
									<a-form-item label=""></a-form-item>
									<a-form-item label="已支付金额">
										{{ inputNumberFormat(commonAlreadyPay(formApproval.speed.checker.projectApprovalSpeedCheckerPayment)) }}元
										<!-- {{ countPaid('employDesignerPrice',formApproval.employDesignerList,'formApproval') }} -->
									</a-form-item>
								</a-col>
								<a-col :span="6">
									<a-form-item label=""></a-form-item>
									<a-form-item label=""></a-form-item>
									<a-form-item label="待支付金额">
										{{ inputNumberFormat(handleAwaitPrice((formApproval.speed.checker.projectApprovalSpeedCheckerCost - commonAlreadyPay(formApproval.speed.checker.projectApprovalSpeedCheckerPayment)))) }}元
										<!-- {{ countAwaitPrice('employDesignerPrice','employDesignerAwaitPrice','employDesignerAllPrice','formApproval') }} -->
									</a-form-item>
								</a-col>
							</a-row>
							<p>付款记录：</p>
							<div class="ui-contentBorderBox"
								v-for="(item,index) in formApproval.speed.checker.projectApprovalSpeedCheckerPayment"
								:key="index">
								<div class="ui-contentWhiteBorder">
									<a-row :gutter="24">
										<a-col :span="10">
											<a-form-item label="付款金额">
												<a-input-number :min="0" placeholder="请输入付款金额"
													v-model:value="item.amount" class="ui-inputNumWidth"
													:formatter="inputNumberFormat"
													@blur="changePrice(formApproval.speed.checker.projectApprovalSpeedCheckerCost,formApproval.speed.checker.projectApprovalSpeedCheckerPayment,index)"
													:disabled="current < projectStage && item.isDisabled">
												</a-input-number>元
											</a-form-item>
											<a-form-item label="请上传发票">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.ticket"
														:index="index+1"
														name="聘请图审付款发票"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
										<a-col :span="10">
											<a-form-item label="资金来源">
												<a-select placeholder="请选择资金来源" v-model:value="item.source"
													:disabled="current < projectStage && item.isDisabled"
													class="ui-inputNumWidth">
													<a-select-option :value="0">上级专项资金</a-select-option>
													<a-select-option :value="1">县国资公司</a-select-option>
													<a-select-option :value="2">县本级财政资金</a-select-option>
													<a-select-option :value="3">其他</a-select-option>
												</a-select>
											</a-form-item>
											<a-form-item label="请上传支付凭证">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.voucher"
														:index="index+1"
														name="聘请图审支付凭证"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
									</a-row>
								</div>
								<div>
									<div class="ui-plusIconBox">
										<Icon width="60" class="ui-icon-plusStyle" icon="PlusOutlined" />
										<a-button type="text" size="large"
											@click="onAddPayRecord(formApproval.speed.checker.projectApprovalSpeedCheckerCost,formApproval.speed.checker.projectApprovalSpeedCheckerPayment,{amount: null,source: null,ticket: [],voucher: [],isDisabled:false})">
											新增付款记录</a-button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="ui-childBorderStyle">
						<p>18.4、聘请测绘 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
						<div style="margin-left: 20px;">
							<a-row :gutter="24">
								<a-col :span="6">
									<a-form-item label="请上传中标通知书或成交确认书">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formApproval.speed.surveyor.projectApprovalSpeedSurveyorNotice"
												name="聘请测绘中标通知书或成交确认书"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="请上传合同">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formApproval.speed.surveyor.projectApprovalSpeedSurveyorContract"
												name="聘请测绘合同"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="合同金额">
										<a-input-number :min="0" placeholder="请输入合同金额"
											v-model:value="formApproval.speed.surveyor.projectApprovalSpeedSurveyorCost"
											class="ui-inputNumWidth"
											:formatter="inputNumberFormat"
											:disabled="isModify && current < projectStage"></a-input-number>元
									</a-form-item>
								</a-col>
								<a-col :span="6">
									<a-form-item label=""></a-form-item>
									<a-form-item label=""></a-form-item>
									<a-form-item label="已支付金额">
										{{ inputNumberFormat(commonAlreadyPay(formApproval.speed.surveyor.projectApprovalSpeedSurveyorPayment)) }}元
									</a-form-item>
								</a-col>
								<a-col :span="6">
									<a-form-item label=""></a-form-item>
									<a-form-item label=""></a-form-item>
									<a-form-item label="待支付金额">
										{{ inputNumberFormat(handleAwaitPrice((formApproval.speed.surveyor.projectApprovalSpeedSurveyorCost - commonAlreadyPay(formApproval.speed.surveyor.projectApprovalSpeedSurveyorPayment)))) }}元
									</a-form-item>
								</a-col>
							</a-row>
							<p>付款记录：</p>
							<div class="ui-contentBorderBox"
								v-for="(item,index) in formApproval.speed.surveyor.projectApprovalSpeedSurveyorPayment"
								:key="index">
								<div class="ui-contentWhiteBorder">
									<a-row :gutter="24">
										<a-col :span="10">
											<a-form-item label="付款金额">
												<a-input-number :min="0" placeholder="请输入付款金额"
													v-model:value="item.amount" class="ui-inputNumWidth"
													:formatter="inputNumberFormat"
													@blur="changePrice(formApproval.speed.surveyor.projectApprovalSpeedSurveyorCost,formApproval.speed.surveyor.projectApprovalSpeedSurveyorPayment,index)"
													:disabled="current < projectStage && item.isDisabled">
												</a-input-number>元
											</a-form-item>
											<a-form-item label="请上传发票">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.ticket"
														:index="index+1"
														name="聘请测绘付款发票"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
										<a-col :span="10">
											<a-form-item label="资金来源">
												<a-select placeholder="请选择资金来源" v-model:value="item.source"
													class="ui-inputNumWidth"
													:disabled="current < projectStage && item.isDisabled">
													<a-select-option :value="0">上级专项资金</a-select-option>
													<a-select-option :value="1">县国资公司</a-select-option>
													<a-select-option :value="2">县本级财政资金</a-select-option>
													<a-select-option :value="3">其他</a-select-option>
												</a-select>
											</a-form-item>
											<a-form-item label="请上传支付凭证">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.voucher"
														:index="index+1"
														name="聘请测绘支付凭证"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
									</a-row>
								</div>
								<div>
									<div class="ui-plusIconBox">
										<Icon width="60" class="ui-icon-plusStyle" icon="PlusOutlined" />
										<a-button type="text" size="large"
											@click="onAddPayRecord(formApproval.speed.surveyor.projectApprovalSpeedSurveyorCost,formApproval.speed.surveyor.projectApprovalSpeedSurveyorPayment,{amount: null,source: null,ticket: [],voucher: [], isDisabled:false})">
											新增付款记录</a-button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="ui-childBorderStyle">
						<p>18.5、聘请地勘 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
						<div style="margin-left: 20px;">
							<a-row :gutter="24">
								<a-col :span="6">
									<a-form-item label="请上传中标通知书或成交确认书">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formApproval.speed.geological.projectApprovalSpeedGeologicalNotice"
												name="聘请地勘中标通知书或成交确认书"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="请上传合同">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formApproval.speed.geological.projectApprovalSpeedGeologicalContract"
												name="聘请地勘合同"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="合同金额">
										<a-input-number :min="0" placeholder="请输入合同金额"
											v-model:value="formApproval.speed.geological.projectApprovalSpeedGeologicalCost"
											class="ui-inputNumWidth"
											:formatter="inputNumberFormat"
											:disabled="isModify && current < projectStage"></a-input-number>元
									</a-form-item>
								</a-col>
								<a-col :span="6">
									<a-form-item label=""></a-form-item>
									<a-form-item label=""></a-form-item>
									<a-form-item label="已支付金额">
										{{ inputNumberFormat(commonAlreadyPay(formApproval.speed.geological.projectApprovalSpeedGeologicalPayment)) }}元
									</a-form-item>
								</a-col>
								<a-col :span="6">
									<a-form-item label=""></a-form-item>
									<a-form-item label=""></a-form-item>
									<a-form-item label="待支付金额">
										{{ inputNumberFormat(handleAwaitPrice((formApproval.speed.geological.projectApprovalSpeedGeologicalCost - commonAlreadyPay(formApproval.speed.geological.projectApprovalSpeedGeologicalPayment)))) }}元
									</a-form-item>
								</a-col>
							</a-row>
							<p>付款记录：</p>
							<div class="ui-contentBorderBox"
								v-for="(item,index) in formApproval.speed.geological.projectApprovalSpeedGeologicalPayment"
								:key="index">
								<div class="ui-contentWhiteBorder">
									<a-row :gutter="24">
										<a-col :span="10">
											<a-form-item label="付款金额">
												<a-input-number :min="0" placeholder="请输入付款金额"
													v-model:value="item.amount" class="ui-inputNumWidth" :formatter="inputNumberFormat"
													@blur="changePrice(formApproval.speed.geological.projectApprovalSpeedGeologicalCost,formApproval.speed.geological.projectApprovalSpeedGeologicalPayment,index)"
													:disabled="current < projectStage && item.isDisabled">
												</a-input-number>元
											</a-form-item>
											<a-form-item label="请上传发票">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.ticket"
														:index="index+1"
														name="聘请地勘付款发票"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
										<a-col :span="10">
											<a-form-item label="资金来源">
												<a-select placeholder="请选择资金来源" v-model:value="item.source"
													class="ui-inputNumWidth"
													:disabled="current < projectStage && item.isDisabled">
													<a-select-option :value="0">上级专项资金</a-select-option>
													<a-select-option :value="1">县国资公司</a-select-option>
													<a-select-option :value="2">县本级财政资金</a-select-option>
													<a-select-option :value="3">其他</a-select-option>
												</a-select>
											</a-form-item>
											<a-form-item label="请上传支付凭证">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.voucher"
														:index="index+1"
														name="聘请地勘支付凭证"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
									</a-row>
								</div>
								<div>
									<div class="ui-plusIconBox">
										<Icon width="60" class="ui-icon-plusStyle" icon="PlusOutlined" />
										<a-button type="text" size="large"
											@click="onAddPayRecord(formApproval.speed.geological.projectApprovalSpeedGeologicalCost,formApproval.speed.geological.projectApprovalSpeedGeologicalPayment,{amount: null,source: null,ticket: [],voucher: [], isDisabled:false})">
											新增付款记录</a-button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="ui-childBorderStyle">
						<p>18.6、聘请预算编制 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
						<div style="margin-left: 20px;">
							<a-row :gutter="24">
								<a-col :span="6">
									<a-form-item label="请上传中标通知书或成交确认书">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formApproval.speed.budgeting.projectApprovalSpeedBudgetingNotice"
												name="聘请预算编制中标通知书或成交确认书"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="请上传合同">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formApproval.speed.budgeting.projectApprovalSpeedBudgetingContract"
												name="聘请预算编制合同"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="合同金额">
										<a-input-number :min="0" placeholder="请输入合同金额"
											v-model:value="formApproval.speed.budgeting.projectApprovalSpeedBudgetingCost"
											class="ui-inputNumWidth"
											:formatter="inputNumberFormat"
											:disabled="isModify && current < projectStage"></a-input-number>元
									</a-form-item>
								</a-col>
								<a-col :span="6">
									<a-form-item label=""></a-form-item>
									<a-form-item label=""></a-form-item>
									<a-form-item label="已支付金额">
										{{ inputNumberFormat(commonAlreadyPay(formApproval.speed.budgeting.projectApprovalSpeedBudgetingPayment)) }}元
									</a-form-item>
								</a-col>
								<a-col :span="6">
									<a-form-item label=""></a-form-item>
									<a-form-item label=""></a-form-item>
									<a-form-item label="待支付金额">
										{{ inputNumberFormat(handleAwaitPrice((formApproval.speed.budgeting.projectApprovalSpeedBudgetingCost - commonAlreadyPay(formApproval.speed.budgeting.projectApprovalSpeedBudgetingPayment)))) }}元
									</a-form-item>
								</a-col>
							</a-row>
							<p>付款记录：</p>
							<div class="ui-contentBorderBox"
								v-for="(item,index) in formApproval.speed.budgeting.projectApprovalSpeedBudgetingPayment"
								:key="index">
								<div class="ui-contentWhiteBorder">
									<a-row :gutter="24">
										<a-col :span="10">
											<a-form-item label="付款金额">
												<a-input-number :min="0" placeholder="请输入付款金额"
													v-model:value="item.amount" class="ui-inputNumWidth" :formatter="inputNumberFormat"
													@blur="changePrice(formApproval.speed.budgeting.projectApprovalSpeedBudgetingCost,formApproval.speed.budgeting.projectApprovalSpeedBudgetingPayment,index)"
													:disabled="current < projectStage && item.isDisabled">
												</a-input-number>元
											</a-form-item>
											<a-form-item label="请上传发票">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.ticket"
														:index="index+1"
														name="聘请预算编制付款发票"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
										<a-col :span="10">
											<a-form-item label="资金来源">
												<a-select placeholder="请选择资金来源" v-model:value="item.source"
													class="ui-inputNumWidth"
													:disabled="current < projectStage && item.isDisabled">
													<a-select-option :value="0">上级专项资金</a-select-option>
													<a-select-option :value="1">县国资公司</a-select-option>
													<a-select-option :value="2">县本级财政资金</a-select-option>
													<a-select-option :value="3">其他</a-select-option>
												</a-select>
											</a-form-item>
											<a-form-item label="请上传支付凭证">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.voucher"
														:index="index+1"
														name="聘请预算编制支付凭证"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
									</a-row>
								</div>
								<div>
									<div class="ui-plusIconBox">
										<Icon width="60" class="ui-icon-plusStyle" icon="PlusOutlined" />
										<a-button type="text" size="large"
											@click="onAddPayRecord(formApproval.speed.budgeting.projectApprovalSpeedBudgetingCost,formApproval.speed.budgeting.projectApprovalSpeedBudgetingPayment,{amount: null,source: null,ticket: [],voucher: [],isDisabled:false})">
											新增付款记录</a-button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="ui-childBorderStyle">
						<p>18.7、聘请预算评审 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
						<div style="margin-left: 20px;">
							<a-row :gutter="24">
								<a-col :span="6">
									<a-form-item label="请上传中标通知书或成交确认书">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formApproval.speed.budget.projectApprovalSpeedBudgetNotice"
												name="聘请预算评审中标通知书或成交确认书"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="请上传合同">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formApproval.speed.budget.projectApprovalSpeedBudgetContract"
												name="聘请预算评审合同"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="合同金额">
										<a-input-number :min="0" placeholder="请输入合同金额"
											v-model:value="formApproval.speed.budget.projectApprovalSpeedBudgetCost"
											class="ui-inputNumWidth"
											:formatter="inputNumberFormat"
											:disabled="isModify && current < projectStage"></a-input-number>元
									</a-form-item>
								</a-col>
								<a-col :span="6">
									<a-form-item label=""></a-form-item>
									<a-form-item label=""></a-form-item>
									<a-form-item label="已支付金额">
										{{ inputNumberFormat(commonAlreadyPay(formApproval.speed.budget.projectApprovalSpeedBudgetPayment)) }}元
									</a-form-item>
								</a-col>
								<a-col :span="6">
									<a-form-item label=""></a-form-item>
									<a-form-item label=""></a-form-item>
									<a-form-item label="待支付金额">
										{{ inputNumberFormat(handleAwaitPrice((formApproval.speed.budget.projectApprovalSpeedBudgetCost - commonAlreadyPay(formApproval.speed.budget.projectApprovalSpeedBudgetPayment)))) }}元
									</a-form-item>
								</a-col>
							</a-row>
							<p>付款记录：</p>
							<div class="ui-contentBorderBox"
								v-for="(item,index) in formApproval.speed.budget.projectApprovalSpeedBudgetPayment"
								:key="index">
								<div class="ui-contentWhiteBorder">
									<a-row :gutter="24">
										<a-col :span="10">
											<a-form-item label="付款金额">
												<a-input-number :min="0" placeholder="请输入付款金额"
													v-model:value="item.amount" class="ui-inputNumWidth" :formatter="inputNumberFormat"
													@blur="changePrice(formApproval.speed.budget.projectApprovalSpeedBudgetCost,formApproval.speed.budget.projectApprovalSpeedBudgetPayment,index)"
													:disabled="current < projectStage && item.isDisabled">
												</a-input-number>元
											</a-form-item>
											<a-form-item label="请上传发票">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.ticket"
														:index="index+1"
														name="聘请预算评审付款发票"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
										<a-col :span="10">
											<a-form-item label="资金来源">
												<a-select placeholder="请选择资金来源" v-model:value="item.source"
													class="ui-inputNumWidth"
													:disabled="current < projectStage && item.isDisabled">
													<a-select-option :value="0">上级专项资金</a-select-option>
													<a-select-option :value="1">县国资公司</a-select-option>
													<a-select-option :value="2">县本级财政资金</a-select-option>
													<a-select-option :value="3">其他</a-select-option>
												</a-select>
											</a-form-item>
											<a-form-item label="请上传支付凭证">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.voucher"
														:index="index+1"
														name="聘请预算评审支付凭证"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
									</a-row>
								</div>
								<div>
									<div class="ui-plusIconBox">
										<Icon width="60" class="ui-icon-plusStyle" icon="PlusOutlined" />
										<a-button type="text" size="large"
											@click="onAddPayRecord(formApproval.speed.budget.projectApprovalSpeedBudgetCost,formApproval.speed.budget.projectApprovalSpeedBudgetPayment,{amount: null,source: null,ticket: [],voucher: [],isDisabled:false})">
											新增付款记录</a-button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="ui-childBorderStyle">
						<p>18.8、聘请招标代理 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
						<div style="margin-left: 20px;">
							<a-row :gutter="24">
								<a-col :span="6">
									<a-form-item label="请上传中标通知书或成交确认书">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formApproval.speed.agent.projectApprovalSpeedAgentNotice"
												name="聘请招标代理中标通知书或成交确认书"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="请上传合同">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="formApproval.speed.agent.projectApprovalSpeedAgentContract"
												name="聘请招标代理合同"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="合同金额">
										<a-input-number :min="0" placeholder="请输入合同金额"
											v-model:value="formApproval.speed.agent.projectApprovalSpeedAgentCost"
											class="ui-inputNumWidth"
											:formatter="inputNumberFormat"
											:disabled="isModify && current < projectStage"></a-input-number>元
									</a-form-item>
								</a-col>
								<a-col :span="6">
									<a-form-item label=""></a-form-item>
									<a-form-item label=""></a-form-item>
									<a-form-item label="已支付金额">
										{{ inputNumberFormat(commonAlreadyPay(formApproval.speed.agent.projectApprovalSpeedAgentPayment)) }}元
									</a-form-item>
								</a-col>
								<a-col :span="6">
									<a-form-item label=""></a-form-item>
									<a-form-item label=""></a-form-item>
									<a-form-item label="待支付金额">
										{{ inputNumberFormat(handleAwaitPrice((formApproval.speed.agent.projectApprovalSpeedAgentCost - commonAlreadyPay(formApproval.speed.agent.projectApprovalSpeedAgentPayment)))) }}
									</a-form-item>
								</a-col>
							</a-row>
							<p>付款记录：</p>
							<div class="ui-contentBorderBox"
								v-for="(item,index) in formApproval.speed.agent.projectApprovalSpeedAgentPayment"
								:key="index">
								<div class="ui-contentWhiteBorder">
									<a-row :gutter="24">
										<a-col :span="10">
											<a-form-item label="付款金额">
												<a-input-number :min="0" placeholder="请输入付款金额"
													v-model:value="item.amount" class="ui-inputNumWidth" :formatter="inputNumberFormat"
													@blur="changePrice(formApproval.speed.agent.projectApprovalSpeedAgentCost,formApproval.speed.agent.projectApprovalSpeedAgentPayment,index)"
													:disabled="current < projectStage && item.isDisabled">
												</a-input-number>元
											</a-form-item>
											<a-form-item label="请上传发票">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.ticket"
														:index="index+1"
														name="聘请招标代理付款发票"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
										<a-col :span="10">
											<a-form-item label="资金来源">
												<a-select placeholder="请选择资金来源" v-model:value="item.source"
													class="ui-inputNumWidth"
													:disabled="current < projectStage && item.isDisabled">
													<a-select-option :value="0">上级专项资金</a-select-option>
													<a-select-option :value="1">县国资公司</a-select-option>
													<a-select-option :value="2">县本级财政资金</a-select-option>
													<a-select-option :value="3">其他</a-select-option>
												</a-select>
											</a-form-item>
											<a-form-item label="请上传支付凭证">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.voucher"
														:index="index+1"
														name="聘请招标代理支付凭证"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
									</a-row>
								</div>
								<div>
									<div class="ui-plusIconBox">
										<Icon width="60" class="ui-icon-plusStyle" icon="PlusOutlined" />
										<a-button type="text" size="large"
											@click="onAddPayRecord(formApproval.speed.agent.projectApprovalSpeedAgentCost,formApproval.speed.agent.projectApprovalSpeedAgentPayment,{amount: null,source: null,ticket: [],voucher: [],isDisabled:false})">
											新增付款记录</a-button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="ui-childBorderStyle" style="margin-top: 10px;">
						<p>18.9、聘请其他中介服务 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
						<div class="ui-SerChildBorderStyle"
							v-for="(itemServ,indexServ) in formApproval.speed.other.projectApprovalSpeedOther">
							<div class="ui-SerChildStyleMini" style="width: 80%;">
								<a-row :gutter="24" style="margin-top: 15px;">
									<a-col :span="8">
										<a-form-item label="服务名称">
											<a-input v-model:value="itemServ.service" placeholder="请输入服务名称"
												:disabled="isModify && current < projectStage && itemServ.isDisabled"></a-input>
										</a-form-item>
										<a-form-item label="请上传中标通知书或成交确认书">
											<div style="display: flex;height: 30px;">
												<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
												<filesUpload :disabled="isModify && current < projectStage && itemServ.isDisabled"
													v-model:value="itemServ.notice"
													:index="indexServ + 1"
													name="聘请其他中介服务中标通知书或成交确认书"
												></filesUpload>
											</div>
										</a-form-item>
										<a-form-item label="请上传合同">
											<div style="display: flex;height: 30px;">
												<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
												<filesUpload :disabled="isModify && current < projectStage && itemServ.isDisabled"
													:index="indexServ + 1"
													v-model:value="itemServ.contract"
													name="聘请其他中介服务合同"
												></filesUpload>
											</div>
										</a-form-item>
										<a-form-item label="招标代理总金额">
											<a-input-number :min="0" v-model:value="itemServ.cost"
												class="ui-inputNumWidth" placeholder="请输入招标代理总金额"
												:formatter="inputNumberFormat"
												:disabled="isModify && current < projectStage && itemServ.isDisabled">
											</a-input-number>元
										</a-form-item>
									</a-col>
									<a-col :span="6">
										<a-form-item label=""></a-form-item>
										<a-form-item label=""></a-form-item>
										<a-form-item label=""></a-form-item>
										<a-form-item label="已支付金额">
											{{ inputNumberFormat(commonAlreadyPay( itemServ.payment)) }}元
										</a-form-item>
									</a-col>
									<a-col :span="6">
										<a-form-item label=""></a-form-item>
										<a-form-item label=""></a-form-item>
										<a-form-item label=""></a-form-item>
										<a-form-item label="待支付金额">
											{{ inputNumberFormat(handleAwaitPrice((itemServ.cost - commonAlreadyPay(itemServ.payment)))) }}元
										</a-form-item>
									</a-col>
								</a-row>
								<p>付款记录：</p>
								<div class="ui-contentBorderBox" v-for="(item,index) in itemServ.payment" :key="index">
									<div class="ui-contentWhiteBorder" style="width:75%">
										<a-row :gutter="24">
											<a-col :span="9">
												<a-form-item label="付款金额">
													<a-input-number :min="0" placeholder="请输入付款金额"
														v-model:value="item.amount" class="ui-inputNumWidth"
														:formatter="inputNumberFormat"
														@blur="changePrice(itemServ.cost,itemServ.payment,index)"
														:disabled="current < projectStage && item.isDisabled">
													</a-input-number>元
												</a-form-item>
												<a-form-item label="请上传发票">
													<div style="display: flex;">
														<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
														<filesUpload
															:disabled="current < projectStage && item.isDisabled"
															v-model:value="item.ticket"
															:index="indexServ+1"
															name="聘请其他中介服务付款发票"
														></filesUpload>
													</div>
												</a-form-item>
											</a-col>
											<a-col :span="9">
												<a-form-item label="资金来源">
													<a-select placeholder="请选择资金来源" v-model:value="item.source"
														class="ui-inputNumWidth"
														:disabled="current < projectStage && item.isDisabled">
														<a-select-option :value="0">上级专项资金</a-select-option>
														<a-select-option :value="1">县国资公司</a-select-option>
														<a-select-option :value="2">县本级财政资金</a-select-option>
														<a-select-option :value="3">其他</a-select-option>
													</a-select>
												</a-form-item>
												<a-form-item label="请上传支付凭证">
													<div style="display: flex;">
														<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
														<filesUpload
															:disabled="current < projectStage && item.isDisabled"
															v-model:value="item.voucher"
															:index="indexServ+1"
															name="聘请其他中介服务支付凭证"
														></filesUpload>
													</div>
												</a-form-item>
											</a-col>
										</a-row>
									</div>
									<div>
										<div class="ui-plusIconBox">
											<Icon width="60" class="ui-icon-plusStyle" icon="PlusOutlined" />
											<a-button type="text" size="large"
												@click="onAddPayRecord(itemServ.cost,itemServ.payment,{amount: null,source:null,ticket: [],voucher: [],isDisabled:false})">
												新增付款记录</a-button>
										</div>
									</div>
								</div>
							</div>
							<div>
								<div class="ui-plusIconBox">
									<Icon width="60" class="ui-icon-plusStyle" icon="PlusOutlined" />
									<a-button type="text" size="large"
										@click="onAddPayRecord({},formApproval.speed.other.projectApprovalSpeedOther,{service: '',notice: [],contract: [],cost: null,isDisabled:false,payment: [{amount: null,source:null,ticket: [],voucher: []}]},true)">
										新增中介服务</a-button>
								</div>
							</div>
						</div>
						<!-- <div>
							<div class="ui-plusIconBox"><Icon width="60" class="ui-icon-plusStyle" icon="PlusOutlined" /><a-button type="text" size="large" @click="onAddPayRecord(formApproval.speed.other.projectApprovalSpeedOther,{service: '',notice: [],contract: [],cost: null,payment: [{amount: null,source:null,ticket: [],voucher: []}]})" >新增中介服务</a-button></div>
						</div> -->
					</div>
				</div>
			</a-form>
		</div>
		<!-- 项目招标 -->
		<div class="steps-content" v-if="steps[current].key == 'inviteBids'">
			<a-form :model="inviteTenders" ref="inviteTendersRef" scrollToFirstError>
				<!-- 1、县政府常务会议纪要 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>1、县政府常务会议纪要 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-childBorderStyle">
						<a-radio-group v-model:value="inviteTenders.summary.inviteTendersSummaryType"
							:disabled="isModify && current < projectStage">
							<a-radio :value="1">EPC项目</a-radio>
							<a-radio :value="0">非EPC项目</a-radio>
						</a-radio-group>
						<div class="ui-cutApart"></div>
						<a-form-item label="请上传会议纪要" :name="['summary','inviteTendersSummaryFile']"
							:rules="[{required:true, message: '请上传会议纪要'}]" v-if="inviteTenders.summary.inviteTendersSummaryType != 0">
							<div style="display: flex;">
								<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
								<filesUpload :disabled="isModify && current < projectStage"
									v-model:value="inviteTenders.summary.inviteTendersSummaryFile"
									name="县政府常务会议纪要"
								>
								</filesUpload>
							</div>
						</a-form-item>
					</div>
				</div>
				<!-- 2、班子会会议纪要 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>2、班子会会议纪要 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-childBorderStyle">
						<a-form-item label="请上传会议纪要" :name="['meeting','inviteTendersMeetingSummary']"
							:rules="[{required:true, message: '请上传会议纪要'}]">
							<div style="display: flex;">
								<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
								<filesUpload :disabled="isModify && current < projectStage"
									v-model:value="inviteTenders.meeting.inviteTendersMeetingSummary"
									name="班子会会议纪要"
								>
								</filesUpload>
							</div>
						</a-form-item>
					</div>
				</div>
				<!-- 3、中标信息 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>3、中标信息 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-childBorderStyle">
						<a-row :gutter="24">
							<a-col :span="8">
								<a-form-item label="中标公司名称" :name="['success','inviteTendersSuccessCompany']"
									:rules="[{required:true}]">
									<a-input placeholder="请输入中标公司名称"
										v-model:value="inviteTenders.success.inviteTendersSuccessCompany"
										:disabled="isModify && current < projectStage"></a-input>
								</a-form-item>
								<a-form-item label="请上传中标通知书、交易确认书" :name="['success','inviteTendersSuccessNoticeFile']"
									:rules="[{required:true, message: '请上传中标通知书、交易确认书'}]">
									<div style="display: flex;">
										<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
										<filesUpload :disabled="isModify && current < projectStage"
											v-model:value="inviteTenders.success.inviteTendersSuccessNoticeFile"
											name="中标通知书或交易确认书"
										>
										</filesUpload>
									</div>
								</a-form-item>
							</a-col>
							<a-col :span="8">
								<a-form-item label="工程中标价（合同价）" :name="['success','inviteTendersSuccessPrice']"
									:rules="[{required:true}]">
									<a-input-number :min="0" placeholder="0"
										v-model:value="inviteTenders.success.inviteTendersSuccessPrice"
										class="ui-inputNumWidth"
										:formatter="inputNumberFormat"
										:disabled="isModify && current < projectStage">
									</a-input-number>元
								</a-form-item>
								<a-form-item label="请上传施工合同" :name="['success','inviteTendersSuccessContract']"
									:rules="[{required:true, message: '请上传施工合同'}]">
									<div style="display: flex;">
										<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
										<filesUpload :disabled="isModify && current < projectStage"
											v-model:value="inviteTenders.success.inviteTendersSuccessContract"
											name="中标信息施工合同"
										>
										</filesUpload>
									</div>
								</a-form-item>
							</a-col>
							<a-col :span="8">
								<a-form-item label="招标方式"
									:name="[ 'success','inviteTendersSuccessWay']"
									:rules="[{required:true}]">
									<a-select allowClear placeholder="请选择招标方式" v-model:value="inviteTenders.success.inviteTendersSuccessWay"
										class="ui-inputNumWidth"
										:disabled="isModify && current < projectStage">
										<a-select-option value="0">公开招标</a-select-option>
										<a-select-option value="1">邀请招标</a-select-option>
										<a-select-option value="2">直接打包</a-select-option>
									</a-select>
								</a-form-item>
							</a-col>
						</a-row>
					</div>
				</div>
				<!-- 4、监理信息 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>4、监理信息 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-contentBorder">
						<a-radio-group v-model:value="inviteTenders.watcher.inviteTendersHasWatcher"
							@change="(e) => { changeIsSupervise(e); onTypeChange(e, 'inviteTendersHasWatcher') }" :disabled="isModify && current < projectStage">
							<a-radio :value="0">有监理</a-radio>
							<a-radio :value="1">有监理(包含在全过程咨询服务中)</a-radio>
							<a-radio :value="2">无监理</a-radio>
							<a-radio :value="3">不需要</a-radio>
						</a-radio-group>
						<div class="ui-cutApart"></div>
						<div
							v-if="inviteTenders.watcher.inviteTendersHasWatcher == null || inviteTenders.watcher.inviteTendersHasWatcher == 0">
							<a-row :gutter="24">
								<a-col :span="8">
									<a-form-item label="监理公司名称" :name="['watcher','inviteTendersWatcherCompany']"
										:rules="[{required:true}]">
										<a-input v-model:value="inviteTenders.watcher.inviteTendersWatcherCompany"
											placeholder="请输入监理公司名称"
											:disabled="isModify && current < projectStage">
										</a-input>
									</a-form-item>
									<a-form-item label="请上传会议纪要" :name="['watcher','inviteTendersWatcherSummary']"
										:rules="[{required:true, message: '请上传会议纪要'}]">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="inviteTenders.watcher.inviteTendersWatcherSummary"
												name="监理会议纪要"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="合同金额" :name="['watcher','inviteTendersWatcherPrice']"
										:rules="[{required:true}]">
										<a-input-number :min="0" placeholder="请输入合同金额"
											v-model:value="inviteTenders.watcher.inviteTendersWatcherPrice"
											:formatter="inputNumberFormat"
											:disabled="isModify && current < projectStage"
											class="ui-inputNumWidth"></a-input-number>元
									</a-form-item>
								</a-col>
								<a-col :span="8">
									<a-form-item label="请上传中标通知书或成交确认书"
										:name="['watcher','inviteTendersWatcherNoticeFile']" :rules="[{required:true, message: '请上传中标通知书或成交确认书'}]">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="inviteTenders.watcher.inviteTendersWatcherNoticeFile"
												name="监理中标通知书或成交确认书"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="请上传合同" :name="['watcher','inviteTendersWatcherContract']"
										:rules="[{required:true, message: '请上传合同'}]">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="inviteTenders.watcher.inviteTendersWatcherContract"
												name="监理合同"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="已支付金额">
										{{ inputNumberFormat(commonAlreadyPay(inviteTenders.watcher.inviteTendersWatcherPayment)) }}元
										<!-- {{ countPaid('supervisePaidPrice',inviteTenders.watcher.inviteTendersWatcherPayment,'inviteTenders') }} -->
									</a-form-item>
								</a-col>
								<a-col>
									<a-form-item label=""></a-form-item>
									<a-form-item label=""></a-form-item>
									<a-form-item label="待支付金额">
										{{ inputNumberFormat(handleAwaitPrice(((inviteTenders?.watcher?.inviteTendersWatcherPrice) - commonAlreadyPay(inviteTenders.watcher.inviteTendersWatcherPayment)))) }}元
										<!-- {{ countAwaitPrice('supervisePaidPrice','superviseAwaitPaidPrice','supervisePrice','inviteTenders') }} -->
									</a-form-item>
								</a-col>
							</a-row>
							<p>付款记录：</p>
							<div class="ui-contentBorderBox"
								v-for="(item,index) in inviteTenders.watcher.inviteTendersWatcherPayment" :key="index">
								<div class="ui-contentWhiteBorder">
									<a-row :gutter="24">
										<a-col :span="10">
											<a-form-item label="付款金额"
												:name="[ 'watcher','inviteTendersWatcherPayment',index,'amount']"
												:rules="[{required:true}]">
												<a-input-number :min="0" placeholder="请输入付款金额"
													v-model:value="item.amount" class="ui-inputNumWidth" :formatter="inputNumberFormat"
													@blur="changePrice(inviteTenders.watcher.inviteTendersWatcherPrice,inviteTenders.watcher.inviteTendersWatcherPayment,index)"
													:disabled="current < projectStage && item.isDisabled">
												</a-input-number>元
											</a-form-item>
											<a-form-item label="请上传请款审批表"
												:name="[ 'watcher','inviteTendersWatcherPayment',index,'applyFile']"
												:rules="[{required:true, message: '请上传请款审批表'}]">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.applyFile"
														:index="index +1"
														name="监理请款审批表"
													></filesUpload>
												</div>
											</a-form-item>
											<a-form-item label="请上传支付凭证"
												:name="[ 'watcher','inviteTendersWatcherPayment',index,'voucher']"
												:rules="[{required:true, message: '请上传支付凭证'}]">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.voucher"
														:index="index +1"
														name="监理支付凭证"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
										<a-col :span="10">
											
											<a-form-item label="资金来源"
												:name="[ 'watcher','inviteTendersWatcherPayment',index,'source']"
												:rules="[{required:true}]">
												<a-select placeholder="请选择资金来源" v-model:value="item.source"
													class="ui-inputNumWidth"
													:disabled="current < projectStage && item.isDisabled">
													<a-select-option :value="0">上级专项资金</a-select-option>
													<a-select-option :value="1">县国资公司</a-select-option>
													<a-select-option :value="2">县本级财政资金</a-select-option>
													<a-select-option :value="3">其他</a-select-option>
												</a-select>
											</a-form-item>
											<a-form-item label="请上传发票"
												:name="[ 'watcher','inviteTendersWatcherPayment',index,'ticket']"
												:rules="[{required:true, message: '请上传发票'}]">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.ticket"
														:index="index +1"
														name="监理付款发票"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
										<!-- <a-col :span="12">
									        <a-button @click="onAddPayRecord(inviteTenders.watcher.inviteTendersWatcherPayment,index)" type="primary">新增付款记录</a-button>
									        <a-button @click="onDeletePayRecord(inviteTenders.watcher.inviteTendersWatcherPayment,index)" type="primary" ghost v-if="inviteTenders.watcher.inviteTendersWatcherPayment.length >= 1">删除此记录</a-button>
									    </a-col> -->
									</a-row>
								</div>
								<div>
									<div class="ui-plusIconBox">
										<Icon width="60" class="ui-icon-plusStyle" icon="PlusOutlined" />
										<a-button type="text" size="large"
											@click="onAddPayRecord(inviteTenders.watcher.inviteTendersWatcherPrice,inviteTenders.watcher.inviteTendersWatcherPayment,{amount: null, source: 0, applyFile: [],ticket: [],voucher: [],isDisabled:false})">
											新增付款记录</a-button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 5、跟踪审计信息 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>5、跟踪审计信息 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-contentBorder">
						<a-radio-group v-model:value="inviteTenders.flowable.inviteTendersHasFlowable"
							@change="(e) => { changeIsAduit(e); onTypeChange(e, 'inviteTendersHasFlowable') }" :disabled="isModify && current < projectStage">
							<a-radio :value="0">有跟踪审计</a-radio>
							<a-radio :value="1">无跟踪审计</a-radio>
							<a-radio :value="2">不需要</a-radio>
						</a-radio-group>
						<div class="ui-cutApart"></div>
						<div
							v-if="inviteTenders.flowable.inviteTendersHasFlowable == null || inviteTenders.flowable.inviteTendersHasFlowable == 0">
							<a-row :gutter="24">
								<a-col :span="6">
									<a-form-item label="跟踪审计公司名称" :name="['flowable','inviteTendersFlowableCompany']"
										:rules="[{required:true}]">
										<a-input v-model:value="inviteTenders.flowable.inviteTendersFlowableCompany"
											placeholder="请输入跟踪审计公司名称"
											:disabled="isModify && current < projectStage">
										</a-input>
									</a-form-item>
									<a-form-item label="请上传会议纪要" :name="['flowable','inviteTendersFlowableSummary']"
										:rules="[{required:true, message: '请上传会议纪要'}]">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="inviteTenders.flowable.inviteTendersFlowableSummary"
												name="跟踪审计会议纪要"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="合同金额" :name="['flowable','inviteTendersFlowablePrice']"
										:rules="[{required:true}]">
										<a-input-number :min="0" placeholder="请输入合同金额"
											v-model:value="inviteTenders.flowable.inviteTendersFlowablePrice"
											class="ui-inputNumWidth"
											:formatter="inputNumberFormat"
											:disabled="isModify && current < projectStage"></a-input-number>元
									</a-form-item>
								</a-col>
								<a-col :span="6">
									<a-form-item label="请上传中标通知书或成交确认书"
										:name="['flowable','inviteTendersFlowableNoticeFile']"
										:rules="[{required:true, message: '请上传中标通知书或成交确认书'}]">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="inviteTenders.flowable.inviteTendersFlowableNoticeFile"
												name="跟踪审计中标通知书或成交确认书"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="请上传合同" :name="['flowable','inviteTendersFlowableContract']"
										:rules="[{required:true, message: '请上传合同'}]">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="inviteTenders.flowable.inviteTendersFlowableContract"
												name="跟踪审计合同"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="已支付金额">
										{{ inputNumberFormat(commonAlreadyPay(inviteTenders.flowable.inviteTendersFlowablePayment)) }}元
										<!-- {{ countPaid('auditPaidPrice',inviteTenders.flowable.inviteTendersFlowablePayment,'inviteTenders') }} -->
									</a-form-item>
								</a-col>
								<a-col :span="6">
									<a-form-item label=""></a-form-item>
									<a-form-item label=""></a-form-item>
									<a-form-item label="待支付金额">
										{{ inputNumberFormat((inviteTenders?.flowable?.inviteTendersFlowablePrice || 0) - commonAlreadyPay(inviteTenders.flowable.inviteTendersFlowablePayment)) }}元
										<!-- {{ countAwaitPrice('auditPaidPrice','auditAwaitPaidPrice','auditPrice','inviteTenders') }} -->
									</a-form-item>
								</a-col>
							</a-row>
							<p>付款记录：</p>
							<div class="ui-contentBorderBox"
								v-for="(item,index) in inviteTenders.flowable.inviteTendersFlowablePayment"
								:key="index">
								<div class="ui-contentWhiteBorder">
									<a-row :gutter="24">
										<a-col :span="10">
											<a-form-item label="付款金额"
												:name="[ 'flowable','inviteTendersFlowablePayment',index,'amount']"
												:rules="[{required:true}]">
												<a-input-number :min="0" placeholder="请输入付款金额"
													v-model:value="item.amount" class="ui-inputNumWidth"
													:formatter="inputNumberFormat"
													@blur="changePrice(inviteTenders.flowable.inviteTendersFlowablePrice,inviteTenders.flowable.inviteTendersFlowablePayment,index)"
													:disabled="current < projectStage && item.isDisabled">
												</a-input-number>元
											</a-form-item>
											<a-form-item label="请上传付款审批表"
												:name="[ 'flowable','inviteTendersFlowablePayment',index,'applyFile']"
												:rules="[{required:true, message: '请上传付款审批表'}]">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.applyFile"
														:index="index+1"
														name="跟踪审计付款审批表"
													></filesUpload>
												</div>
											</a-form-item>
											<a-form-item label="请上传支付凭证"
												:name="[ 'flowable','inviteTendersFlowablePayment',index,'voucher']"
												:rules="[{required:true, message: '请上传支付凭证'}]">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.voucher"
														:index="index +1"
														name="跟踪审计支付凭证"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
										<a-col :span="10">
											<!-- <a-form-item label="资金来源" :name="[ 'flowable','inviteTendersFlowablePayment',index,'source']" required>
												<a-select placeholder="请选择资金来源" v-model:value="item.source" class="ui-inputNumWidth" :disabled="isModify && current < projectStage">
													<a-select-option :value="0">上级专项资金</a-select-option>
													<a-select-option :value="1">县国资公司</a-select-option>
													<a-select-option :value="2">县本级财政资金</a-select-option>
													<a-select-option :value="3">其他</a-select-option>
												</a-select>
											</a-form-item> -->
											<a-form-item label="资金来源"
												:name="[ 'flowable','inviteTendersFlowablePayment',index,'source']"
												:rules="[{required:true}]">
												<a-select placeholder="请选择资金来源" v-model:value="item.source"
													class="ui-inputNumWidth"
													:disabled="current < projectStage && item.isDisabled">
													<a-select-option :value="0">上级专项资金</a-select-option>
													<a-select-option :value="1">县国资公司</a-select-option>
													<a-select-option :value="2">县本级财政资金</a-select-option>
													<a-select-option :value="3">其他</a-select-option>
												</a-select>
											</a-form-item>
											<a-form-item label="请上传发票"
												:name="[ 'flowable','inviteTendersFlowablePayment',index,'ticket']"
												:rules="[{required:true, message: '请上传发票'}]">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.ticket"
														:index="index + 1"
														name="跟踪审计付款发票"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
										<!-- <a-col :span="12">
								           <a-button @click="onAddPayRecord(inviteTenders.flowable.inviteTendersFlowablePayment,index)" type="primary">新增付款记录</a-button>
								           <a-button @click="onDeletePayRecord(inviteTenders.flowable.inviteTendersFlowablePayment,index)" type="primary" ghost v-if="inviteTenders.flowable.inviteTendersFlowablePayment.length >= 1">删除此记录</a-button>
								       </a-col> -->
									</a-row>
								</div>
								<div>
									<div class="ui-plusIconBox">
										<Icon width="60" class="ui-icon-plusStyle" icon="PlusOutlined" />
										<a-button type="text" size="large"
											@click="onAddPayRecord(inviteTenders.flowable.inviteTendersFlowablePrice,inviteTenders.flowable.inviteTendersFlowablePayment,{amount: null, source: null, applyFile: [],ticket: [],voucher: [],isDisabled:false})">
											新增付款记录</a-button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 6、全过程咨询信息 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>6、全过程咨询信息 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-contentBorder">
						<a-radio-group v-model:value="inviteTenders.saf.inviteTendersHasSaf"
							:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'inviteTendersHasSaf')">
							<a-radio :value="0" :disabled="isConsult">有全过程咨询</a-radio>
							<a-radio :value="1" :disabled="isUnConsult">有全过程咨询(含监理服务)</a-radio>
							<a-radio :value="2" :disabled="isConsult">无全过程咨询</a-radio>
							<a-radio :value="3" :disabled="isConsult">不需要</a-radio>
						</a-radio-group>
						<div class="ui-cutApart"></div>
						<div
							v-if="inviteTenders.saf.inviteTendersHasSaf != 3 && inviteTenders.saf.inviteTendersHasSaf != 2">
							<a-row :gutter="24">
								<a-col :span="6">
									<a-form-item label="全过程咨询公司名称" :name="['saf','inviteTendersSafCompany']"
										:rules="[{required:true}]">
										<a-input v-model:value="inviteTenders.saf.inviteTendersSafCompany"
											placeholder="请输入全过程咨询公司名称"
											:disabled="isModify && current < projectStage">
										</a-input>
									</a-form-item>
									<a-form-item label="请上传会议纪要" :name="['saf','inviteTendersSafSummary']"
										:rules="[{required:true, message: '请上传会议纪要'}]">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="inviteTenders.saf.inviteTendersSafSummary"
												name="全过程咨询会议纪要"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="合同金额" :name="['saf','inviteTendersSafPrice']"
										:rules="[{required:true}]">
										<a-input-number :min="0" placeholder="请输入合同金额"
											v-model:value="inviteTenders.saf.inviteTendersSafPrice"
											class="ui-inputNumWidth"
											:formatter="inputNumberFormat"
											:disabled="isModify && current < projectStage"></a-input-number>元
									</a-form-item>
								</a-col>
								<a-col :span="6">
									<a-form-item label="请上传中标通知书或成交确认书" :name="['saf','inviteTendersSafNoticeFile']"
										:rules="[{required:true, message: '请上传中标通知书或成交确认书'}]">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="inviteTenders.saf.inviteTendersSafNoticeFile"
												name="全过程咨询中标通知书或成交确认书"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="请上传合同" :name="['saf','inviteTendersSafContract']"
										:rules="[{required:true, message: '请上传合同'}]">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="inviteTenders.saf.inviteTendersSafContract"
												name="全过程咨询合同"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="已支付金额">
										{{ inputNumberFormat(commonAlreadyPay(inviteTenders.saf.inviteTendersSafPayment)) }}元
										<!-- {{ countPaid('consultPaidPrice',inviteTenders.saf.inviteTendersSafPayment,'inviteTenders') }} -->
									</a-form-item>
								</a-col>
								<a-col :span="6">
									<a-form-item label=""></a-form-item>
									<a-form-item label=""></a-form-item>
									<a-form-item label="待支付金额">
										{{ inputNumberFormat((inviteTenders?.saf?.inviteTendersSafPrice || 0) - commonAlreadyPay(inviteTenders.saf.inviteTendersSafPayment)) }}元
										<!-- {{ countAwaitPrice('consultPaidPrice','consultAwaitPaidPrice','consultPrice','inviteTenders') }} -->
									</a-form-item>
								</a-col>
							</a-row>
							<p>付款记录：</p>
							<div class="ui-contentBorderBox"
								v-for="(item,index) in inviteTenders.saf.inviteTendersSafPayment" :key="index">
								<div class="ui-contentWhiteBorder">
									<a-row :gutter="24">
										<a-col :span="10">
											<a-form-item label="付款金额"
												:name="[ 'saf','inviteTendersSafPayment',index,'amount']"
												:rules="[{required:true}]">
												<a-input-number :min="0" placeholder="请输入付款金额"
													v-model:value="item.amount" class="ui-inputNumWidth" :formatter="inputNumberFormat"
													@blur="changePrice(inviteTenders.saf.inviteTendersSafPrice,inviteTenders.saf.inviteTendersSafPayment,index)"
													:disabled="current < projectStage && item.isDisabled">
												</a-input-number>元
											</a-form-item>
											<a-form-item label="请上传请款审批表"
												:name="[ 'saf','inviteTendersSafPayment',index,'applyFile']"
												:rules="[{required:true, message: '请上传请款审批表'}]">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.applyFile"
														:index="index+1"
														name="全过程咨询请款审批表"
													></filesUpload>
												</div>
											</a-form-item>
											<a-form-item label="请上传支付凭证"
												:name="[ 'saf','inviteTendersSafPayment',index,'voucher']"
												:rules="[{required:true, message: '请上传支付凭证'}]">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.voucher"
														:index="index+1"
														name="全过程咨询支付凭证"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
										<a-col :span="10">
											<a-form-item label="资金来源"
												:name="[ 'saf','inviteTendersSafPayment',index,'source']"
												:rules="[{required:true}]">
												<a-select placeholder="请选择资金来源" v-model:value="item.source"
													class="ui-inputNumWidth"
													:disabled="current < projectStage && item.isDisabled">
													<a-select-option :value="0">上级专项资金</a-select-option>
													<a-select-option :value="1">县国资公司</a-select-option>
													<a-select-option :value="2">县本级财政资金</a-select-option>
													<a-select-option :value="3">其他</a-select-option>
												</a-select>
											</a-form-item>
											<a-form-item label="请上传发票"
												:name="[ 'saf','inviteTendersSafPayment',index,'ticket']"
												:rules="[{required:true, message: '请上传发票'}]">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.ticket"
														:index="index+1"
														name="全过程咨询付款发票"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
										<!-- <a-col :span="12">
									        <a-button @click="onAddPayRecord(inviteTenders.saf.inviteTendersSafPayment,index)" type="primary">新增付款记录</a-button>
									        <a-button @click="onDeletePayRecord(inviteTenders.saf.inviteTendersSafPayment,index)" type="primary" ghost v-if="inviteTenders.saf.inviteTendersSafPayment.length >= 1">删除此记录</a-button>
									    </a-col> -->
									</a-row>
								</div>
								<div>
									<div class="ui-plusIconBox">
										<Icon width="60" class="ui-icon-plusStyle" icon="PlusOutlined" />
										<a-button type="text" size="large"
											@click="onAddPayRecord(inviteTenders.saf.inviteTendersSafPrice,inviteTenders.saf.inviteTendersSafPayment,{amount: null, source: 0, applyFile: [],ticket: [],voucher: [],isDisabled:false})">
											新增付款记录</a-button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</a-form>
		</div>
		<!-- 项目施工 -->
		<div class="steps-content" v-if="steps[current].key == 'construction'">
			<a-form :model="construction" ref="constructionRef" scrollToFirstError>
				<!-- 1、施工许可证或开工报告 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>1、施工许可证或开工报告 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-childBorderStyle">
						<a-radio-group v-model:value="construction.permit.constructionHasPermit"
							:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'constructionHasPermit')">
							<a-radio :value="0">有施工许可证或开工报告</a-radio>
							<a-radio :value="1">无施工许可证或开工报告</a-radio>
						</a-radio-group>
						<div class="ui-cutApart"></div>
						<div v-if="construction.permit.constructionHasPermit != 1">
							<a-form-item label="请上传施工许可证" :name="['permit','constructionPermitBuildFile']"
								:rules="[{required:true, message: '请上传施工许可证'}]">
								<div style="display: flex;">
									<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
									<filesUpload :disabled="isModify && current < projectStage"
										v-model:value="construction.permit.constructionPermitBuildFile"
										name="施工许可证"
										:limit="1"
									>
									</filesUpload>
									<a-form-item>
										<a-date-picker v-model:value="construction.permit.constructionPermitBuildTime" :value-format="'X'"
										 :disabled="!(construction.permit.constructionPermitBuildFile) || construction.permit.constructionPermitBuildFile.length == 0" />
									</a-form-item>
								</div>
							</a-form-item>
							<a-form-item label="请上传开工报告" :name="['permit','constructionPermitStartReport']"
								:rules="[{required:true, message: '请上传开工报告'}]">
								<div style="display: flex;">
									<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
									<filesUpload :disabled="isModify && current < projectStage"
										v-model:value="construction.permit.constructionPermitStartReport"
										name="开工报告" :limit="1"
									>
									</filesUpload>
									<a-form-item>
										<a-date-picker v-model:value="construction.permit.constructionPermitStartReportTime" :value-format="'X'"
										 :disabled="!(construction.permit.constructionPermitStartReport) || construction.permit.constructionPermitStartReport.length == 0" />
									</a-form-item>
								</div>
							</a-form-item>
						</div>
					</div>
				</div>
				<!-- 2、项目变更 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>2、项目变更 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-childBorderStyle">
						<a-radio-group v-model:value="construction.change.constructionHasChange"
							:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'constructionHasChange')">
							<a-radio :value="0">有变更</a-radio>
							<a-radio :value="1">无变更</a-radio>
						</a-radio-group>
						<div class="ui-cutApart"></div>
						<div v-if="construction.change.constructionHasChange != 1">
							<a-row :gutter="24">
								<a-col :span="6">
									<a-form-item label="增量金额" :rules="[{required:true,message:'请输入增量金额'}]">
										<a-input-number v-model:value="construction.change.constructionChangeAddAmount"
											placeholder="请输入增量金额"
											class="ui-inputNumWidth"
											:formatter="inputNumberFormat"
											:disabled="isModify && current < projectStage"></a-input-number>元
									</a-form-item>
									<a-form-item label="请上传增量申请" :name="['change','constructionChangeRequest']"
										:rules="[{required:true, message: '请上传增量申请'}]">
										<div style="display: flex;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="construction.change.constructionChangeRequest"
												name="项目增量申请"
											>
											</filesUpload>
										</div>
									</a-form-item>
								</a-col>
								<a-col :span="8">
									<a-form-item label="增量比例">{{(construction.change.constructionChangeAddAmount/Number(inviteTenders.success.inviteTendersSuccessPrice))!='Infinity' ? handleAwaitPrice(((construction.change.constructionChangeAddAmount/Number(inviteTenders.success.inviteTendersSuccessPrice))*100).toFixed(2))+'%':handleAwaitPrice(((construction.change.constructionChangeAddAmount/Number(inviteTenders.success.inviteTendersSuccessPrice))*100).toFixed(2))}}</a-form-item>
									<a-form-item label="请上传增量批复" :name="['change','constructionChangeResponse']"
										:rules="[{required:true, message: '请上传增量批复'}]">
										<div style="display: flex;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="construction.change.constructionChangeResponse"
												name="项目增量批复"
											>
											</filesUpload>
											<a-form-item>
											<a-date-picker v-model:value="construction.change.constructionChangeResponseTime" :value-format="'X'"
												:disabled="!(construction.change.constructionChangeResponse) || construction.change.constructionChangeResponse.length == 0" />
											</a-form-item>
										</div>
									</a-form-item>
								</a-col>
							</a-row>
						</div>
					</div>
				</div>
				<!-- 3、工程项目设备询价 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>3、工程项目设备询价 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-childBorderStyle">
						<a-radio-group v-model:value="construction.inquiry.constructionHasInquiry"
							:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'constructionHasInquiry')">
							<a-radio :value="0">有设备询价</a-radio>
							<a-radio :value="1">有设备询价(无询价公司)</a-radio>
							<a-radio :value="2">无设备询价</a-radio>
						</a-radio-group>
						<div class="ui-cutApart"></div>
						<div
							v-if="construction.inquiry.constructionHasInquiry == 0 || construction.inquiry.constructionHasInquiry == null">
							<a-row :gutter="24">
								<a-col :span="8">
									<a-form-item label="设备询价公司名称" :name="['inquiry','constructionInquiryCompany']"
										:rules="[{required:true}]">
										<a-input v-model:value="construction.inquiry.constructionInquiryCompany"
											placeholder="请输入设备询价公司名称"
											:disabled="isModify && current < projectStage"></a-input>
									</a-form-item>
									<a-form-item label="请上传会议纪要" :name="['inquiry','constructionInquirySummary']"
										:rules="[{required:true, message: '请上传会议纪要'}]">
										<div style="display: flex;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="construction.inquiry.constructionInquirySummary"
												name="工程项目设备询价会议纪要"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="请上传同意询价批复" :name="['inquiry','constructionInquiry']"
										:rules="[{required:true, message: '请上传同意询价批复'}]">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="construction.inquiry.constructionInquiry"
												name="工程项目设备询价同意询价批复" :limit="1"
											>
											</filesUpload>
											<a-form-item>
											<a-date-picker v-model:value="construction.inquiry.constructionInquiryTime" :value-format="'X'"
												:disabled="!(construction.inquiry.constructionInquiry) || construction.inquiry.constructionInquiry.length == 0" />
											</a-form-item>
										</div>
									</a-form-item>
									<a-form-item label="合同金额" :name="['inquiry','constructionInquiryCost']"
										:rules="[{required:true}]">
										<a-input-number :min="0" placeholder="请输入合同金额" class="ui-inputNumWidth"
											v-model:value="construction.inquiry.constructionInquiryCost"
											:formatter="inputNumberFormat"
											:disabled="isModify && current < projectStage"></a-input-number>元
									</a-form-item>
								</a-col>
								<a-col :span="6">
									<a-form-item label="请上传中标通知书或成交确认书"
										:name="['inquiry','constructionInquiryNoticeFile']" :rules="[{required:true, message: '请上传中标通知书或成交确认书'}]">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="construction.inquiry.constructionInquiryNoticeFile"
												name="工程项目设备询价中标通知书或成交确认书"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="请上传合同" :name="['inquiry','constructionInquiryContract']"
										:rules="[{required:true, message: '请上传合同'}]">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="construction.inquiry.constructionInquiryContract"
												name="工程项目设备询价合同"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="请上传询价结果" :name="['inquiry','constructionInquiryResult']"
										:rules="[{required:true, message: '请上传询价结果'}]">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="construction.inquiry.constructionInquiryResult"
												name="工程项目设备询价结果"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="已支付金额">
										<!-- {{alreadyPay(construction.inquiry.constructionInquiryPayment)}} -->
										{{ inputNumberFormat(countPrice(construction.inquiry.constructionInquiryPayment,construction.inquiry.constructionInquiryCost)) }}元
									</a-form-item>
								</a-col>
								<a-col :span="6">
									<a-form-item label=""></a-form-item>
									<a-form-item label=""></a-form-item>
									<a-form-item label=""></a-form-item>
									<a-form-item label="待支付金额">
										<!-- {{construction.inquiry.constructionInquiryCost - alreadyPay(construction.inquiry.constructionInquiryPayment) | handleAwaitPrice}} -->
										{{ inputNumberFormat(handleAwaitPrice(construction.inquiry.constructionInquiryCost - countPrice(construction.inquiry.constructionInquiryPayment,construction.inquiry.constructionInquiryCost))) }}元
									</a-form-item>
								</a-col>
							</a-row>
							<p>付款记录：</p>
							<div class="ui-contentBorderBox"
								v-for="(item,index) in construction.inquiry.constructionInquiryPayment" :key="index">
								<div class="ui-contentWhiteBorder">
									<a-row :gutter="24">
										<a-col :span="10">
											<a-form-item label="付款金额"
												:name="[ 'inquiry','constructionInquiryPayment',index,'amount']"
												:rules="[{required:true}]">
												<a-input-number :min="0" placeholder="请输入付款金额"
													v-model:value="item.amount" class="ui-inputNumWidth"
													:formatter="inputNumberFormat"
													@blur="changePrice(construction.inquiry.constructionInquiryCost,construction.inquiry.constructionInquiryPayment,index)"
													:disabled="current < projectStage && item.isDisabled">
												</a-input-number>元
											</a-form-item>
											<a-form-item label="请上传请款审批表"
												:name="[ 'inquiry','constructionInquiryPayment',index,'applyFile']"
												:rules="[{required:true, message: '请上传请款审批表'}]">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.applyFile"
														:index="index+1"
														name="工程项目设备询价请款审批表"
													></filesUpload>
												</div>
											</a-form-item>
											<a-form-item label="请上传支付凭证"
												:name="[ 'inquiry','constructionInquiryPayment',index,'voucher']"
												:rules="[{required:true, message: '请上传支付凭证'}]">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.voucher"
														:index="index+1"
														name="工程项目设备询价支付凭证"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
										<a-col :span="10">
											<a-form-item label="资金来源"
												:name="[ 'inquiry','constructionInquiryPayment',index,'source']"
												:rules="[{required:true, message: '请选择资金来源'}]">
												<a-select placeholder="请选择资金来源" v-model:value="item.source"
													class="ui-inputNumWidth"
													:disabled="current < projectStage && item.isDisabled">
													<a-select-option :value="0">上级专项资金</a-select-option>
													<a-select-option :value="1">县国资公司</a-select-option>
													<a-select-option :value="2">县本级财政资金</a-select-option>
													<a-select-option :value="3">其他</a-select-option>
												</a-select>
											</a-form-item>
											<a-form-item label="请上传发票"
												:name="[ 'inquiry','constructionInquiryPayment',index,'ticket']"
												:rules="[{required:true, message: '请上传发票'}]">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.ticket"
														:index="index+1"
														name="工程项目设备询价付款发票"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
										<!-- <a-col :span="12">
									        <a-button @click="onAddPayRecord(construction.inquiry.constructionInquiryPayment,index)">新增付款记录</a-button>
									        <a-button @click="onDeletePayRecord(construction.inquiry.constructionInquiryPayment,index)" v-if="construction.inquiry.constructionInquiryPayment.length >= 1">删除此记录</a-button>
									    </a-col> -->
									</a-row>
								</div>
								<div>
									<div class="ui-plusIconBox">
										<Icon width="60" class="ui-icon-plusStyle" icon="PlusOutlined" />
										<a-button type="text" size="large"
											@click="onAddPayRecord(construction.inquiry.constructionInquiryCost,construction.inquiry.constructionInquiryPayment,{amount: null,source: null,applyFile: [],ticket: [],voucher: [],isDisabled:false})">
											新增付款记录</a-button>
									</div>
								</div>
							</div>
						</div>
						<div v-if="construction.inquiry.constructionHasInquiry == 1">
							<a-row :gutter="24">
								<a-col :span="6">
									<a-form-item label="请上传会议纪要" :name="[ 'inquiry','constructionInquirySummary']"
										:rules="[{required:true, message: '请上传会议纪要'}]">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="construction.inquiry.constructionInquirySummary"
												name="工程项目设备询价会议纪要"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="请上传同意询价批复" :name="[ 'inquiry','constructionInquiry']"
										:rules="[{required:true, message: '请上传同意询价批复'}]">
										<div style="display: flex;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="construction.inquiry.constructionInquiry"
												name="工程项目设备询价同意询价批复"
											>
											</filesUpload>
											<a-form-item>
												<a-date-picker v-model:value="construction.inquiry.constructionInquiryTime" :value-format="'X'"
															:disabled="!(construction.inquiry.constructionInquiry) || construction.inquiry.constructionInquiry.length == 0" />
											</a-form-item>
										</div>
									</a-form-item>
								</a-col>
								<a-col :span="6">
									<a-form-item label="请上传合同" :name="[ 'inquiry','constructionInquiryContract']"
										:rules="[{required:true, message: '请上传合同'}]">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="construction.inquiry.constructionInquiryContract"
												name="工程项目设备询价合同"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="请上传询价结果" :name="[ 'inquiry','constructionInquiryResult']"
										:rules="[{required:true, message: '请上传询价结果'}]">
										<div style="display: flex;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="construction.inquiry.constructionInquiryResult"
												name="工程项目设备询价结果"
											>
											</filesUpload>
										</div>
									</a-form-item>
								</a-col>
							</a-row>
						</div>
					</div>
				</div>
				<!-- 4、工程进度 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>4、工程进度 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-childBorderStyle" style="min-width: 1000px;">
						<a-row :gutter="24">
							<a-col :span="8">
								<a-form-item label="工程完成量" :name="['schedule','constructionSchedulePercent']"
									:rules="[{required:true}]">
									<a-input-number :min="0" :max="maxConstructionSchedulePercent" class="ui-inputNumWidth" :formatter="inputNumberFormat"
										v-model:value="construction.schedule.constructionSchedulePercent"
										:disabled="isModify && current < projectStage"
										placeholder="请输入当前工程完成量">
									</a-input-number> <span style="margin-left: 5px;">元</span>
								</a-form-item>
							</a-col>
							<a-col :span="6">
								<a-form-item label="工程进度百分比" :rules="[{required:true}]">
									{{ finishPercentage ? Number(finishPercentage).toFixed(2) : '0.00' }}%
								</a-form-item>
							</a-col>
							<a-col :span="8">
								<a-form-item label="项管办核定付款比例" :name="['schedule','constructionSchedulePayPercent']"
									:rules="[{required:true, message: '请选择项管办核定付款比例'}]">
									<a-select v-model:value="construction.schedule.constructionSchedulePayPercent"
										class="ui-inputNumWidth"
										:disabled="isModify && current < projectStage">
										<a-select-option :value="0.7">70%</a-select-option>
										<a-select-option :value="0.8">80%</a-select-option>
										<a-select-option v-if="$store.state.system.userInfo.adjust || formUseLand.speed.buildLandSpeedPayPercent === 0.9" :value="0.9">90%</a-select-option>
										<a-select-option v-if="$store.state.system.userInfo.adjust || formUseLand.speed.buildLandSpeedPayPercent === 1" :value="1">100%</a-select-option>
									</a-select>
								</a-form-item>
							</a-col>
						</a-row>
					</div>
				</div>
				<!-- 5、工程款付款 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>5、工程款付款 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-childBorderStyle">
						<a-row :gutter="24">
							<a-col>合同总金额：{{ inputNumberFormat(inviteTenders.success.inviteTendersSuccessPrice) }}元</a-col>
							<a-col>审计金额：{{ inputNumberFormat(auditPay(construction.payment.constructionPayment)) }}元</a-col>
							<a-col>已拨付金额：{{ inputNumberFormat(alreadyPay(construction.payment.constructionPayment,true)) }}元</a-col>
							<a-col>待拨付金额：{{ inputNumberFormat(constructionToPay()) }}元</a-col>
						</a-row>
						<div class="ui-cutApart"></div>
						<!-- <a-radio-group v-model:value="construction.payment.constructionPayment[index].type" :disabled="isModify && current < projectStage">
                            <a-radio :value="1">预付款</a-radio>
                            <a-radio :value="2">进度款</a-radio>
                            <a-radio :value="3">结算款</a-radio>
                            <a-radio :value="4">尾款</a-radio>
                        </a-radio-group> -->
						<div class="ui-cutApart"></div>
						<div class="ui-contentBorderBox"
							v-for="(item,index) in construction.payment.constructionPayment" :key="index">
							<div class="ui-contentWhiteBorder">
								<a-radio-group v-model:value="construction.payment.constructionPayment[index].type"
									:disabled="current < projectStage && item.isDisabled" @change="(e) => onTypeChange(e, 'constructionPayment', index)">
									<a-radio :value="0" :disabled="advanPayShow.includes(0)">预付款</a-radio>
									<a-radio :value="1">进度款</a-radio>
									<a-radio :value="2" :disabled="advanPayShow.includes(2)">结算款</a-radio>
									<a-radio :value="3">尾款</a-radio>
								</a-radio-group>
								<div class="ui-cutApart"></div>
								<a-row>
									<a-col :span="10">
										<a-form-item label="付款金额"
											:name="[ 'payment','constructionPayment',index,'amount']"
											:rules="[{required:true}]">
											<!-- <a-input-number :min="0" :max="advancePayMax(item.type)" placeholder="请输入付款金额" v-model:value="item.amount" -->
											<a-input-number :min="0" placeholder="请输入付款金额" v-model:value="item.amount"
												class="ui-inputNumWidth"
												:formatter="inputNumberFormat"
												@blur="changeProjectPrice(item.type,inviteTenders.success.inviteTendersSuccessPrice,construction.schedule.constructionSchedulePercent*construction.schedule.constructionSchedulePayPercent,construction.payment.constructionPayment,index)"
												:disabled="current < projectStage && item.isDisabled">
											</a-input-number>元
										</a-form-item>
										<a-form-item label="请上传请款审批表"
											:name="[ 'payment','constructionPayment',index,'applyFile']"
											:rules="[{required:true, message: '请上传请款审批表'}]">
											<div style="display: flex;height: 30px;">
												<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
												<filesUpload :disabled="current < projectStage && item.isDisabled"
													v-model:value="item.applyFile"
													:index="index+1"
													name="工程款请款审批表"
												></filesUpload>
											</div>
										</a-form-item>
										<a-form-item label="请上传支付凭证"
											:name="[ 'payment','constructionPayment',index,'voucher']"
											:rules="[{required:true, message: '请上传支付凭证'}]">
											<div style="display: flex;">
												<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
												<filesUpload :disabled="current < projectStage && item.isDisabled"
													v-model:value="item.voucher"
													:index="index+1"
													name="工程款支付凭证"
												></filesUpload>
											</div>
										</a-form-item>
										<a-form-item label="审计金额"
											:name="[ 'payment','constructionPayment',index,'auditCost']"
											:rules="[{required:true}]"
											v-if="construction.payment.constructionPayment[index].type == 2">
											<a-input-number :min="0" v-model:value="item.auditCost"
												class="ui-inputNumWidth"
												:formatter="inputNumberFormat"
												:disabled="current < projectStage && item.isDisabled">
											</a-input-number>元
										</a-form-item>
									</a-col>
									<a-col :span="10">
										<a-form-item label="资金来源"
											:name="[ 'payment','constructionPayment',index,'source']"
											:rules="[{required:true, message: '请选择资金来源'}]">
											<a-select placeholder="请选择资金来源" v-model:value="item.source"
												class="ui-inputNumWidth"
												:disabled="current < projectStage && item.isDisabled">
												<a-select-option :value="0">上级专项资金</a-select-option>
												<a-select-option :value="1">县国资公司</a-select-option>
												<a-select-option :value="2">县本级财政资金</a-select-option>
												<a-select-option :value="3">其他</a-select-option>
											</a-select>
										</a-form-item>
										<a-form-item label="请上传发票"
											:name="[ 'payment','constructionPayment',index,'ticket']"
											:rules="[{required:true, message: '请上传发票'}]">
											<div style="display: flex;height: 30px;">
												<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
												<filesUpload :disabled="current < projectStage && item.isDisabled"
													v-model:value="item.ticket"
													:index="index+1"
													name="工程款付款发票"
												></filesUpload>
											</div>
										</a-form-item>
										<a-form-item label="请上传工程进度报表"
											:name="[ 'payment','constructionPayment',index,'progress']"
											:rules="[{required:true, message: '请上传工程进度报表'}]"
											v-if="construction.payment.constructionPayment[index].type == 1">
											<div style="display: flex;">
												<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
												<filesUpload :disabled="current < projectStage && item.isDisabled"
													v-model:value="item.progress"
													:index="index+1"
													name="工程进度报表"
												></filesUpload>
											</div>
										</a-form-item>
										<a-form-item label="请上传审计报告"
											:name="[ 'payment','constructionPayment',index,'audit']"
											:rules="[{required:true, message: '请上传审计报告'}]"
											v-if="construction.payment.constructionPayment[index].type == 2">
											<div style="display: flex;">
												<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
												<filesUpload :disabled="current < projectStage && item.isDisabled"
													v-model:value="item.audit"
													:index="index+1"
													name="工程审计报告"
												></filesUpload>
											</div>
										</a-form-item>
									</a-col>
									<!-- <a-col :span="12">
								        <a-button @click="onAddPayRecord(construction.payment.constructionPayment,index)" type="primary">新增付款记录</a-button>
								        <a-button @click="onDeletePayRecord(construction.payment.constructionPayment,index)" type="primary" ghost v-if="construction.payment.constructionPayment.length >= 1">删除此记录</a-button>
								    </a-col> -->
								</a-row>
							</div>
							<div>
								<div class="ui-plusIconBox">
									<Icon width="60" class="ui-icon-plusStyle" icon="PlusOutlined" />
									<a-button type="text" size="large"
										@click="onAddPayProjectList(inviteTenders.success.inviteTendersSuccessPrice,construction.schedule.constructionSchedulePercent*construction.schedule.constructionSchedulePayPercent,construction.payment.constructionPayment,{type: onAddPayRecord?1:0,amount: null,source: null,applyFile: [],ticket:  [],voucher:  [],progress: [],audit: [],isDisabled:false})">
										新增付款记录</a-button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 6、审计 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>6、审计 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-childBorderStyle">
						<a-radio-group v-model:value="construction.audit.constructionHasAudit"
							:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'constructionHasAudit')">
							<a-radio :value="0" :disabled="isAudit">有审计</a-radio>
							<a-radio :value="1" :disabled="isUnAudit">有审计（跟踪审计）</a-radio>
							<a-radio :value="2" :disabled="isAudit">无审计</a-radio>
							<a-radio :value="3" :disabled="isAudit">不需要</a-radio>
						</a-radio-group>
						<div class="ui-cutApart"></div>
						<div
							v-if="construction.audit.constructionHasAudit == null || construction.audit.constructionHasAudit == 0">
							<a-row :gutter="24">
								<a-col :span="8">
									<a-form-item label="审计公司名称" :name="['audit','constructionAuditCompany']"
										:rules="[{required:true}]">
										<a-input v-model:value="construction.audit.constructionAuditCompany"
											placeholder="请输入审计公司名称"
											:disabled="isModify && current < projectStage">
										</a-input>
									</a-form-item>
									<a-form-item label="请上传会议纪要" :name="['audit','constructionAuditSummary']"
										:rules="[{required:true, message: '请上传会议纪要'}]">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="construction.audit.constructionAuditSummary"
												name="审计会议纪要"
											>
											</filesUpload>
										</div>
									</a-form-item>
								</a-col>
								<a-col :span="8">
									<a-form-item label="请上传中标通知书或成交确认书" :name="['audit','constructionAuditNoticeFile']"
										:rules="[{required:true, message: '请上传中标通知书或成交确认书'}]">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="construction.audit.constructionAuditNoticeFile"
												name="审计中标通知书或成交确认书"
											>
											</filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="请上传合同" :name="['audit','constructionAuditContract']"
										:rules="[{required:true, message: '请上传合同'}]">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage"
												v-model:value="construction.audit.constructionAuditContract"
												name="审计合同"
											>
											</filesUpload>
										</div>
									</a-form-item>
								</a-col>
							</a-row>
							<a-row :gutter="24">
								<a-col :span="8">
									<a-form-item label="合同金额" :name="['audit','constructionAuditCost']"
										:rules="[{required:true}]">
										<a-input-number :min="0" placeholder="请输入合同金额" class="ui-inputNumWidth"
										    :formatter="inputNumberFormat"
											v-model:value="construction.audit.constructionAuditCost"
											:disabled="isModify && current < projectStage">
										</a-input-number>元
									</a-form-item>
								</a-col>
								<a-col :span="8">
									<a-form-item label="已支付金额">
										{{ inputNumberFormat(countPrice(construction.audit.constructionAuditPayment,construction.audit.constructionAuditCost)) }}元
									</a-form-item>
								</a-col>
								<a-col :span="8">
									<a-form-item label="待支付金额">
										{{ inputNumberFormat(handleAwaitPrice(construction.audit.constructionAuditCost - countPrice(construction.audit.constructionAuditPayment,construction.audit.constructionAuditCost))) }}元
									</a-form-item>
								</a-col>
							</a-row>
							<div class="ui-contentBorderBox"
								v-for="(item,index) in construction.audit.constructionAuditPayment" :key="index">
								<div class="ui-contentWhiteBorder">
									<a-row :gutter="24">
										<a-col :span="10">
											<a-form-item label="付款金额">
												<a-input-number :min="0" placeholder="请输入付款金额"
													v-model:value="item.amount" class="ui-inputNumWidth"
													:formatter="inputNumberFormat"
													:disabled="current < projectStage && item.isDisabled">
												</a-input-number>元
											</a-form-item>
											<a-form-item label="请上传请款审批表">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.applyFile"
														:index="index+1"
														name="审计付款请款审批表"
													></filesUpload>
												</div>
											</a-form-item>
											<a-form-item label="请上传支付凭证">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.voucher"
														:index="index+1"
														name="审计支付凭证"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
										<a-col :span="10">
											<a-form-item label="资金来源">
												<a-select placeholder="请选择资金来源" v-model:value="item.source"
													class="ui-inputNumWidth"
													:disabled="current < projectStage && item.isDisabled">
													<a-select-option :value="0">上级专项资金</a-select-option>
													<a-select-option :value="1">县国资公司</a-select-option>
													<a-select-option :value="2">县本级财政资金</a-select-option>
													<a-select-option :value="3">其他</a-select-option>
												</a-select>
											</a-form-item>
											<a-form-item label="请上传发票">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="current < projectStage && item.isDisabled"
														v-model:value="item.ticket"
														:index="index+1"
														name="审计付款发票"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
										<!-- <a-col :span="12">
									        <a-button @click="onAddPayRecord(construction.audit.constructionAuditPayment,index)">新增付款记录</a-button>
									        <a-button @click="onDeletePayRecord(construction.audit.constructionAuditPayment,index)" v-if="construction.audit.constructionAuditPayment.length >= 1">删除此记录</a-button>
									    </a-col> -->
									</a-row>
								</div>
								<div>
									<div class="ui-plusIconBox">
										<Icon width="60" class="ui-icon-plusStyle" icon="PlusOutlined" />
										<a-button type="text" size="large"
											@click="onAddPayRecord(construction.audit.constructionAuditPayment,{amount: null,source: null,applyFile: [],ticket: [],voucher: [],isDisabled:false})">
											新增付款记录</a-button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</a-form>
		</div>
		<!-- 竣工验收 -->
		<div class="steps-content" v-if="steps[current].key == 'finish'">
			<a-form :model="completion" ref="completionRef" scrollToFirstError>
				<!-- 1、避雷检查 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>1、避雷检查 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-childBorderStyle">
						<a-radio-group v-model:value="completion.lpi.completionHasLpi"
							:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'completionHasLpi')">
							<a-radio :value="0">有避雷检查报告</a-radio>
							<a-radio :value="1">无避雷检查报告</a-radio>
							<a-radio :value="2">不需要</a-radio>
						</a-radio-group>
						<div class="ui-cutApart"></div>
						<a-form-item label="请上传避雷检查报告" :name="['lpi','completionLpiReport']" :rules="[{required:true, message: '请上传避雷检查报告'}]"
							v-if="completion.lpi.completionHasLpi == null || completion.lpi.completionHasLpi == 0">
							<div style="display: flex;">
								<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
								<filesUpload :disabled="isModify && current < projectStage"
									v-model:value="completion.lpi.completionLpiReport"
									name="避雷检查报告"
								>
								</filesUpload>
							</div>
						</a-form-item>
					</div>
				</div>
				<!-- 2、消防验收报告 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>2、消防验收报告 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-childBorderStyle">
						<a-radio-group v-model:value="completion.fire.completionHasFire"
							:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'completionHasFire')">
							<a-radio :value="0">有消防验收</a-radio>
							<a-radio :value="1">无消防验收</a-radio>
							<a-radio :value="2">不需要</a-radio>
						</a-radio-group>
						<div class="ui-cutApart"></div>
						<a-form-item label="请上传消防验收报告" :name="['fire','completionFireReport']"
							:rules="[{required:true, message: '请上传消防验收报告'}]"
							v-if="completion.fire.completionHasFire == null || completion.fire.completionHasFire == 0">
							<div style="display: flex;">
								<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
								<filesUpload :disabled="isModify && current < projectStage"
									v-model:value="completion.fire.completionFireReport"
									name="消防验收报告"
								></filesUpload>
							</div>
						</a-form-item>
					</div>
				</div>
				<!-- 3、建设规划验收报告 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>3、建设规划验收报告 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-childBorderStyle">
						<a-radio-group v-model:value="completion.build.completionHasBuild"
							:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'completionHasBuild')">
							<a-radio :value="0">有建设规划验收</a-radio>
							<a-radio :value="1">无建设规划验收</a-radio>
							<a-radio :value="2">不需要</a-radio>
						</a-radio-group>
						<div class="ui-cutApart"></div>
						<a-form-item label="请上传建设规划验收报告" :name="['build','completionBuildReport']"
							:rules="[{required:true, message: '请上传建设规划验收报告'}]"
							v-if="completion.build.completionHasBuild == null || completion.build.completionHasBuild == 0">
							<div style="display: flex;">
								<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
								<filesUpload :disabled="isModify && current < projectStage"
									v-model:value="completion.build.completionBuildReport"
									name="建设规划验收报告"
								></filesUpload>
							</div>
						</a-form-item>
					</div>
				</div>
				<!-- 4、环评验收报告 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>4、环评验收报告 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-childBorderStyle">
						<a-radio-group v-model:value="completion.eia.completionHasEia"
							:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'completionHasEia')">
							<a-radio :value="0">有环评验收</a-radio>
							<a-radio :value="1">无环评验收</a-radio>
							<a-radio :value="2">不需要</a-radio>
						</a-radio-group>
						<div class="ui-cutApart"></div>
						<a-form-item label="请上传环评验收报告" :name="['eia','completionEiaReport']" :rules="[{required:true, message: '请上传环评验收报告'}]"
							v-if="completion.eia.completionHasEia == null || completion.eia.completionHasEia == 0">
							<div style="display: flex;">
								<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
								<filesUpload :disabled="isModify && current < projectStage"
									v-model:value="completion.eia.completionEiaReport"
									name="环评验收报告"
								></filesUpload>
							</div>
						</a-form-item>
					</div>
				</div>
				<!-- 5、水保持验收报告 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>5、水土保持验收报告 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-childBorderStyle">
						<a-radio-group v-model:value="completion.keep.completionHasKeep"
							:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'completionHasKeep')">
							<a-radio :value="0">有水保验收</a-radio>
							<a-radio :value="1">无水保验收</a-radio>
							<a-radio :value="2">不需要</a-radio>
						</a-radio-group>
						<div class="ui-cutApart"></div>
						<a-form-item label="请上传水保验收报告" :name="['keep','completionKeepReport']"
							:rules="[{required:true, message: '请上传水保验收报告'}]"
							v-if="completion.keep.completionHasKeep == null || completion.keep.completionHasKeep == 0">
							<div style="display: flex;">
								<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
								<filesUpload :disabled="isModify && current < projectStage"
									v-model:value="completion.keep.completionKeepReport"
									name="水土保持验收报告"
								></filesUpload>
							</div>
						</a-form-item>
					</div>
				</div>
				<!-- 6、交、竣工验收报告 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>6、交、竣工验收报告 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-childBorderStyle">

						<a-form-item label="请上传交工验收报告" :name="['completed','completionReport']"
							:rules="[{required:true, message: '请上传交工验收报告'}]">
							<div style="display: flex;">
								<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
								<filesUpload :disabled="isModify && current < projectStage"
									v-model:value="completion.completed.completionReport"
									name="交工验收报告"
								></filesUpload>
							</div>
						</a-form-item>

						<a-form-item label="请上传竣工验收报告" :name="['completed','completionCompletedReport']"
							:rules="[{required:true, message: '请上传竣工验收报告'}]">
							<div style="display: flex;">
								<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
								<filesUpload :disabled="isModify && current < projectStage"
									v-model:value="completion.completed.completionCompletedReport"
									name="竣工验收报告"
								>
								</filesUpload>
							</div>
						</a-form-item>

					</div>
				</div>
				<!-- 7、竣工验收备案表 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>7、竣工验收备案表 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-childBorderStyle">
						<a-radio-group v-model:value="completion.filing.completionHasFiling"
							:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'completionHasFiling')">
							<a-radio :value="0">有竣工验收备案</a-radio>
							<a-radio :value="1">无竣工验收备案</a-radio>
							<a-radio :value="2">不需要</a-radio>
						</a-radio-group>
						<div class="ui-cutApart"></div>
						<a-form-item label="请上传竣工验收备案表" :name="['filing','completionFilingReport']"
							:rules="[{required:true, message: '请上传竣工验收备案表'}]"
							v-if="completion.filing.completionHasFiling == null || completion.filing.completionHasFiling == 0">
							<div style="display: flex;">
								<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
								<filesUpload :disabled="isModify && current < projectStage"
									v-model:value="completion.filing.completionFilingReport"
									name="竣工验收备案表"
								></filesUpload>
							</div>
						</a-form-item>
					</div>
				</div>
				<!-- 8、本阶段聘请第三方相关资料 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle"></span>8、本阶段聘请第三方相关资料 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<!-- 新增有无第三方资料 -->
					<a-radio-group v-model:value="completion.other.completionHasOrder"
						:disabled="isModify && current < projectStage" @change="(e) => onTypeChange(e, 'completionHasOrder')">
						<a-radio :value="0">有第三方服务</a-radio>
						<a-radio :value="1">无第三方服务</a-radio>
					</a-radio-group>
					<div v-for="(item,index) in completion.other.completionOther"
						:key="index">
						<div class="ui-SerChildBorderStyle" v-if="completion.other.completionHasOrder == 0">
							<div class="ui-SerChildStyleMini" style="width: 80%;">
							<a-row >
								<a-col :span="12">
									<a-form-item label="费用类别" :name="[ 'other','completionOther',index,'costType']"
										:rules="[{required:true, message: '请选择费用类别'}]">
										<a-select v-model:value="item.costType" class="ui-inputNumWidth" placeholder="请选择费用类别"
											:disabled="isModify && current < projectStage && item.isDisabled">
											<a-select-option :value="0">避雷检查</a-select-option>
											<a-select-option :value="1">消防验收</a-select-option>
											<a-select-option :value="2">建设规划验收</a-select-option>
											<a-select-option :value="3">环评验收</a-select-option>
											<a-select-option :value="4">水保验收</a-select-option>
											<a-select-option :value="5">交、竣工验收</a-select-option>
											<a-select-option :value="6">竣工备案</a-select-option>
											<a-select-option :value="7">其他</a-select-option>
										</a-select>
									</a-form-item>
									<a-form-item label="第三方公司名称" :name="[ 'other','completionOther',index,'company']"
										:rules="[{required:true}]">
										<a-input class="ui-inputLength" v-model:value="item.company" placeholder="请输入第三方公司名称"
											:disabled="isModify && current < projectStag && item.isDisablede"></a-input>
									</a-form-item>
									<a-form-item label="请上传聘请第三方的确认手续"
										:name="[ 'other','completionOther',index,'procedures']"
										:rules="[{required:true, message: '请上传聘请第三方的确认手续'}]">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage && item.isDisabled"
												v-model:value="item.procedures"
												:index="index+1"
												name="聘请第三方的确认手续"
											></filesUpload>
										</div>
									</a-form-item>
								</a-col>
								<a-col :span="12">
									<a-form-item label="费用名称" :name="[ 'other','completionOther',index,'costName']"
										:rules="[{required:true}]">
										<a-input class="ui-inputLength" v-model:value="item.costName" placeholder="请输入费用名称"
											:disabled="isModify && current < projectStage && item.isDisabled"></a-input>
									</a-form-item>
									<a-form-item label="请上传聘请第三方的会议纪要"
										:name="[ 'other','completionOther',index,'summary']" :rules="[{required:true, message: '请上传聘请第三方的会议纪要'}]">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage && item.isDisabled"
												v-model:value="item.summary"
												:index="index+1"
												name="聘请第三方的会议纪要"
											></filesUpload>
										</div>
									</a-form-item>
									<a-form-item label="请上传聘请第三方的合同"
										:name="[ 'other','completionOther',index,'contract']"
										:rules="[{required:true, message: '请上传聘请第三方的合同'}]">
										<div style="display: flex;height: 30px;">
											<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
											<filesUpload :disabled="isModify && current < projectStage && item.isDisabled"
												v-model:value="item.contract"
												:index="index+1"
												name="聘请第三方的合同"
											></filesUpload>
										</div>
									</a-form-item>
									<!-- <a-form-item label="待支付金额" :rules="[{required:true}]">
									0
								    {{ countResultassignAwaitEmploy('employInfoAwaitPaid',completion.other.completionOther[index].cost,index) }}
								</a-form-item> -->
								</a-col>
								<a-col :span="24">
									<a-row>
										<a-col :span="10">
											<a-form-item label="合同总金额" :name="[ 'other','completionOther',index,'cost']"
												:rules="[{required:true}]">
												<a-input-number :min="0" placeholder="请输入合同总金额" v-model:value="item.cost"
													class="ui-inputNumWidth"
													:formatter="inputNumberFormat"
													:disabled="isModify && current < projectStage && item.isDisabled"></a-input-number>元
											</a-form-item>
										</a-col>
										<a-col :span="7">
											<a-form-item label="已支付金额" :rules="[{required:true}]">
												{{ inputNumberFormat(commonAlreadyPay(item.payment)) }}元
												<!-- {{ countPaid('employInfoPaid',item.payment,'completion','completionOther',index) }} -->
											</a-form-item>
										</a-col>
										<a-col :span="7">
											<a-form-item label="待支付金额" :rules="[{required:true}]" style="line-height: 45px;">
												{{ inputNumberFormat(handleAwaitPrice(item.cost - commonAlreadyPay(item.payment))) }}元
												<!-- {{ countResultassignAwaitEmploy('employInfoAwaitPaid',completion.other.completionOther[index].cost,index) }} -->
											</a-form-item>
										</a-col>
									</a-row>
									<!-- <div class="ui-plusIconBox"><Icon width="60" class="ui-icon-plusStyle" icon="PlusOutlined" /><a-button type="text" size="large" @click="onAddFinishInfo" >新增费用记录</a-button></div> -->
								</a-col>
							</a-row>
							<div class="ui-contentBorderBox" v-for="(pay,payIndex) in item.payment" :key="payIndex">
								<div class="ui-contentWhiteBorder">
									<a-row>
										<a-col :span="10">
											<a-form-item label="付款金额">
												<a-input-number :min="0" placeholder="请输入付款金额"
													v-model:value="pay.amount" class="ui-inputNumWidth"
													:formatter="inputNumberFormat"
													@blur="changePrice(item.cost,item.payment,payIndex)"
													:disabled="!isModify && pay.isDisabled">
												</a-input-number>元
											</a-form-item>
											<!-- <a-form-item label="请上传请款审批表">
								            <div style="display: flex;">
								            	<Icon icon="ExclamationCircleOutlined" class="ui-icon-style"/>
								            	<filesUpload :disabled="isModify && current < projectStage" v-model:value="fileList"></filesUpload>
								            </div>
								        </a-form-item> -->
											<a-form-item label="请上传支付凭证" :name="[ 'other','completionOther',index, 'payment',payIndex,'voucher']" :rules="[{required:true, message:'请上传支付凭证'}]">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="!isModify && pay.isDisabled"
														v-model:value="pay.voucher"
														:index="(index+1)+''+payIndex"
														name="聘请第三方支付凭证"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
										<a-col :span="10">
											<a-form-item label="资金来源">
												<a-select placeholder="请选择资金来源" v-model:value="pay.source"
													class="ui-inputNumWidth"
													:disabled="!isModify && pay.isDisabled">
													<a-select-option :value="0">上级专项资金</a-select-option>
													<a-select-option :value="1">县国资公司</a-select-option>
													<a-select-option :value="2">县本级财政资金</a-select-option>
													<a-select-option :value="3">其他</a-select-option>
												</a-select>
											</a-form-item>
											<a-form-item label="请上传发票" :name="[ 'other','completionOther',index, 'payment',payIndex,'ticket']" :rules="[{required:true, message:'请上传发票'}]">
												<div style="display: flex;">
													<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
													<filesUpload :disabled="!isModify && pay.isDisabled"
														v-model:value="pay.ticket"
														:index="(index+1)+''+payIndex"
														name="聘请第三方付款发票"
													></filesUpload>
												</div>
											</a-form-item>
										</a-col>
										<!-- <a-col :span="12">
								        <a-button @click="onAddPayRecord(completion.other.completionOther[index].payment,payIndex)">新增付款记录</a-button>
								        <a-button @click="onDeletePayRecord(completion.other.completionOther[index].payment,payIndex)" v-if="completion.other.completionOther[index].payment.length >= 1">删除此记录</a-button>
								    </a-col> -->
									</a-row>
								</div>
								<div>
									<div class="ui-plusIconBox">
										<Icon width="60" class="ui-icon-plusStyle" icon="PlusOutlined" />
										<a-button type="text" size="large"
											@click="onAddPayRecord(item.cost,item.payment,{amount: null,source: null,ticket: [],voucher: [], isDisabled:false})">
											新增付款记录</a-button>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div class="ui-plusIconBox">
								<Icon width="60" class="ui-icon-plusStyle" icon="PlusOutlined" />
								<a-button type="text" size="large"
									@click="onAddPayRecord({},completion.other.completionOther,{costType: 0,costName: '',company: '',summary: [],procedures: [],contract: [],cost: null,isDisabled:false,payment: [{amount: null,source: null,ticket: [],voucher: [],isDisabled:false}]},true)">
									新增费用记录</a-button>
							</div>
						</div>
						</div>
					</div>
				</div>
				<!-- 9、项目财务决算报告 -->
				<div>
					<p class="ui-contentMarginTop"><span class="ui-tipsStyle">*</span>9、项目财务结算报告 <Icon icon="QuestionCircleOutlined" class="ui-icon-style" /></p>
					<div class="ui-childBorderStyle">

						<a-form-item label="结算金额" :name="['accounts','completionAccountsCost']"
							:rules="[{required:true}]">
							<a-input-number :min="0" placeholder="请输入结算金额"
								v-model:value="completion.accounts.completionAccountsCost" class="ui-inputNumWidth"
								:formatter="inputNumberFormat"
								:disabled="isModify && current < projectStage"></a-input-number>元
						</a-form-item>

						<a-form-item label="请上传项目财务结算报告" :name="['accounts','completionAccountsReport']"
							:rules="[{required:true, message: '请上传项目财务结算报告'}]">
							<div style="display: flex;">
								<!-- <Icon icon="ExclamationCircleOutlined" class="ui-icon-style" /> -->
								<filesUpload :disabled="isModify && current < projectStage"
									v-model:value="completion.accounts.completionAccountsReport"
									name="项目财务结算报告"
								></filesUpload>
							</div>
						</a-form-item>

					</div>
				</div>
			</a-form>
		</div>
		<div class="steps-action" v-if="!isPreview">
			<a-button v-if="current <= steps.length - 1" type="primary" ghost @click="next(current,0)">暂存并退出</a-button>
			<a-button v-if="current > 0" style="margin-left: 8px" @click="prev">取消</a-button>
			<a-button v-if="current < steps.length - 1" type="primary" @click="next(current,1)">下一阶段</a-button>
			<a-button v-if="current === steps.length - 1" type="primary" @click="next(current,1)">提交</a-button>
			<!-- <a-button v-if="current == steps.length - 1" type="primary" @click="">归档</a-button> -->
		</div>
		</a-spin>
	</div>
</template>

<script>
	import {
		updateProjectBasic,
		postSaveStage,
		postFiscalUpdateStage,
		getProjectDetail
	} from '@/service/modules/project.js'
	import cTable from '@/components/cTable/index.vue';
	import filesUpload from '@/components/upload/projectFileUpload.vue';
	import {
		Icon
	} from '@/components/icon/icon.js';
	import {
		message
	} from 'ant-design-vue';
	import modalHeader from '@/components/modalHeader/index.vue';
	// const dataJson = require('@/views/project/projectData.json')
	import projectData from '@/views/project/projectData.js'
	import {
		baseInfoEcho,
		buildLandEcho,
		approvalEcho,
		inviteTendersEcho,
		constructionEcho,
		completionEcho,
	} from '@/views/project/dataEcho.js'
	import eventBus from '@/utils/eventBus.js';
	function formValidator(rule, value) { // 表单校验方法
		if (!value) return Promise.reject('该项为必填')
		return Promise.resolve('已通过')
	}
	export default {
		components: {
			filesUpload,
			cTable,
			Icon,
			modalHeader
		},
		data() {
			return {
				loading: false,
				// projectData1: projectData,
				formUseLand: JSON.parse(JSON.stringify(projectData.formUseLand)),
				completion: JSON.parse(JSON.stringify(projectData.completion)),
				construction: JSON.parse(JSON.stringify(projectData.construction)),
				formApproval: JSON.parse(JSON.stringify(projectData.formApproval)),
				inviteTenders: JSON.parse(JSON.stringify(projectData.inviteTenders)),
				projectInfo: {},
				stageInfos: {},
				projectStage: 0,
				projectId: null,
				baseInfoLoading: false,
				landInfoLoading: false,
				current: 0,
				isPreview: false, //是否整体预览
				currentSteps: '',
				useable: false, // 是否允许修改
				isModify: false, //是否可输入
				// advanPayShow: false, // 验收 预付款按钮是否可选
				steps: [{
						title: '基本信息',
						key: 'addInfo',
						form: 'formProjectInfoRef'
					},
					{
						title: '建设用地储备',
						key: 'land',
						form: 'formUseLandRef'
					},
					{
						title: '项目立项',
						key: 'confirm',
						form: 'formApprovalRef'
					},
					{
						title: '项目招标',
						key: 'inviteBids',
						form: 'inviteTendersRef'
					},
					{
						title: '项目施工',
						key: 'construction',
						form: 'constructionRef'
					},
					{
						title: '竣工验收',
						key: 'finish',
						form: 'completionRef'
					}
				],
				// options: [{
				// 		value: "1",
				// 		label: '固定资产'
				// 	},
				// 	{
				// 		value: "2",
				// 		label: '公共基础设施',
				// 		children: [{
				// 			value: "3",
				// 			label: '水利'
				// 		}, {
				// 			value: "4",
				// 			label: '交通'
				// 		}, {
				// 			value: "5",
				// 			label: '市政'
				// 		}, {
				// 			value: "6",
				// 			label: '其它'
				// 		}]
				// 	},
				// 	{
				// 		value: "7",
				// 		label: '费用支出'
				// 	}
				// ],
				options: [{
						value: "1",
						label: '固定资产',
						children: []
					},
					{
						value: "2",
						label: '公共基础设施',
						children: [{
							value: "3",
							label: '水利',
							children: []
						}, {
							value: "4",
							label: '交通',
							children: []
						}, {
							value: "5",
							label: '市政',
							children: []
						}, {
							value: "6",
							label: '其它',
							children: []
						}],
					},
					{
						value: "7",
						label: '费用支出',
						children: []
					}
				],
				summaryOptions: [{
						label: '项目立项',
						value: 0
					},
					{
						label: '聘请可研编制',
						value: 1
					},
					{
						label: '聘请施工图设计',
						value: 2
					},
					{
						label: '聘请测绘',
						value: 3
					},
					{
						label: '聘请地勘',
						value: 4
					},
					{
						label: '聘请预算编制',
						value: 5
					},
					{
						label: '聘请招标代理公司',
						value: 6
					},
					{
						label: '其他',
						value: 7
					},
				],
				formProjectInfo: {}, //基本信息录入
				// fileList: [],
				// 项目招标的单选框控制，6、全过程资讯信息的单选框
				isConsult: false,
				isUnConsult: false,
				// 项目施工的单选框控制,6、审计的单选框
				isAudit: null,
				isUnAudit: false,
				// 基本信息录入---表单校验规则
				formProjectInfoRules: {
					name: [{
						required: true,
						message: '请输入项目名称'
					}],
					mangerUnitId: [{
						required: true,
						message: '请选择主管单位'
					}],
					mangerUserPhone: [{
						required: true,
						message: '请输入正确的手机号',
						pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
						trigger: 'blur'
					}],
					mangerUsername: [{
						required: true,
						message: '请输入主管单位联系人',
						trigger: 'blur'
					}, {
						min: 2,
						max: 6,
						message: '请输入正确的姓名',
						trigger: 'blur'
					}],
					description: [{
						required: true,
						message: '请输入项目主要的建设内容'
					}, {
						min: 10,
						message: '至少10个字',
						trigger: 'blur'
					}],
					headUsername: [{
						required: true,
						message: '请输入项目负责人姓名'
					}, {
						min: 2,
						max: 6,
						message: '请输入正确的姓名',
						trigger: 'blur'
					}],
					leaderUsername: [{
						required: true,
						message: '请输入分管领导姓名'
					}, {
						min: 2,
						max: 6,
						message: '请输入正确的姓名',
						trigger: 'blur'
					}],
					headUserPhone: [{
						required: true,
						message: '请输入正确的手机号',
						pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
						trigger: 'blur'
					}],
					leaderPhone: [{
						required: true,
						message: '请输入正确的手机号',
						pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
						trigger: 'blur'
					}]
				},
				// 建设用地储备---表单校验规则
				formUseLandRules: {
					'result.buildLandResultUnitName': [{
						required: true,
						validator: formValidator
					}],
				}
			}
		},
		// watch: {
		// 	formUseLand: {
		// 		handler(newVal,oldVal) {
		// 			console.log("newVal",newVal)
		// 		},
		// 		deep:true
		// 	}
		// },
		computed: {
			// 三通一平资金总额
			payPrice() {
				const res = this.formUseLand.paySpecialPrice + this.formUseLand.payCurrentPrice + this.formUseLand
					.payCompanyPrice + this.formUseLand.payOrderPrice;
				if (isNaN(res)) return 0;
				else return res;
			},
			// 调查支出费用总额
			surveyPrice() {
				const res = this.formUseLand.surveySpecialPrice + this.formUseLand.surveyCurrentPrice + this.formUseLand
					.surveyCompanyPrice + this.formUseLand.surveyOrderPrice;
				if (isNaN(res)) return 0;
				else return res;
			},
			// 项目立项的班子会，其他（选项的输入框展示）
			showOrderInput() {
				return this.formApproval.meeting.projectApprovalMeetingSelected.includes(8);
			},
			// 建设用地储备，三通一平支出，工程进度百分比
			paymentPercentage() {
				if (this.formUseLand.speed.buildLandSpeedTotalCost != null && this.formUseLand.speed.buildLandSpeedCompletedPercent != null) {
					const res = (this.formUseLand.speed.buildLandSpeedCompletedPercent / this.formUseLand.speed.buildLandSpeedTotalCost) * 100;
					if (isNaN(res)) return 0;
					this.formUseLand.speed.paymentPercentage = res;
					return res;
				} else {
					return 0;
				}
			},
			
			// 项目施工，工程进度，百分比计算
			finishPercentage() {
				let amount = 0;
				// 有审计金额
				if(this.auditPay(this.construction.payment.constructionPayment)){
					amount += Number(this.auditPay(this.construction.payment.constructionPayment));
				}
				// 有增量金额
				if(this.construction.change.constructionChangeAddAmount){
					amount += Number(this.construction.change.constructionChangeAddAmount);
				}
				if(this.auditPay(this.construction.payment.constructionPayment)===0){
					amount += Number(this.inviteTenders.success.inviteTendersSuccessPrice);
				}
				const res = (this.construction.schedule.constructionSchedulePercent / amount) * 100;
				return res;
				// if (this.construction.schedule.constructionSchedulePercent && this.inviteTenders.success.inviteTendersSuccessPrice) {
				// 	const res = (this.construction.schedule.constructionSchedulePercent / this.inviteTenders.success.inviteTendersSuccessPrice) * 100;
				// 	// this.construction.finishPercentage = res;
				// 	return res;
				// } else {
				// 	return 0;
				// }
			},
			advanPayShow() {
				let payTypeList = [];
				if (this.construction.payment.constructionPayment) {
					payTypeList = this.construction.payment.constructionPayment.map(item => {
						return item.type
					})
				}
				return payTypeList;
			},
			onbuildLandSpeedPaymentType() {
				let peyTypeList = [];
				if (this.formUseLand.speed.buildLandSpeedPaymentFile) {
					peyTypeList = this.formUseLand.speed.buildLandSpeedPaymentFile.map(item => {
						return item.type;
					})
				}
				return peyTypeList;
			},
			buildLandResultPaymentType() {
				let peyTypeList = [];
				if (this.formUseLand.result.buildLandResultPaymentFile) {
					peyTypeList = this.formUseLand.result.buildLandResultPaymentFile.map(item => {
						return item.type;
					})
				}
				return peyTypeList;
			},
			maxConstructionSchedulePercent(){
				if(this.construction.schedule.constructionSchedulePercent>(Number(this.construction.change?.constructionChangeAddAmount)+Number(this.inviteTenders.success.inviteTendersSuccessPrice))){
                    return Number(this.construction.change?.constructionChangeAddAmount)+Number(this.inviteTenders.success.inviteTendersSuccessPrice)
				}
				if(Number(this.auditPay(this.construction.payment.constructionPayment))&&this.construction.schedule.constructionSchedulePercent>(Number(this.construction.change?.constructionChangeAddAmount)+Number(this.auditPay(this.construction.payment.constructionPayment)))){
                    return Number(this.construction.change?.constructionChangeAddAmount) + Number(this.auditPay(this.construction.payment.constructionPayment))
				}
			}
		},
		created() {
			if (this.$route.query.id) {
				this.isModify = true
				// if(this.$route.query.isPreview) {
				// 	this.onPreviewBtn()
				// }
				if (this.$route.query.usable) {
					if (Number(this.$route.query.usable) === 1) {
						// this.useable = true
						this.isModify = false
					}
				}
				this.projectId = Number(this.$route.query.id)
				// this.projectStage = Number(this.$route.query.stage) === 0?1:Number(this.$route.query.stage)
				// this.current = Number(this.projectStage)
				this.getDetailFun(this.projectId)
			}
		},
		methods: {
			handleAwaitPrice(value){
				if(value==='Infinity'){
					return '招标合同价为0,无法计算比例'
				}
				if(isNaN(value)) return 0;
				return value;
			},
			// 计算最大金额
			/**
			 * @param {合同金额}
			 */
			countMaxPrice(contract){
				if(!contract){
					// message.warning(`请填写合同金额`);
					return Infinity;
				}else{
					return contract;
				}
			},

			async getDetailFun(id){
				this.loading = true;
				try {
					let ret = await getProjectDetail({
						id
					})
					this.loading = false;
					if (ret.code === 200) {
						this.projectInfo = ret.data
						this.stageInfos = this.projectInfo.stageInfos
						this.projectId = this.projectInfo.id
						this.projectStage = Number(this.projectInfo.stage) === 0?1:Number(this.projectInfo.stage)
						this.current = this.projectStage
						this.onInit()
					}
				} catch (e) {
					//TODO handle the exception
					this.loading = false;
				}
			},
			async onSubmitProjectBasic(step) { // 修改基本信息
				let postData = JSON.parse(JSON.stringify(this.formProjectInfo));
				    postData.finalUpdate = step
					let tempTime = this.transDateTime(postData.bootTime)
					tempTime = new Date(tempTime)
					// postData.bootTime = tempTime.valueOf()
					postData.bootTime = this.moment(tempTime).unix();
					postData.id = this.projectId
					this.baseInfoLoading = true;
					try {
						let ret = await updateProjectBasic(postData)
						this.baseInfoLoading = false;
						if (ret.code == 200) {
							this.$message.success('录入成功');
							this.current = this.current + 1;
						}
					} catch (e) {
						this.baseInfoLoading = false;
					}
				// this.$refs.formProjectInfoRef.validate().then(async () => {
				// 	let postData = JSON.parse(JSON.stringify(this.formProjectInfo));
				// 	let tempTime = this.transDateTime(postData.bootTime)
				// 	tempTime = new Date(tempTime)
				// 	// postData.bootTime = tempTime.valueOf()
				// 	postData.bootTime = this.moment(tempTime).unix();
				// 	postData.id = this.projectId
				// 	this.baseInfoLoading = true;
				// 	try {
				// 		let ret = await updateProjectBasic(postData)
				// 		this.baseInfoLoading = false;
				// 		if (ret.code == 200) {
				// 			this.$message.success('录入成功');
				// 			this.current = this.current + 1;
				// 		}
				// 	} catch (e) {
				// 		this.baseInfoLoading = false;
				// 	}
				// })
			},
			onSubmitProjectLand(step) { // 提交建设用地储备信息
				if(step === 0) { // 走保存 不走表单验证
					this.submitProjectLandData(step)
				} else {
					return this.submitProjectLandData(step)
					// this.$refs.formUseLandRef.validate().then(() => {
					// 	return this.submitProjectLandData(step)
					// })
				}
			},
			async submitProjectLandData(step) {
					this.countAuditPrice();
					let postData = JSON.parse(JSON.stringify(this.formUseLand));
					let speedPayArr = postData?.speed?.buildLandSpeedPaymentFile || [];
					let costAuditPayArr = postData?.costAudit?.buildLandCostAuditPaymentFile || [];
					let resultPayArr = postData?.result?.buildLandResultPaymentFile || [];
					// 建设用地来源
					postData.source.buildLandSourcePlanFile = this.fileToStr(postData.source.buildLandSourcePlanFile)
					postData.source.buildLandSourceImageFile = this.fileToStr(postData.source.buildLandSourceImageFile)
					postData.source.buildLandSourcePaymentFile = this.fileToStr(postData.source.buildLandSourcePaymentFile)
					postData.source.buildLandSourceTransferFile = this.fileToStr(postData.source.buildLandSourceTransferFile)
					// 土地性质
					postData.nature.buildLandNatureReportFile = this.fileToStr(postData.nature.buildLandNatureReportFile)
					postData.nature.buildLandNatureAuditFile = this.fileToStr(postData.nature.buildLandNatureAuditFile)
					postData.nature.buildLandNatureMapTicket = this.fileToStr(postData.nature.buildLandNatureMapTicket)
					postData.nature.buildLandNatureMapPaymentFile = this.fileToStr(postData.nature.buildLandNatureMapPaymentFile)
					postData.nature.buildLandNatureApprovalTicket = this.fileToStr(postData.nature.buildLandNatureApprovalTicket)
					postData.nature.buildLandNatureApprovalPaymentFile = this.fileToStr(postData.nature.buildLandNatureApprovalPaymentFile)
					// 土地报批
					postData.approval.buildLandApprovalPaymentFile = this.fileToStr(postData.approval.buildLandApprovalPaymentFile)
					postData.approval.buildLandApprovalTicket = this.fileToStr(postData.approval.buildLandApprovalTicket)
					// 取得方式
					postData.gain.buildLandGainConfirmFile = this.fileToStr(postData.gain.buildLandGainConfirmFile)
					postData.gain.buildLandGainContractFile = this.fileToStr(postData.gain.buildLandGainContractFile)
					postData.gain.buildLandGainChangeFile = this.fileToStr(postData.gain.buildLandGainChangeFile)
					postData.gain.buildLandGainTicketFile = this.fileToStr(postData.gain.buildLandGainTicketFile)
					postData.gain.buildLandGainPaymentFile = this.fileToStr(postData.gain.buildLandGainPaymentFile)
					// 前置办理不动产登记证
					postData.register.buildLandRegisterPermitFile = this.fileToStr(postData.register.buildLandRegisterPermitFile)
					postData.register.buildLandRegisterRegisterFile = this.fileToStr(postData.register.buildLandRegisterRegisterFile)
					postData.register.buildLandRegisterTicketFile = this.fileToStr(postData.register.buildLandRegisterTicketFile)
					postData.register.buildLandRegisterPaymentFile = this.fileToStr(postData.register.buildLandRegisterPaymentFile)
					// // 三通一平支出
					// postData.speed.buildLandSpeedContractFile = this.fileToStr(postData.speed.buildLandSpeedContractFile)
					// postData.speed.buildLandSpeedNoticeFile = this.fileToStr(postData.speed.buildLandSpeedNoticeFile)
					// postData.speed.buildLandSpeedSummaryFile = this.fileToStr(postData.speed.buildLandSpeedSummaryFile)
					postData.speed.buildLandSpeed.forEach((item,index)=>{
						postData.speed.buildLandSpeed[index].summary = this.fileToStr(item.summary);
						postData.speed.buildLandSpeed[index].contract = this.fileToStr(item.contract);
						postData.speed.buildLandSpeed[index].notice = this.fileToStr(item.notice);
						item.payment.forEach((pay,payIndex)=>{
							postData.speed.buildLandSpeed[index].payment[payIndex].ticket = this.fileToStr(pay.ticket);
							postData.speed.buildLandSpeed[index].payment[payIndex].voucher = this.fileToStr(pay.voucher);
							postData.speed.buildLandSpeed[index].payment[payIndex].applyFile = this.fileToStr(pay.applyFile);
							postData.speed.buildLandSpeed[index].payment[payIndex].auditReport = this.fileToStr(pay.auditReport);
							postData.speed.buildLandSpeed[index].payment[payIndex].auditCost = this.fileToStr(pay.auditCost);
							postData.speed.buildLandSpeed[index].payment[payIndex].scheduleFile = this.fileToStr(pay.scheduleFile);
						})
					})
					// speedPayArr.forEach((item, index) => {
					// 	postData.speed.buildLandSpeedPaymentFile[index].applyFile = this.fileToStr(item.applyFile)
					// 	postData.speed.buildLandSpeedPaymentFile[index].ticket = this.fileToStr(item.ticket)
					// 	postData.speed.buildLandSpeedPaymentFile[index].voucher = this.fileToStr(item.voucher)
					// 	postData.speed.buildLandSpeedPaymentFile[index].scheduleFile = this.fileToStr(item.scheduleFile)
					// 	postData.speed.buildLandSpeedPaymentFile[index].auditReport = this.fileToStr(item.auditReport)
					// })

					// 三通一平审计
					postData.costAudit.buildLandCostAuditContractFile = this.fileToStr(postData.costAudit.buildLandCostAuditContractFile)
					postData.costAudit.buildLandCostAuditNoticeFile = this.fileToStr(postData.costAudit.buildLandCostAuditNoticeFile)
					postData.costAudit.buildLandCostAuditSummaryFile = this.fileToStr(postData.costAudit.buildLandCostAuditSummaryFile)
					costAuditPayArr.forEach((item, index) => {
						postData.costAudit.buildLandCostAuditPaymentFile[index].applyFile = this.fileToStr(item.applyFile)
						postData.costAudit.buildLandCostAuditPaymentFile[index].ticket = this.fileToStr(item.ticket)
						postData.costAudit.buildLandCostAuditPaymentFile[index].voucher = this.fileToStr(item.voucher)
					})
					//  污染状况调查
					postData.result.buildLandResultContractFile = this.fileToStr(postData.result.buildLandResultContractFile)
					postData.result.buildLandResultNoticeFile = this.fileToStr(postData.result.buildLandResultNoticeFile)
					postData.result.buildLandResultSummaryFile = this.fileToStr(postData.result.buildLandResultSummaryFile)
					postData.result.buildLandResultSurveyFile = this.fileToStr(postData.result.buildLandResultSurveyFile)
					resultPayArr.forEach((item, index) => {
						postData.result.buildLandResultPaymentFile[index].applyFile = this.fileToStr(item.applyFile)
						postData.result.buildLandResultPaymentFile[index].ticket = this.fileToStr(item.ticket)
						postData.result.buildLandResultPaymentFile[index].voucher = this.fileToStr(item.voucher)
					})
					let postDataArr = this.postMessage(postData)
					this.landInfoLoading = true;
					try {
						let ret
						let tempParam = {
							projectId: this.projectId,
							stage: 1,
							message: postDataArr,
						}
						// if (this.current <= this.projectStage) {
						if (!this.isModify && this.current !== this.projectStage) {
							tempParam.finalUpdate = step ? 0 : 1;
							ret = await postFiscalUpdateStage(tempParam)
						} else {
							tempParam.advance = step;
							if(step === 1){
								ret = await postSaveStage(tempParam)
							} else {
								ret = await postSaveStage(tempParam)
							}
						}
						this.landInfoLoading = false;
						if (ret.code === 200) {
							this.$message.success('保存成功');
							if (step === 1) {
								if (this.current === this.projectStage) {
									this.projectStage = this.projectStage + 1
								}
								this.current = this.current + 1;
							} else if (step === 0) {
								this.$closePage('/project/projectMain');
								// this.$router.push({
								// 	name: 'projectMain'
								// })
							}
						}
					} catch (e) {
						this.landInfoLoading = false;
					}
			},
			
			onSubmitProjectApproval(step) {
				if(step === 0) { // 走保存 不走表单验证
					this.submitProjectApprovalData(step)
				} else {
					return this.submitProjectApprovalData(step)
					// this.$refs.formApprovalRef.validate().then(() => {
					// 	return this.submitProjectApprovalData(step)
					// })
				}
			},
			async submitProjectApprovalData(step){
				// console.log(this.formApproval);

					let postData = JSON.parse(JSON.stringify(this.formApproval));
					let sourceFile = postData.source
					let reviewFile = postData.review
					let permitFile = postData.permit
					let speedFeasFile = postData.speed.feasibility.projectApprovalSpeedFeasibilityPayment || []
					let designerFile = postData.speed.designer.projectApprovalSpeedDesignerPayment || []
					let checkerFile = postData.speed.checker.projectApprovalSpeedCheckerPayment || []
					let surveyorFile = postData.speed.surveyor.projectApprovalSpeedSurveyorPayment || []
					let geologicalFile = postData.speed.geological.projectApprovalSpeedGeologicalPayment || []
					let budgetingFile = postData.speed.budgeting.projectApprovalSpeedBudgetingPayment || []
					let budgetFile = postData.speed.budget.projectApprovalSpeedBudgetPayment || []
					let agentFile = postData.speed.agent.projectApprovalSpeedAgentPayment || []
					let otherFile = postData.speed.other.projectApprovalSpeedOther || []
					// let otherPaymentFile = postData.speed.other.projectApprovalSpeedOther[0]
					//  2.资金来源
					postData.source.projectApprovalSourceGovInvestPlan = this.fileToStr(sourceFile
						.projectApprovalSourceGovInvestPlan)
					postData.source.projectApprovalSourceSpecialFile = this.fileToStr(sourceFile
						.projectApprovalSourceSpecialFile)
					postData.source.projectApprovalSourceSummaryFile = this.fileToStr(sourceFile
						.projectApprovalSourceSummaryFile)
					postData.source.projectApprovalSourceCountyFile = this.fileToStr(sourceFile
						.projectApprovalSourceCountyFile)
					postData.source.projectApprovalSourceOtherFile = this.fileToStr(sourceFile
						.projectApprovalSourceOtherFile)
					//  3.项目建议书批复文件
					postData.review.projectApprovalReviewReviewFile = this.fileToStr(reviewFile
						.projectApprovalReviewReviewFile)
					postData.review.projectApprovalReviewReviewBook = this.fileToStr(reviewFile
						.projectApprovalReviewReviewBook)
					//  4.项目选址意见书
					postData.opinion.projectApprovalOpinionFile = this.fileToStr(postData.opinion
						.projectApprovalOpinionFile)
					//  5.科研批复
					postData.feasibility.projectApprovalFeasibilityFile = this.fileToStr(postData.feasibility
						.projectApprovalFeasibilityFile)
					//  6.用地许可证、工程规划许可证
					postData.permit.projectApprovalPermitLandFile = this.fileToStr(permitFile
						.projectApprovalPermitLandFile)
					postData.permit.projectApprovalPermitProjectFIle = this.fileToStr(permitFile
						.projectApprovalPermitProjectFIle)
					//  7.社会最风险评估
					postData.risk.projectApprovalRiskFile = this.fileToStr(postData.risk
						.projectApprovalRiskFile)
					//  8.初步设计批复
					postData.design.projectApprovalDesignFile = this.fileToStr(postData.design
						.projectApprovalDesignFile)
					//  9.环境影响评估
					postData.env.projectApprovalEnvApprovalFile = this.fileToStr(postData.env
						.projectApprovalEnvApprovalFile)
					postData.env.projectApprovalEnvRecordFile = this.fileToStr(postData.env
						.projectApprovalEnvRecordFile)
					//  10.水土保持方案
					postData.keep.projectApprovalKeepFile = this.fileToStr(postData.keep
						.projectApprovalKeepFile)
					//  11.防洪影响评价
					postData.evaluate.projectApprovalEvaluateFile = this.fileToStr(postData.evaluate
						.projectApprovalEvaluateFile)
					//	12.节能审批
					postData.ec.projectApprovalEcFIle = this.fileToStr(postData.ec.projectApprovalEcFIle)
					//  13.消防审批
					postData.fire.projectApprovalFireFile = this.fileToStr(postData.fire
						.projectApprovalFireFile)
					//  14.人防审批
					postData.pad.projectApprovalPadFile = this.fileToStr(postData.pad.projectApprovalPadFile)
					//  15.预算评审
					postData.budget.projectApprovalBudgetReport = this.fileToStr(postData.budget
						.projectApprovalBudgetReport)
					//  16.班子会会议纪要
					postData.meeting.projectApprovalMeetingSelected = this.fileToStr(postData.meeting
						.projectApprovalMeetingSelected)
					postData.meeting.projectApprovalMeetingSummary = this.fileToStr(postData.meeting
						.projectApprovalMeetingSummary)
					//  17.本阶段聘请中介的中标通知书(或电子卖场或中介超市成交通知书) 、合同、费用支付情况
					//  17-1.可研编制
					postData.speed.feasibility.projectApprovalSpeedFeasibilityNotice = this.fileToStr(postData
						.speed.feasibility.projectApprovalSpeedFeasibilityNotice)
					postData.speed.feasibility.projectApprovalSpeedFeasibilityContract = this.fileToStr(
						postData.speed.feasibility.projectApprovalSpeedFeasibilityContract)
					speedFeasFile.forEach((item, index) => {
						// postData.result.buildLandResultPaymentFile[index].applyFile = this.fileToStr(item.applyFile)
						postData.speed.feasibility.projectApprovalSpeedFeasibilityPayment[index]
							.ticket = this.fileToStr(item.ticket)
						postData.speed.feasibility.projectApprovalSpeedFeasibilityPayment[index]
							.voucher = this.fileToStr(item.voucher)
					})
					//  17-2.施工图设计
					postData.speed.designer.projectApprovalSpeedDesignerNotice = this.fileToStr(postData.speed
						.designer.projectApprovalSpeedDesignerNotice)
					postData.speed.designer.projectApprovalSpeedDesignerContract = this.fileToStr(postData
						.speed.designer.projectApprovalSpeedDesignerContract)
					designerFile.forEach((item, index) => {
						// postData.result.buildLandResultPaymentFile[index].applyFile = this.fileToStr(item.applyFile)
						postData.speed.designer.projectApprovalSpeedDesignerPayment[index].ticket =
							this.fileToStr(item.ticket)
						postData.speed.designer.projectApprovalSpeedDesignerPayment[index].voucher =
							this.fileToStr(item.voucher)
					})
					// 17-3.图审
					postData.speed.checker.projectApprovalSpeedCheckerNotice = this.fileToStr(postData.speed
						.checker.projectApprovalSpeedCheckerNotice)
					postData.speed.checker.projectApprovalSpeedCheckerContract = this.fileToStr(postData.speed.checker.projectApprovalSpeedCheckerContract)
					checkerFile.forEach((item, index) => {
						// postData.result.buildLandResultPaymentFile[index].applyFile = this.fileToStr(item.applyFile)
						postData.speed.checker.projectApprovalSpeedCheckerPayment[index].ticket = this
							.fileToStr(item.ticket)
						postData.speed.checker.projectApprovalSpeedCheckerPayment[index].voucher = this
							.fileToStr(item.voucher)
					})
					//  17-4.绘测 
					postData.speed.surveyor.projectApprovalSpeedSurveyorNotice = this.fileToStr(postData.speed
						.surveyor.projectApprovalSpeedSurveyorNotice)
					postData.speed.surveyor.projectApprovalSpeedSurveyorContract = this.fileToStr(postData
						.speed.surveyor.projectApprovalSpeedSurveyorContract)
					surveyorFile.forEach((item, index) => {
						// postData.result.buildLandResultPaymentFile[index].applyFile = this.fileToStr(item.applyFile)
						postData.speed.surveyor.projectApprovalSpeedSurveyorPayment[index].ticket =
							this.fileToStr(item.ticket)
						postData.speed.surveyor.projectApprovalSpeedSurveyorPayment[index].voucher =
							this.fileToStr(item.voucher)
					})
					//  17-5.地勘
					postData.speed.geological.projectApprovalSpeedGeologicalNotice = this.fileToStr(postData
						.speed.geological.projectApprovalSpeedGeologicalNotice)
					postData.speed.geological.projectApprovalSpeedGeologicalContract = this.fileToStr(postData
						.speed.geological.projectApprovalSpeedGeologicalContract)
					geologicalFile.forEach((item, index) => {
						// postData.result.buildLandResultPaymentFile[index].applyFile = this.fileToStr(item.applyFile)
						postData.speed.geological.projectApprovalSpeedGeologicalPayment[index].ticket =
							this.fileToStr(item.ticket)
						postData.speed.geological.projectApprovalSpeedGeologicalPayment[index]
							.voucher = this.fileToStr(item.voucher)
					})
					// 17-6.预算编制
					postData.speed.budgeting.projectApprovalSpeedBudgetingNotice = this.fileToStr(postData
						.speed.budgeting.projectApprovalSpeedBudgetingNotice)
					postData.speed.budgeting.projectApprovalSpeedBudgetingContract = this.fileToStr(postData
						.speed.budgeting.projectApprovalSpeedBudgetingContract)
					budgetingFile.forEach((item, index) => {
						// postData.result.buildLandResultPaymentFile[index].applyFile = this.fileToStr(item.applyFile)
						postData.speed.budgeting.projectApprovalSpeedBudgetingPayment[index].ticket =
							this.fileToStr(item.ticket)
						postData.speed.budgeting.projectApprovalSpeedBudgetingPayment[index].voucher =
							this.fileToStr(item.voucher)
					})
					//  17-7.预算评审
					postData.speed.budget.projectApprovalSpeedBudgetNotice = this.fileToStr(postData.speed
						.budget.projectApprovalSpeedBudgetNotice)
					postData.speed.budget.projectApprovalSpeedBudgetContract = this.fileToStr(postData.speed
						.budget.projectApprovalSpeedBudgetContract)
					budgetFile.forEach((item, index) => {
						// postData.result.buildLandResultPaymentFile[index].applyFile = this.fileToStr(item.applyFile)
						postData.speed.budget.projectApprovalSpeedBudgetPayment[index].ticket = this
							.fileToStr(item.ticket)
						postData.speed.budget.projectApprovalSpeedBudgetPayment[index].voucher = this
							.fileToStr(item.voucher)
					})
					//  17-8.招标代理
					postData.speed.agent.projectApprovalSpeedAgentNotice = this.fileToStr(postData.speed.agent
						.projectApprovalSpeedAgentNotice)
					postData.speed.agent.projectApprovalSpeedAgentContract = this.fileToStr(postData.speed
						.agent.projectApprovalSpeedAgentContract)
					agentFile.forEach((item, index) => {
						// postData.result.buildLandResultPaymentFile[index].applyFile = this.fileToStr(item.applyFile)
						postData.speed.agent.projectApprovalSpeedAgentPayment[index].ticket = this.fileToStr(item.ticket)
						postData.speed.agent.projectApprovalSpeedAgentPayment[index].voucher = this.fileToStr(item.voucher)
					})
					// 17-9.其他中介服务
					otherFile.forEach((item, index) => {
						postData.speed.other.projectApprovalSpeedOther[index].notice = this.fileToStr(item.notice)
						postData.speed.other.projectApprovalSpeedOther[index].contract = this.fileToStr(item.contract)
						item.payment.forEach((pay, payIndex) => {
							postData.speed.other.projectApprovalSpeedOther[index].payment[payIndex].ticket = this.fileToStr(pay.ticket)
							postData.speed.other.projectApprovalSpeedOther[index].payment[payIndex].voucher = this.fileToStr(pay.voucher)
						})
					})
					// this.baseInfoLoading = true;
					let postDataArr = this.postMessage(postData)
					try {
						let ret
						let tempParam = {
							projectId: this.projectId,
							stage: 2,
							message: postDataArr,
						}
						// if (this.current <= this.projectStage) {
						if (!this.isModify && this.current !== this.projectStage) {
							tempParam.finalUpdate = step ? 0 : 1;
							ret = await postFiscalUpdateStage(tempParam)
						} else {
							tempParam.advance = step;
							if(step === 1){
								ret = await postSaveStage(tempParam)
							} else {
								ret = await postSaveStage(tempParam)
							}
						}
						// this.landInfoLoading = false;
						if (ret.code === 200) {
							this.$message.success('保存成功');
							if (step === 1) {
								if (this.current === this.projectStage) {
									this.projectStage = this.projectStage + 1
								}
								this.current = this.current + 1;
								// this.projectStage = this.projectStage + 1
							} else if (step === 0) {
								this.$closePage('/project/projectMain');
								// this.$router.push({
								// 	name: 'projectMain'
								// })
							}
						}
					} catch (e) {
						this.landInfoLoading = false;
					}
			},		
			
			onSubmitProjectInviteTenders(step) {
				if(step === 0) { // 走保存 不走表单验证
					this.submitProjectInviteTendersData(step)
				} else {
					return this.submitProjectInviteTendersData(step)
					// this.$refs.inviteTendersRef.validate().then(() => {
					// 	return this.submitProjectInviteTendersData(step)
					// })
				}
			},
			async submitProjectInviteTendersData(step){
					let postData = JSON.parse(JSON.stringify(this.inviteTenders));
					this.changeDefaultAduit()
					let watcherPayArr = postData.watcher.inviteTendersWatcherPayment
					let flowablePayArr = postData.flowable.inviteTendersFlowablePayment
					let safPayArr = postData.saf.inviteTendersSafPayment;
					postData.summary.inviteTendersSummaryFile = this.fileToStr(this.inviteTenders.summary
						.inviteTendersSummaryFile)
					postData.meeting.inviteTendersMeetingSummary = this.fileToStr(this.inviteTenders.meeting
						.inviteTendersMeetingSummary)
					//  3.中标信息
					postData.success.inviteTendersSuccessNoticeFile = this.fileToStr(this.inviteTenders.success
						.inviteTendersSuccessNoticeFile)
					postData.success.inviteTendersSuccessContract = this.fileToStr(this.inviteTenders.success
						.inviteTendersSuccessContract)
					//  4.监理信息
					postData.watcher.inviteTendersWatcherNoticeFile = this.fileToStr(this.inviteTenders.watcher
						.inviteTendersWatcherNoticeFile)
					postData.watcher.inviteTendersWatcherSummary = this.fileToStr(this.inviteTenders.watcher
						.inviteTendersWatcherSummary)
					postData.watcher.inviteTendersWatcherContract = this.fileToStr(this.inviteTenders.watcher
						.inviteTendersWatcherContract)
					watcherPayArr.forEach((item, index) => {
						postData.watcher.inviteTendersWatcherPayment[index].applyFile = this.fileToStr(
							item.applyFile)
						postData.watcher.inviteTendersWatcherPayment[index].ticket = this.fileToStr(
							item.ticket)
						postData.watcher.inviteTendersWatcherPayment[index].voucher = this.fileToStr(
							item.voucher)
					})
					//  5.跟踪审计信息
					postData.flowable.inviteTendersFlowableNoticeFile = this.fileToStr(this.inviteTenders
						.flowable.inviteTendersFlowableNoticeFile)
					postData.flowable.inviteTendersFlowableSummary = this.fileToStr(this.inviteTenders.flowable
						.inviteTendersFlowableSummary)
					postData.flowable.inviteTendersFlowableContract = this.fileToStr(this.inviteTenders
						.flowable.inviteTendersFlowableContract)
					flowablePayArr.forEach((item, index) => {
						postData.flowable.inviteTendersFlowablePayment[index].applyFile = this
							.fileToStr(item.applyFile)
						postData.flowable.inviteTendersFlowablePayment[index].ticket = this.fileToStr(
							item.ticket)
						postData.flowable.inviteTendersFlowablePayment[index].voucher = this.fileToStr(
							item.voucher)
					})
					//  6.全过程咨询信息
					postData.saf.inviteTendersSafNoticeFile = this.fileToStr(this.inviteTenders.saf
						.inviteTendersSafNoticeFile)
					postData.saf.inviteTendersSafSummary = this.fileToStr(this.inviteTenders.saf
						.inviteTendersSafSummary)
					postData.saf.inviteTendersSafContract = this.fileToStr(this.inviteTenders.saf
						.inviteTendersSafContract)
					safPayArr.forEach((item, index) => {
						postData.saf.inviteTendersSafPayment[index].applyFile = this.fileToStr(item
							.applyFile)
						postData.saf.inviteTendersSafPayment[index].ticket = this.fileToStr(item
							.ticket)
						postData.saf.inviteTendersSafPayment[index].voucher = this.fileToStr(item
							.voucher)
					})
					let postDataArr = this.postMessage(postData)
					try {
						let tempParam = {
							projectId: this.projectId,
							stage: 3,
							message: postDataArr,
						}
						let ret;
						// if (this.current <= this.projectStage) {
						if (!this.isModify && this.current !== this.projectStage) {
							tempParam.finalUpdate = step ? 0 : 1;
							ret = await postFiscalUpdateStage(tempParam)
						} else {
							tempParam.advance = step;
							if(step === 1){
								ret = await postSaveStage(tempParam)
							} else {
								ret = await postSaveStage(tempParam)
							}
						}
						// this.landInfoLoading = false;
						if (ret.code === 200) {
							this.$message.success('保存成功');
							if (step === 1) {
								if (this.current === this.projectStage) {
									this.projectStage = this.projectStage + 1
								}
								this.current = this.current + 1;
								// this.projectStage = this.projectStage + 1
							} else if (step === 0) {
								this.$closePage('/project/projectMain');
							}
						}
					} catch (e) {
						// this.landInfoLoading = false;
					}
			},
			
			onSubmitProjectConstruction(step) {
				if(step === 0) { // 走保存 不走表单验证
					this.submitProjectConstructionData(step)
				} else {
					return this.submitProjectConstructionData(step)
					// this.$refs.constructionRef.validate().then(() => {
					// 	return this.submitProjectConstructionData(step)
					// })
				}
			},
			async submitProjectConstructionData(step) {
					let postData = JSON.parse(JSON.stringify(this.construction));
					let inquiryPayArr = postData.inquiry.constructionInquiryPayment
					let paymentArr = postData.payment.constructionPayment
					let auditPayArr = postData.audit.constructionAuditPayment
					// let flowablePayArr = postData.flowable.inviteTendersFlowablePayment
					// let safPayArr = postData.saf.inviteTendersSafPayment
					//  1 许可证或开工报告
					postData.permit.constructionPermitBuildFile = this.fileToStr(postData.permit
						.constructionPermitBuildFile)
					postData.permit.constructionPermitStartReport = this.fileToStr(postData.permit
						.constructionPermitStartReport)
					//  2 项目变更
					postData.change.constructionChangeRequest = this.fileToStr(postData.change
						.constructionChangeRequest)
					postData.change.constructionChangeResponse = this.fileToStr(postData.change
						.constructionChangeResponse)
					//  3 项目设备询价
					postData.inquiry.constructionInquiryNoticeFile = this.fileToStr(postData.inquiry
						.constructionInquiryNoticeFile)
					postData.inquiry.constructionInquirySummary = this.fileToStr(postData.inquiry
						.constructionInquirySummary)
					postData.inquiry.constructionInquiryContract = this.fileToStr(postData.inquiry
						.constructionInquiryContract)
					postData.inquiry.constructionInquiry = this.fileToStr(postData.inquiry.constructionInquiry)
					postData.inquiry.constructionInquiryResult = this.fileToStr(postData.inquiry
						.constructionInquiryResult)
					inquiryPayArr.forEach((item, index) => {
						postData.inquiry.constructionInquiryPayment[index].applyFile = this.fileToStr(
							item.applyFile)
						postData.inquiry.constructionInquiryPayment[index].ticket = this.fileToStr(item
							.ticket)
						postData.inquiry.constructionInquiryPayment[index].voucher = this.fileToStr(
							item.voucher)
					})
					//  5 工程款付款
					paymentArr.forEach((item, index) => {
						postData.payment.constructionPayment[index].applyFile = this.fileToStr(item
							.applyFile)
						postData.payment.constructionPayment[index].ticket = this.fileToStr(item
							.ticket)
						postData.payment.constructionPayment[index].voucher = this.fileToStr(item
							.voucher)
						postData.payment.constructionPayment[index].progress = this.fileToStr(item
							.progress)
						postData.payment.constructionPayment[index].audit = this.fileToStr(item.audit)
					})
					//  5 审计
					postData.audit.constructionAuditNoticeFile = this.fileToStr(postData.audit
						.constructionAuditNoticeFile)
					postData.audit.constructionAuditSummary = this.fileToStr(postData.audit
						.constructionAuditSummary)
					postData.audit.constructionAuditContract = this.fileToStr(postData.audit
						.constructionAuditContract)
					auditPayArr.forEach((item, index) => {
						postData.audit.constructionAuditPayment[index].applyFile = this.fileToStr(item
							.applyFile)
						postData.audit.constructionAuditPayment[index].ticket = this.fileToStr(item
							.ticket)
						postData.audit.constructionAuditPayment[index].voucher = this.fileToStr(item
							.voucher)
					})
					let postDataArr = this.postMessage(postData)
					try {
						let tempParam = {
							projectId: this.projectId,
							stage: 4,
							message: postDataArr,
							advance: step
						}
						let ret
						// if (this.current <= this.projectStage) {
						if (!this.isModify && this.current !== this.projectStage) {
							tempParam.finalUpdate = step ? 0 : 1;
							ret = await postFiscalUpdateStage(tempParam)
						} else {
							tempParam.advance = step;
							if(step === 1){
								ret = await postSaveStage(tempParam)
							} else {
								ret = await postSaveStage(tempParam)
							}
						}
						// this.landInfoLoading = false;
						if (ret.code === 200) {
							this.$message.success('保存成功');
							if (step === 1) {
								if (this.current === this.projectStage) {
									this.projectStage = this.projectStage + 1
								}
								this.current = this.current + 1;
								// this.projectStage = this.projectStage + 1
							} else if (step === 0) {
								this.$closePage('/project/projectMain');
							}
						}
					} catch (e) {
						// this.landInfoLoading = false;
					}
				},
			
			onSubmitProjectCompletionRef(step) {
				if(step === 0) { // 走保存 不走表单验证
					this.submitProjectCompletionData(step)
				} else {
					return this.submitProjectCompletionData(step)
					// this.$refs.completionRef.validate().then(() => {
					// 	return this.submitProjectCompletionData(step)
					// })
				}
			},
			async submitProjectCompletionData(step){
					let postData = JSON.parse(JSON.stringify(this.completion));
					let otherArr = postData.other.completionOther
					//  1.避雷检查
					postData.lpi.completionLpiReport = this.fileToStr(postData.lpi.completionLpiReport)
					//  2.消防验收报告
					postData.fire.completionFireReport = this.fileToStr(postData.fire.completionFireReport)
					//  3.建设规划验收报告
					postData.build.completionBuildReport = this.fileToStr(postData.build.completionBuildReport)
					//  4.环评验收报告
					postData.eia.completionEiaReport = this.fileToStr(postData.eia.completionEiaReport)
					//  5.水土保持验收报告
					postData.keep.completionKeepReport = this.fileToStr(postData.keep.completionKeepReport)
					//  6.竣工验收报告
					postData.completed.completionCompletedReport = this.fileToStr(postData.completed.completionCompletedReport)
					//  7.竣工验收备案表
					postData.filing.completionFilingReport = this.fileToStr(postData.filing.completionFilingReport)
					//  8.本阶聘请第三方报告
					postData.completed.completionReport = this.fileToStr(postData.completed.completionReport)
					otherArr.forEach((item, index) => {
						postData.other.completionOther[index].summary = this.fileToStr(item.summary)
						postData.other.completionOther[index].procedures = this.fileToStr(item
							.procedures)
						postData.other.completionOther[index].contract = this.fileToStr(item.contract)
						item.payment.forEach((pay, payIndex) => {
							postData.other.completionOther[index].payment[payIndex].ticket =
								this.fileToStr(pay.ticket)
							postData.other.completionOther[index].payment[payIndex].voucher =
								this.fileToStr(pay.voucher)
						})
					})
					//  9.项目财务决算报告
					postData.accounts.completionAccountsReport = this.fileToStr(postData.accounts
						.completionAccountsReport)
					let postDataArr = this.postMessage(postData)
					try {
						let tempParam = {
							projectId: this.projectId,
							stage: 5,
							message: postDataArr,
						}
						let ret
						// if (this.current <= this.projectStage) {
							// && this.current !== this.projectStage
						if (!this.isModify) {
							tempParam.finalUpdate = step ? 0 : 1;
							ret = await postFiscalUpdateStage(tempParam)
						} else {
							tempParam.advance = step;
							if(step === 1){
								ret = await postSaveStage(tempParam)
							} else {
								ret = await postSaveStage(tempParam)
							}
						}
						// this.landInfoLoading = false;
						if (ret.code === 200) {
							this.$message.success('保存成功');
							this.$closePage('/project/projectMain');
						}
					} catch (e) {
						// this.landInfoLoading = false;
					}
			},
			
			fileToStr(fileUrl) {
				if (Array.isArray(fileUrl)) {
					return fileUrl = fileUrl.join(',')
				} else {
					return fileUrl
				}
			},
			async next(tempCurrent, step) {
				// console.log(tempCurrent, step, this.projectStage, this.current, this.isModify);
				if (step === 0) {
					// && this.current !== this.projectStage
					if (!this.isModify) {
						this.$confirm({
							title:'提示',
							content: '退出后需再次申请才能修改，如果想要继续修改，请点击下一阶段。确定退出吗?',
							onOk: ()=> {
								this.nextSubmitFun(tempCurrent, step);
								// 让项目管理页面更新数据
								// eventBus.$emit('refreshProject');
							}
						})
					} else {
						this.nextSubmitFun(tempCurrent, step);
						// 让项目管理页面更新数据
						// eventBus.$emit('refreshProject');
					}
					return;
				}
				this.nextSubmitFun(tempCurrent, step);
				// eventBus.$emit('refreshProject');
				// const currentRef = this.steps[this.current].form;
				// console.log(this.$refs[currentRef]);
				// this.current = this.current + 1;
				// this.$refs.formProjectInfoRef.validate().then(()=>{
				//     this.current = this.current + 1;
				// }).catch(error =>{
				//     message.warning('请正确填写内容');
				// })
			},
			nextSubmitFun(tempCurrent, step) {
				if (tempCurrent == 0) {//基本信息
					this.onSubmitProjectBasic(step)
				}
				if (tempCurrent == 1) {//建设用地储备
					this.onSubmitProjectLand(step)
				}
				if (tempCurrent == 2) {//项目立项
					this.onSubmitProjectApproval(step)
				}
				if (tempCurrent == 3) {//项目招标
					this.onSubmitProjectInviteTenders(step)
				}
				if (tempCurrent == 4) {//项目施工
					this.onSubmitProjectConstruction(step)
				}
				if(tempCurrent === 5) {//竣工验收
					this.onSubmitProjectCompletionRef(step)
				}
				this.$nextTick(()=>{
					eventBus.$emit('projectScrollTop');
				})
			},
			onLastSumit() {
				this.onSubmitProjectCompletionRef(step)
			},
			prev() {
				this.$closePage('/project/projectMain');
				// this.current = this.current - 1;
			},
			commonAlreadyPay(arr) { // 通用已支付金额
				let amount = 0
				if (arr && arr.length) {
					arr.forEach(item => {
						if(item.amount) amount += (item.amount || 0)
					})
				}
				if(isNaN(amount)) return 0;
				return amount;
			},
			// 增加付款信息
			/**
			 * @param {price} 合同金额
			 * @param {list} 当前数组
			 * @param {tempObj} 新增对象
			 * @param {isOther} 是否是聘请其他中介服务
			 */
			onAddPayRecord(price,list, tempObj,isOther) {
				if(isOther){
					list.push(tempObj)//为聘请其他中介服务新增
					return;
				}
				let flag = true;
				let priceAmount = 0;
				// 判断数组是否都填写的信息，都填了才能新增
				list.forEach(item=>{
					priceAmount += item.amount; 
					if(!item.amount){
						flag = false;
					}
				})  
				// 都填写了金额
				if(flag){
					// 付款金额总额 < 合同金额，可以继续增加付款记录
					if(price > priceAmount){
						list.push(tempObj)//为true新增
					}else{
						message.warning('付款金额已到达最大，请调整付款金额');
					}
				}else{
					// 没有填写付款金额，不新增
					message.warning('请填写完成当前付款记录后新增');
				}
			},
			// 三通一平增加付款记录
			/**
			 * @param {priceTotal} 合同金额
			 * @param {price} 工程完成量*核定付款比例
			 * @param {list} 当前数组
			 * @param {tempObj} 新增对象
			 * @param {auditCost} 审计金额
			 */
			onAddPayRecordList(priceTotal,price,list, tempObj,auditCost){
				let flag = true;//判断是否都填写了金额
				let priceAmount = 0;//付款金额总和
				let hasArrears = false;//是否有尾款
				// 判断数组是否都填写的信息，都填了才能新增
				// 判断是否有尾款，没有尾款，可以加
				list.forEach(item=>{
					priceAmount += item.amount; 
					if(!item.amount){
						flag = false;
						if(item.amount == 0 && item.type == 1){
							flag = true;
						}
						
					}
					if(item.type == 2){
						hasArrears = true;
					}
				})  
				// 没有尾款&&都填写了金额
				if(!hasArrears&&flag){
					// console.log(price,priceAmount);
					// if(price <= priceAmount){
					// 	message.warning('当前付款金额已达最大值，无法新增');
					// 	return;
					// }
					list.push(tempObj);
					return;
				}
				// 有尾款（判断尾款+进度款+结算款金额 是否== 合同金额）
				if(hasArrears&&flag){
					// 有审计金额
					if(auditCost){
						if(priceAmount < auditCost){
							list.push(tempObj);
						}else{
							message.warning('付款金额已到达最大，请调整付款金额');
						}
					}else{//无审计金额
						// 全部的付款金额 < 合同金额
						if(priceAmount < priceTotal){
							list.push(tempObj);
						}else{
							message.warning('付款金额已到达最大，请调整付款金额');
						}
					}
					
					return;
				}
				if(!flag){
					// 没有填写付款金额，不新增
					message.warning('请填写完成当前付款记录后新增');
					return;
				}
				return;
			},
			// 工程款付款（新增付款记录）
			/**
			 * @param {priceTotal} 合同金额
			 * @param {price} 工程完成量*核定付款比例
			 * @param {list} 当前数组
			 * @param {tempObj} 新增对象
			 */
			onAddPayProjectList(priceTotal,price,list, tempObj){
				priceTotal = Number(priceTotal);
				let flag = true;//判断是否都填写了金额
				let priceAmount = 0;//付款金额总和
				let hasArrears = false;//是否有尾款
				let hasAduit = false;//是否有审计金额
				// 判断数组是否都填写的信息，都填了才能新增
				// 判断是否有尾款，没有尾款，可以加
				list.forEach(item=>{
					priceAmount += item.amount; 
					if(item.amount == null){
						flag = false;
					}
					if(item.type == 3){//有尾款
						hasArrears = true;
					}
					if(item.type == 2){//有结算款
						hasAduit = true;
					}
				})  
				// 没有尾款&&都填写了金额
				if(!hasArrears&&flag){
					list.push(tempObj);
					return;
				}
				// 没有结算款
				if(!hasAduit&&flag){
					list.push(tempObj);
					return;
				}
				// 有尾款（判断尾款+进度款+结算款金额 是否== 合同金额）
				if(hasArrears&&flag){
					// 有审计金额
					if(this.auditPay(this.construction.payment.constructionPayment)){
						if(priceAmount < this.auditPay(this.construction.payment.constructionPayment) + Number(this.construction.change.constructionChangeAddAmount)){
							list.push(tempObj);
						}else{
							message.warning('付款金额已到达最大，请调整付款金额');
						}
					}else{//无审计金额
						// 全部的付款金额 < 合同金额
						if(priceAmount < priceTotal){
							list.push(tempObj);
						}else{
							message.warning('付款金额已到达最大，请调整付款金额');
						}
					}
					
					return;
				}
				if(!flag){
					// 没有填写付款金额，不新增
					message.warning('请填写完成当前付款记录后新增');
					return;
				}
				return;
			},
			// 金额输入框change事件，判断金额不能超出最大，离开输入框时间
			/**
			 * @param {price合同金额}
			 * @param {list当前数组}
			 * @param {index当前索引}
			 */
			changePrice(price,list,index){
				// 计算前面的价格总和amount，if（index==0），当前price不得大于合同金额
				// if（list长度大于1）判断前面的金额相加不大于合同金额
				// 当前最大值= 合同金额-前面金额相加
				if(list.length == 1){
					if(list[index].amount > price){
						list[index].amount = price;
					}
					return;
				}
				let priceAmount = 0;//全部总和
				let priceAmountExceptCurrent = 0;//除了当前的总和
				list.forEach((item,i)=>{
					priceAmount += item.amount;
					if(i != index){
						priceAmountExceptCurrent += item.amount;
					}
				})
				if(priceAmount > price){//总和大于合同金额
					list[index].amount = price - priceAmountExceptCurrent;
				}
			},
			// 结算款的计算
			/**
			 * @param {type} 进度款or结算款or尾款
			 * @param {priceTotal} 合同金额
			 * @param {price} 工程完成量*付款比例
			 * @param {list} 当前数组
			 * @param {index} 当前索引
			 * @param {auditCost} 审计金额
			 */
			changePriceCount(type,priceTotal,price,list,index,auditCost){
				if(list.length == 1){
					if(list[index].amount > price){
						list[index].amount = price;
						message.warning('付款金额总额达到工程完成量的付款比例，不能新增付款记录');
					}
					return;
				}
				let priceAmount = 0; //全部付款总额总额
				let priceAmountExceptCurrent = 0; //出去当前项的，付款金额总额
				list.forEach((item,i)=>{
					priceAmount += item.amount;
					if(i !== index){
						priceAmountExceptCurrent += item.amount;
					}
				})
				// 进度款or结算款
				if(type == 0 || type == 1){
					if(priceAmount > price){
						list[index].amount = price - priceAmountExceptCurrent; 
					}
				}else if(type == 2){//尾款，可以支付剩下的核定付款比例,判断审计金额
					if(auditCost && (priceAmount >= auditCost)){//有审计
						list[index].amount = auditCost - priceAmountExceptCurrent;
					}else if(!auditCost && priceAmount > priceTotal){//无审计
						list[index].amount = priceTotal - priceAmountExceptCurrent;
					}
				}
				
			},
			// 工程款付款，付款金额计算（input框失去焦点）
			/**
			 * @param {type} 进度款or结算款or尾款
			 * @param {priceTotal} 合同金额
			 * @param {price} 工程完成量*付款比例
			 * @param {list} 当前数组
			 * @param {index} 当前索引
			 */
			changeProjectPrice(type,priceTotal,price,list,index){
				// console.log(type,priceTotal,price,list,index);
				let priceAdd = 0;//增量金额
				let priceAduit = 0; //审计金额
				let priceAwait = 0;//尾款金额总额
				priceTotal = Number(priceTotal);
				if(this.construction.change?.constructionChangeAddAmount){
					priceAdd = Number(this.construction?.change?.constructionChangeAddAmount);
				}
				if(this.auditPay(this.construction?.payment?.constructionPayment)){
					priceAduit = this.auditPay(this.construction?.payment?.constructionPayment)
				}
				let priceAmount = 0;//所有的付款金额总和
				let priceAmountExceptCurrent = 0;//除了当前的付款金额总和
				list.forEach((item,i)=>{
					if(item.type != 3){
						priceAmount += item.amount;
					}else{
						priceAwait = item.amount;
					}
					if(i != index){
						priceAmountExceptCurrent += item.amount;
					}
				})
				if(priceAdd && type == 0){
					if(list[index].amount > (priceAdd+priceTotal) * 0.3 || list[index].amount > price){
						message.warning(`预付款金额合计不能超过合同金额的30%`)
						if((priceAdd+priceTotal) * 0.3 > price){
							list[index].amount = price;
						}else{
							list[index].amount = (priceAdd+priceTotal) * 0.3;
						}
					}
				}else if(!priceAdd && type == 0){
					if(list[index].amount > priceTotal * 0.3 || list[index].amount > price){
						message.warning(`预付款金额合计不能超过合同金额的30%`)
						if(priceTotal * 0.3 > price){
							list[index].amount = price;
						}else{
							list[index].amount = priceTotal * 0.3;
						}
					}
				}
				// 有增量金额,无审计金额
				if(priceAdd&&!priceAduit){
					// if 预付款or进度款or结算款，最大值==工程完成量*比例
					if(type == 0 || type == 1 || type == 2){
						if(priceAmount > price){
							list[index].amount = price - priceAmountExceptCurrent;
						}
					}else if(type == 3){
						// 选择尾款，付款总金额  合同金额+增量金额
						if(priceAmount > (priceTotal + priceAdd)){
							list[index].amount = priceTotal + priceAdd - priceAmountExceptCurrent;
						}
					}
				}
				// 无增量
				if(!priceAdd&&!priceAduit){
					if(type == 0 || type == 1 || type == 2){
						if(priceAmount > price){
							list[index].amount = price - priceAmountExceptCurrent;
						}
					}else if(type == 3){
						// 选择尾款，付款总金额  合同金额
						if(priceAmount > priceTotal){
							list[index].amount = priceTotal - priceAmountExceptCurrent;
						}
					}
				}
				// 有审计金额
				if(priceAduit){
					// 有增量
					if(type == 0 || type == 1 || type == 2){
						if(priceAmount > price){
							list[index].amount = price - priceAmountExceptCurrent;
						}
					}else if(type == 3){
						// 选择尾款，付款总金额  合同金额 + 增量
						if(priceAwait > priceAduit + priceAdd){
							list[index].amount = priceAduit + priceAdd - priceAmountExceptCurrent;
						}
					}
				}
				
			},
			// 删除当前付款记录
			onDeletePayRecord(item, index) {
				if (item.length == 1) {
					message.warning('至少要有一条付款记录');
					return;
				}
				item.splice(index, 1);
			},
			// 项目施工，已拨付款金额,第二个参数用来判断是否是（项目施工--工程款付款）
			alreadyPay(arr,isflag) {
				let price = 0;
				let flag = this.construction?.schedule?.constructionSchedulePayPercent || 0;
				let hasAduit = false;
				if (arr && arr.length) {
					arr.forEach(item => {
						if(isflag){
							price += (item.amount || 0); 
							if(item?.auditCost){
								hasAduit = true;
							}
						}else{
							price += (item.amount || 0); 
						}
					});
					// 是工程款付款&&有增量金额
					// if(isflag&&this.construction?.change?.constructionChangeAddAmount){
					// 	price += this.construction?.change?.constructionChangeAddAmount;
					// }
				}
				let constructionSchedulePercent = this.construction?.schedule?.constructionSchedulePercent || 0;
				if ((price > flag * constructionSchedulePercent) && !hasAduit) {
					setTimeout(() => {
						message.warning(`付款金额合计不能超过工程完成量的${flag*100}%`)
					},500)
					// return price;
					return `付款金额合计不能超过工程完成量的${flag*100}%`;
				} else {
					return price;
				}
			},
			// 计算全部付款金额 与 合同金额 差值 == 已支付金额
			countPrice(paymentList,constractPrice){
				let price = 0;
				if(paymentList&&paymentList.length){
					paymentList.forEach(item=>{
						price += item.amount || 0;
					})
				}
				if(constractPrice){
					const value = constractPrice - price;
					if(value < 0){//合同金额 < 全部付款金额
						setTimeout(() => {
							message.warning(`付款金额总额不得大于合同金额`);
						},500)
						return `付款金额总额不得大于合同金额`;
					}else{
						return price;
					}
				}
				return price
			},
			// 项目施工 审计金额
			auditPay(arr) {
				let amount = 0
				if (arr && arr.length) {
					arr.forEach(item => {
						if(item.auditCost) amount += item.auditCost
					})
				}
				if(isNaN(amount)) return 0;
				return amount
			},
			// 项目施工 工程付款 待付款金额计算
			constructionToPay() {
				let amount = 0
				let tempAudit = this.auditPay(this.construction.payment.constructionPayment) || 0
				if(tempAudit > 0) {//有审计金额
					let constructionPayment = this.alreadyPay(this.construction.payment.constructionPayment,1) || 0;
					if(this.construction.change.constructionChangeAddAmount){
						tempAudit += Number(this.construction.change.constructionChangeAddAmount);
					}
					amount = tempAudit - constructionPayment
				} else {//无审计金额
					let inviteTendersSuccessPrice = Number(this.inviteTenders?.success?.inviteTendersSuccessPrice) || 0;
					let constructionPayment = this.alreadyPay(this.construction.payment.constructionPayment) || 0;
					if(this.construction.change.constructionChangeAddAmount){
						inviteTendersSuccessPrice += Number(this.construction.change.constructionChangeAddAmount);
					}
					amount = inviteTendersSuccessPrice - constructionPayment;
				}
				if(isNaN(amount)){
					return 0;
				}else{
					return amount;
				}
			},
			// 竣工验收，第三方相关资料，新增费用信息
			onAddFinishInfo() {
				this.completion.other.completionOther.push({
					category: '',
					orderCompanyName: '',
					allPrice: '',
					costName: '',
					employList: [{
						amount: '',
						source: ''
					}]
				})
			},
			// 竣工验收，第三方相关资料，删除费用信息最后一个
			onDeleteFinishInfo(index) {
				if (this.completion.other.completionOther.length == 1) {
					message.warning('至少要有一条费用记录');
					return;
				}
				this.completion.other.completionOther.splice(index, 1);
			},
			// 项目立项，聘请中介，费用计算
			countPaid(name, list, formName, formNameChild, index) {
				let price = 0;
				if (list && list.length) {
					list.forEach(item => {
						price += (item.amount || 0);
					})
				}
				if (isNaN(price)) return 0;
				// this.formApproval[name] = price;
				if (formNameChild) {
					this.countResultassignEmploy(name, price, index);
				} else {
					this.countResultassign(name, formName, price);
				}
				return price;
			},
			countAwaitPrice(formName, formNameChild, formNameChild2, prepaid) {
				const price = this[formName][formNameChild][formNameChild2] - prepaid;
				if (isNaN(price)) return 0;
				if (price < 0) return `付款金额合计不得超过总金额`;
				return price;
			},
			// countAwaitPrice(paidPrice, awaitPrice, allPrice, formName, formNameChild, index) {
			// 	const price = this[formName][allPrice] - this[formName][paidPrice];
			// 	if(isNaN(price)) return 0;
			// 	if(price < 0) return `付款金额合计不得超过总金额`;
			// 	// this.formApproval[awaitPrice] = price;
			// 	this.countResultassign(awaitPrice,formName,price);
			// 	return price;
			// },
			// 计算成功，表单赋值
			countResultassign(name, formName, price) {
				this[formName][name] = price;
			},
			// 计算成功，表单赋值，验收表单
			countResultassignEmploy(name, price, index) {
				this.completion.other.completionOther[index][name] = price;
			},
			countResultassignAwaitEmploy(name, allprice, index) {
				const price = allprice - this.completion.other.completionOther[index].employInfoPaid;
				if (isNaN(price)) return 0;
				if (price < 0) return `付款金额合计不得超过总金额`;
				this.completion.other.completionOther[index][name] = price;
				return price;
			},
			changeDefaultAduit(){
				if (this.inviteTenders.flowable.inviteTendersHasFlowable == 0) {
					this.construction.audit.constructionHasAudit = 1;
					this.isAudit = true;
					this.isUnAudit = false;
					return;
				}
				if (this.inviteTenders.flowable.inviteTendersHasFlowable == 1 || this.inviteTenders.flowable.inviteTendersHasFlowable == 2) {
					this.construction.audit.constructionHasAudit = 0;
					this.isAudit = false;
					this.isUnAudit = true;
					return;
				}
			},
			// 跟踪监理信息，改变radio，项目招标
			changeIsSupervise(e) {
				if (e.target.value == 1) {
					this.inviteTenders.saf.inviteTendersHasSaf = 1;
					this.isConsult = true;
					this.isUnConsult = false;
					return;
				}
				this.inviteTenders.saf.inviteTendersHasSaf = 0;
				this.isConsult = false;
				this.isUnConsult = true;
			},
			// 跟踪审计信息，改变radio，项目招标
			changeIsAduit(e) {
				if (e.target.value == 0) {
					this.construction.audit.constructionHasAudit = 1;
					this.isAudit = true;
					this.isUnAudit = false;
					return;
				}
				if (e.target.value == 1 || e.target.value == 2) {
					this.construction.audit.constructionHasAudit = 0;
					this.isAudit = false;
					this.isUnAudit = true;
					return;
				}
				// this.isAudit = false ;
				// this.isUnAudit = true;
			},
			// 建设用地储备，总金额计算（共用方法）
			countAllPrice(name,parent, price1, price2, price3, price4,) {
				const price =Number(this.formUseLand[parent][price1]) +Number(this.formUseLand[parent][price2]) + Number(this.formUseLand[parent][price3]) +Number(this
					.formUseLand[parent][price4]);
				if (isNaN(price)) return 0;
				this.formUseLand[parent][name] = price;
				return price;
			},
			postMessage(param) {
				let tempArr = param
				let newArr = []
				Object.keys(tempArr).forEach((key) => {
					tempArr[key] = Object.keys(tempArr[key]).map((innerKey) => {
						return {
							pKey: innerKey,
							pValue: tempArr[key][innerKey],
							type: '',
						}
					})
				})
				for (let i in tempArr) {
					if(i == 'speed' && this.current == 2){
						let arrTemp = [];
						tempArr[i].forEach((item)=>{
							let {pValue} = item;
							for(let index in pValue ){
								let obj ={
									pKey: index,
									pValue:pValue[index],
									type: ''
								}
								newArr.push(obj);
							}
						})
					}else{
						newArr.push(...tempArr[i])
					}
				}
				return newArr
			},
			advancePayMax(type) { // 竣工验收 工程付款预付款金额大小限制
				if(type === 0) return Number(this.inviteTenders.success.inviteTendersSuccessPrice)*0.3
			},
			upLoadData() { // 上传文件参数的处理方法
				let tempFileParam = {
					'name': '',
					'projectName': ''
				}
			},
			onInit() { // 做数据回显
				this.formProjectInfo = baseInfoEcho(this.projectInfo)
				this.formProjectInfo.bootTime = this.moment.unix(this.formProjectInfo.bootTime)
				this.formUseLand = buildLandEcho(this.stageInfos)
				if (this.stageInfos) {
					this.formApproval = approvalEcho(this.stageInfos)
					this.inviteTenders = inviteTendersEcho(this.stageInfos)
					this.construction = constructionEcho(this.stageInfos)
					this.completion = completionEcho(this.stageInfos)
				}
			},
			onTypeChange(e, key, index) {
				let type = '';
				if(key === 'buildLandFlagHasStage'){
					type = this.formUseLand.flag.buildLandFlagHasStage;
					this.formUseLand.flag = JSON.parse(JSON.stringify(projectData['formUseLand'].flag));
					this.formUseLand.flag.buildLandFlagHasStage = type;
					if(this.formUseLand.flag.buildLandFlagHasStage!==0){
						this.formUseLand.flag.buildLandFlagNotes = ''
					}
					
				}
				if (key === 'buildLandSourceType') {
					type = this.formUseLand.source.buildLandSourceType;
					this.formUseLand.source = JSON.parse(JSON.stringify(projectData['formUseLand'].source));
					this.formUseLand.source.buildLandSourceType = type;
				}
				if (key === 'buildLandNatureHasForestry') {
					type = this.formUseLand.nature.buildLandNatureHasForestry;
					this.formUseLand.nature = JSON.parse(JSON.stringify(projectData['formUseLand'].nature));
					this.formUseLand.nature.buildLandNatureHasForestry = type;
				}
				if (key === 'buildLandGainType') {
					type = this.formUseLand.gain.buildLandGainType;
					this.formUseLand.gain = JSON.parse(JSON.stringify(projectData['formUseLand'].gain));
					this.formUseLand.gain.buildLandGainType = type;
				}
				if (key === 'buildLandRegisterStatus') {
					type = this.formUseLand.register.buildLandRegisterStatus;
					this.formUseLand.register = JSON.parse(JSON.stringify(projectData['formUseLand'].register));
					this.formUseLand.register.buildLandRegisterStatus = type;
				}
				if (key === 'buildLandSpeedStatus') {
					type = this.formUseLand.speed.buildLandSpeedStatus;
					this.formUseLand.speed = JSON.parse(JSON.stringify(projectData['formUseLand'].speed));
					this.formUseLand.speed.buildLandSpeedStatus = type;
				}
				if (key === 'buildLandCostAuditStatus') {
					type = this.formUseLand.costAudit.buildLandCostAuditStatus;
					this.formUseLand.costAudit = JSON.parse(JSON.stringify(projectData['formUseLand'].costAudit));
					this.formUseLand.costAudit.buildLandCostAuditStatus = type;
				}
				if (key === 'buildLandResultStatus') {
					type = this.formUseLand.result.buildLandResultStatus;
					this.formUseLand.result = JSON.parse(JSON.stringify(projectData['formUseLand'].result));
					this.formUseLand.result.buildLandResultStatus = type;
				}
				if (key === 'projectApprovalReviewStatus') {
					type = this.formApproval.review.projectApprovalReviewStatus;
					this.formApproval.review = JSON.parse(JSON.stringify(projectData['formApproval'].review));
					this.formApproval.review.projectApprovalReviewStatus = type;
				}
				if (key === 'projectApprovalOpinionFile') {
					type = this.formApproval.opinion.projectApprovalOpinionFile;
					this.formApproval.opinion = JSON.parse(JSON.stringify(projectData['formApproval'].opinion));
					this.formApproval.opinion.projectApprovalOpinionFile = type;
				}
				if (key === 'projectApprovalHasFeasibility') {
					type = this.formApproval.feasibility.projectApprovalHasFeasibility;
					this.formApproval.feasibility = JSON.parse(JSON.stringify(projectData['formApproval'].feasibility));
					this.formApproval.feasibility.projectApprovalHasFeasibility = type;
				}
				if (key === 'projectApprovalHasRisk') {
					type = this.formApproval.risk.projectApprovalHasRisk;
					this.formApproval.risk = JSON.parse(JSON.stringify(projectData['formApproval'].risk));
					this.formApproval.risk.projectApprovalHasRisk = type;
				}
				if (key === 'projectApprovalHasDesign') {
					type = this.formApproval.design.projectApprovalHasDesign;
					this.formApproval.design = JSON.parse(JSON.stringify(projectData['formApproval'].design));
					this.formApproval.design.projectApprovalHasDesign = type;
				}
				if (key === 'projectApprovalHasEnv') {
					type = this.formApproval.env.projectApprovalHasEnv;
					this.formApproval.env = JSON.parse(JSON.stringify(projectData['formApproval'].env));
					this.formApproval.env.projectApprovalHasEnv = type;
				}
				if (key === 'projectApprovalHasKeep') {
					type = this.formApproval.keep.projectApprovalHasKeep;
					this.formApproval.keep = JSON.parse(JSON.stringify(projectData['formApproval'].keep));
					this.formApproval.keep.projectApprovalHasKeep = type;
				}
				if (key === 'projectApprovalHasEvaluate') {
					type = this.formApproval.evaluate.projectApprovalHasEvaluate;
					this.formApproval.evaluate = JSON.parse(JSON.stringify(projectData['formApproval'].evaluate));
					this.formApproval.evaluate.projectApprovalHasEvaluate = type;
				}
				if (key === 'projectApprovalHasEc') {
					type = this.formApproval.ec.projectApprovalHasEc;
					this.formApproval.ec = JSON.parse(JSON.stringify(projectData['formApproval'].ec));
					this.formApproval.ec.projectApprovalHasEc = type
				}
				if (key === 'projectApprovalHasFire') {
					type = this.formApproval.fire.projectApprovalHasFire;
					this.formApproval.fire = JSON.parse(JSON.stringify(projectData['formApproval'].fire));
					this.formApproval.fire.projectApprovalHasFire = type;
				}
				if (key === 'projectApprovalHasPad') {
					type = this.formApproval.pad.projectApprovalHasPad;
					this.formApproval.pad = JSON.parse(JSON.stringify(projectData['formApproval'].pad));
					this.formApproval.pad.projectApprovalHasPad = type;
				}
				if (key === 'inviteTendersHasWatcher') {
					type = this.inviteTenders.watcher.inviteTendersHasWatcher;
					this.inviteTenders.watcher = JSON.parse(JSON.stringify(projectData['inviteTenders'].watcher));
					this.inviteTenders.watcher.inviteTendersHasWatcher = type;
				}
				if (key === 'inviteTendersHasFlowable') {
					type = this.inviteTenders.flowable.inviteTendersHasFlowable;
					this.inviteTenders.flowable = JSON.parse(JSON.stringify(projectData['inviteTenders'].flowable));
					this.inviteTenders.flowable.inviteTendersHasFlowable = type;
				}
				if (key === 'inviteTendersHasSaf') {
					type = this.inviteTenders.saf.inviteTendersHasSaf;
					this.inviteTenders.saf = JSON.parse(JSON.stringify(projectData['inviteTenders'].saf));
					this.inviteTenders.saf.inviteTendersHasSaf = type;
				}
				if (key === 'constructionHasPermit') {
					type = this.construction.permit.constructionHasPermit;
					this.construction.permit = JSON.parse(JSON.stringify(projectData['construction'].permit));
					this.construction.permit.constructionHasPermit = type;
				}
				if (key === 'constructionHasChange') {
					type = this.construction.change.constructionHasChange;
					this.construction.change = JSON.parse(JSON.stringify(projectData['construction'].change));
					this.construction.change.constructionHasChange = type;
				}
				if (key === 'constructionHasInquiry') {
					type = this.construction.inquiry.constructionHasInquiry;
					this.construction.inquiry = JSON.parse(JSON.stringify(projectData['construction'].inquiry));
					this.construction.inquiry.constructionHasInquiry = type;
				}
				if (key === 'constructionPayment') {
					type = this.construction.payment.constructionPayment[index].type;
					this.construction.payment.constructionPayment[index] = JSON.parse(JSON.stringify(projectData['construction'].payment.constructionPayment[0]));
					this.construction.payment.constructionPayment[index].type = type;
				}
				if (key === 'constructionHasAudit') {
					type = this.construction.audit.constructionHasAudit;
					this.construction.audit = JSON.parse(JSON.stringify(projectData['construction'].audit));
					this.construction.audit.constructionHasAudit = type;
				}
				if (key === 'completionHasLpi') {
					type = this.completion.lpi.completionHasLpi;
					this.completion.lpi = JSON.parse(JSON.stringify(projectData['completion'].lpi));
					this.completion.lpi.completionHasLpi = type;
				}
				if (key === 'completionHasFire') {
					type = this.completion.fire.completionHasFire;
					this.completion.fire = JSON.parse(JSON.stringify(projectData['completion'].fire));
					this.completion.fire.completionHasFire = type;
				}
				if (key === 'buildLandResultPaymentFile') {
					type = this.formUseLand.result.buildLandResultPaymentFile[index].type;
					this.formUseLand.result.buildLandResultPaymentFile[index] = JSON.parse(JSON.stringify(projectData['formUseLand'].result.buildLandResultPaymentFile[0]));
					this.formUseLand.result.buildLandResultPaymentFile[index].type = type;
				}
				if (key === 'completionHasBuild') {
					type = this.completion.build.completionHasBuild;
					this.completion.build = JSON.parse(JSON.stringify(projectData['completion'].build));
					this.completion.build.completionHasBuild = type;
				}
				if (key === 'completionHasEia') {
					type = this.completion.eia.completionHasEia;
					this.completion.eia = JSON.parse(JSON.stringify(projectData['completion'].eia));
					this.completion.eia.completionHasEia = type;
				}
				if (key === 'completionHasKeep') {
					type = this.completion.keep.completionHasKeep;
					this.completion.keep = JSON.parse(JSON.stringify(projectData['completion'].keep));
					this.completion.keep.completionHasKeep = type;
				}
				if (key = 'completionHasFiling') {
					type = this.completion.filing.completionHasFiling;
					this.completion.filing = JSON.parse(JSON.stringify(projectData['completion'].filing));
					this.completion.filing.completionHasFiling = type;
				}
				if(key = 'completionHasOrder'){
					type = this.completion.other.completionHasOrder;
					this.completion.other = JSON.parse(JSON.stringify(projectData['completion'].other));
					this.completion.other.completionHasOrder = type;
				}
			},
			// 三通一平支出增加费用记录
			/**
			 * @param {price} 合同金额
			 * @param {list} 当前数组
			 * @param {tempObj} 新增对象
			 */
			 onAddPayRecordPing(price,list, tempObj) {
				let flag = true;
				let priceAmount = 0;
				// 判断数组是否都填写的信息，都填了才能新增
				list.forEach(item=>{
					priceAmount += item.cost; 
					if(!item.cost){
						flag = false;
					}
				})  
				// 都填写了金额
				if(flag){
					list.push(tempObj)//为true新增
				}else{
					// 没有填写付款金额，不新增
					message.warning('请填写完成当前付款记录后新增');
				}
			},
			// 建设用地储备，三通一平支出，审计金额
			/**
			 * @param {obj} 要进行审计金额计算的对象
			 */
			auditCost(obj) {
				let price = 0;
				obj.payment.forEach(item => {
					if (item.auditCost) price += item.auditCost;
				})
				if (isNaN(price)) return 0;
				return price;
			},
			// 建设用地储备，三通一平支出，已拨付款金额
			/**
			 * @param {obj} 要进行已拨付款金额计算的对象
			 */
			paymentedPrice(obj) {
				let price = 0;
				let flag = obj.payPercent;
				let hasArrears = false;//是否有尾款
				obj.payment.forEach(item => {
					if(item.type == 2){//有尾款
						hasArrears = true;
					}
					if(item.amount){
						price += item.amount;
					}
				});
				// console.log(price, flag, this.formUseLand.speed.buildLandSpeedCompletedPercent)
				if ((price > flag * obj.completedPercent && obj?.completedPercent)&&!hasArrears) {
					// message.warning(`付款金额合计不能超过工程完成量的${flag*100}%`)
					return `付款金额合计不能超过工程完成量的${flag*100}%`;
				} else {
					return price || 0;
				}
			},
			// 建设用地储备，三通一平支出，待拨付款金额
			/**
			 * @param {obj} 要进行已拨付款金额计算的对象
			 */
			paymentedAwaitPrice(obj) {
				// 判断有无结算款
				let flag = false;
				let flagIndex = -1;
				let auditCost = 0;
				obj.payment.forEach((item,index) =>{
					// 有结算款 && 有审计金额
					if(item.type == 1 && item.auditCost){
						flag = true;
						flagIndex = index;
						auditCost = this.auditCost(obj);
					}
				})
				let amount = 0;
				obj.payment.forEach((item)=>{
					if(item.amount){
						amount += item.amount;
					}
				})
				if(flag && flagIndex != -1){
					// 计算进度款的付款金额
					// const res = this.formUseLand.speed.buildLandSpeedPaymentFile[flagIndex].auditCost - amount;
					const res = auditCost - amount;
					if (isNaN(res)) return 0;
					return res;
				}else{
					// const res = this.formUseLand.speed.buildLandSpeedTotalCost - this.paymentedPrice;
					// 没有审计，合同金额-全部
					const res = obj.cost - amount;
					if (isNaN(res)) return 0;
					return res;
				}
					
				return 0;
			},
			// 计算全部审计金额的钱
			countAuditPrice(){
				let allAuditPrice = 0;
				this.formUseLand.speed?.buildLandSpeed.forEach((item)=>{
					item.payment.forEach((i)=>{
						if(i.auditCost){
							allAuditPrice += i.auditCost;
						}
					})
				})	
				this.formUseLand.speed.auditCost = allAuditPrice;
			},
			/**
			 * @param {item} Object
			 * 计算单个三通一平的审计金额
			 */
			countOnesAuditPrice(item){
				let auditAllCost = 0;
				item.payment.forEach(element => {
					if(element.type == 1 && element.auditCost){
						auditAllCost += element.auditCost;
					}
				});
				return auditAllCost;
			} ,
			countContractMax(price){
				console.log(price);
			}
		}
	}
</script>

<style scoped lang="less">
	.steps-content {
		margin-top: 16px;
		/* border: 1px dashed #e9e9e9; */
		border-radius: 6px;
		// background-color: #fafafa;
		min-height: 200px;
		text-align: center;
		padding-top: 20px;
		padding-right: 100px;
		text-align: left;
	}

	.steps-action {
		margin-top: 24px;
		display: flex;
		justify-content: center;
	}

	.ui-styleBox {
		border: 1px solid #e9e9e9;
		border-radius: 20px;
		padding: 10px;
		margin-top: 10px;

		.ui-styleBox-title {
			font-size: 16px;
			font-weight: 400;
		}
		.ui-styleBox-title1{
			margin-top: 30px;
		}
	}

	.ui-contentBorderBox {
		display: flex;
		align-items: center
	}

	.ui-contentBorder {
		// width: 60%;
		border: 1px solid #e9e9e9;
		border-radius: 20px;
		background: #f0f8ff;
		padding: 10px;
		margin-top: 10px;
		margin-right: 10px;
	}

	.ui-contentBorderMini {
		width: 60%;
		border: 1px solid #e9e9e9;
		border-radius: 20px;
		background: #f0f8ff;
		padding: 10px;
		margin-top: 10px;
		margin-right: 10px;
	}

	.ui-contentWhiteBorder {
		width: 80%;
		border: 1px solid #e9e9e9;
		border-radius: 20px;
		background: #ffffff;
		padding: 10px;
		margin-top: 10px;
		margin-right: 10px;
	}

	.ui-contentMarginTop {
		margin-top: 10px;
		font-weight: 600;
		font-size: 18px;
	}

	.ui-employBorder {
		border: 1px solid #e9e9e9;
		padding: 10px;
		border-radius: 20px;
		background-color: #e9e9e9;
		min-width: 600px;
		/* max-width: 1500px; */
	}

	.ui-title {
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}

	.ui-childBorderStyle {
		border: 1px solid #e9e9e9;
		border-radius: 20px;
		background-color: #f0f8ff;
		padding: 10px;
	}

	.ui-SerChildBorderStyle {
		border: 1px solid #e9e9e9;
		border-radius: 20px;
		// background-color: #f0f8ff;
		padding: 10px;
		display: flex;
		align-items: center;
		// margin-left: 20px;
	}

	.ui-SerChildStyleMini {
		width: 100%;
		border: 1px solid #e9e9e9;
		border-radius: 20px;
		background-color: #f0f8ff;
		padding: 5px;
	}

	.ui-cutApart {
		height: 20px;
	}

	.ui-inputNumWidth {
		width: 150px;
	}

	.ui-stepsTip {
		//    position: fixed;
		// right: 0;
		// display: flex;
		//    flex-direction: row-reverse;
		//    align-items: center;
		// text-align:right;
		margin-top: 10px;
		// p{
		//     padding: 0;
		//     margin: 0;
		//     margin-left: 20px;
		// }
	}

	.ui-previewBaseInfo {
		font-size: 20px;
		font-weight: 600;
		margin-left: 10px;
	}

	.ui-fileStyle {
		display: flex;
		flex-wrap: wrap;

		a {
			min-width: 400px;
			color: #1D1E1E;
			font-size: 20px;
			padding: 10px;
		}
	}

	.ui-formUseLandPay-line {
		display: flex;

		.ui-formUseLandPay-price {
			width: 650px;
			margin-left: 50px;

			span {
				padding: 0 20px;
				line-height: 30px;
			}
		}

		.ui-formUseLandPay-percentage {
			width: 250px;
			margin-left: 30px;

			span {
				padding: 0 20px;
				line-height: 30px;
			}
		}
	}

	.ui-icon-style {
		margin-right: 5px !important;
		line-height: 35px !important;
	}

	.ui-tipsStyle {
		color: red;
		// line-height: 10px;
	}

	.ui-stepStyle {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
		width: 100%;
		padding-right: 20%;
		// position:fixed;
		// z-index:999;
		// top:11.5%;
		// margin-top: 10px;
		// background-color: #fff;
	}

	.ui-headerStyle {
		position: fixed;
		z-index: 999;
		top: 113px; // 
		left:230px;
		right:50px;
		// width: 100%;
		margin-top: 10px;
		background-color: #fff;
	}

	.ui-height1 {
		height: 170px;
	}

	.ui-height2 {
		height: 70px;
	}

	.ui-icon-plusStyle {
		font-size: 32px;
		// width: 48px;
		// height: 48px;
	}

	.ui-plusIconBox {
		display: flex;
		align-items: center;
	}

	.ui-moduleBox {
		margin-bottom: 10px;
		padding: 10px;
	}

	.ui-loaddownBtn {
		width: 85%;
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		bottom: 0;
		z-index: 999;
	}

	.ui-group{
		width: 100%;
		display: flex;
	}

	.ui-inputLength{
		width: 250px;
	}
	// .ant-steps-item-icon {
	//  box-sizing: content-box;
	// }	 
</style>
