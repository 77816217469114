<template>
	<div class="ui-fileUpload-box">
		<a-upload :accept="accept" action="/admin/projectFileUpload.do" name="file" :headers="headers"
			v-model:file-list="fileList"
			:showUploadList="false"
			:disabled="disabled"
			:data="{ name: uploadName }"
			:multiple="multiple" :max-count="limit" @preview="handlePreview"
			:beforeUpload="beforeUpload" @change="uploadChange">
			<a-button>
				<template #icon>
					<Icon icon="UploadOutlined" />
				</template>
				文件上传
			</a-button>
		</a-upload>
		<!-- style="height: 0;line-height: 0;" -->
			<div v-show="fileList.length">
				<a-popover v-model:open="visible" title="文件列表" trigger="click">
					<template #content>
						<div>
							<div style="margin-bottom: 6px;" v-for="(item) in fileList" :key="item.uid">
								<a-spin :spinning="!item.url">
									<a-button type="link" :href="item.url" target="_break">
										{{ item.name }}
									</a-button>
									<Icon v-if="!disabled" icon="DeleteOutlined" @click.stop="handleRemove(item)"></Icon>
								</a-spin>
							</div>
						</div>
					</template>
					<div class="ui-loading">
						<a-button v-show="!loading" type="link" size="small" @click="onPreview">点击查看已上传文件</a-button>
						<a-spin class="ui-spin" :spinning="loading" tip="文件上传中"></a-spin> 
					</div>
					
				</a-popover>
			</div>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	export default {
		components: {
			Icon
		},
		emits: ['update:value'],
		props: {
			value: {},
			limit: { // 允许上传文件数量
				type: Number,
				default: 10
			},
			maxSize: { // 文件大小
				type: Number,
				default: 100
			},
			multiple: { // 是否允许多选
				type: Boolean,
				default: false
			},
			disabled: {
				type: Boolean,
				default: false
			},
			headers: {
				type: Object,
				default: () => {}
			},
			uploadData: {
				type: Object,
				default: () => {}
			},
			accept: {
				type: String,
				default: ''
				// default: 'application/pdf'
			},
			name: {
				type: String,
				default: ''
			},
			index: [Number, String]
		},
		data() {
			return {
				fileList: [],
				fileUrls: [],
				visible: false,
				uploadName: ''
			}
		},
		watch: {
			value: {
				handler(newVal) {
					if (typeof newVal === 'string' && newVal) {
						this.fileUrls = [newVal];
						let newValList = newVal.split(',');
						newValList.forEach((item,index)=>{
							this.fileList.push({
								uid: index,
								url: item,
								name: this.fileNameFun(item)
							})
						})
					} else {
						if (newVal && newVal.length) {
							newVal.forEach((item, index) => {
								if (!this.fileUrls.includes(item)) {
									this.fileUrls.push(item);
									this.fileList.push({
										uid: index,
										url: item,
										name: this.fileNameFun(item)
									})
								}
							})
						} else {
							this.fileUrls = [];
							this.fileList = [];
						}
					}
				},
				deep: true,
				immediate: true,
			},
		},
		computed: {
			loading() {
				let uploading = false;
				this.fileList.forEach(item => {
					if(!item.url) {
						uploading = true;
					}
				})
				return uploading;
			}
		},
		methods: {
			beforeUpload(file, fileList) {
				let type = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
				let thisFileType = type.replace('.', 'application/');
				this.file = file;
				let obj = {};
				let index = -1;
				fileList.forEach((item, i) => {
					if (item.uid === file.uid) {
						index = i;
					}
				});
				if (this.fileList.length) {
					index += this.fileList.length;
				}
				index += 1;
				// this.uploadName = `${this.index ? (this.index > 9 ? this.index : '0'+index ) : ''}${ index > 9 ? index : '0'+index }_${ this.name }_${ file.name }`;
				this.uploadName = `${this.index ? (this.index > 9 ? this.index : '0'+this.index ) : ''}${ index > 9 ? index : '0'+index }_${ this.name }_${ file.name }`;
				obj.name = file.name;
				obj.url = URL.createObjectURL(file);
				this.percent = 0;
				let result = true;
				const fileType = this.accept ? this.accept.split(',') : [];
				if (fileType.length && fileType.indexOf(thisFileType) < 0) {
					this.$message.error('文件格式错误，上传失败');
					result = false;
					obj.error = true;
					return result;
				}
				if (this.maxSize && this.maxSize > 0) {
					const isLimit = file.size / 1024 / 1024 <= this.maxSize;
					if (!isLimit) {
						this.$message.error(`文件大小超过${this.maxSize}MB，上传失败。`);
						result = false;
						this.setFile(result, obj, file);
						return result;
					}
				}
				return result;
			},
			setFile(result, obj, file) {
				if (result) {
					this.imageObj = obj;
					this.fileList = [file];
				} else {
					this.imageObj = {};
				}
			},
			onPreview() {
				// this.visible = true;
			},
			uploadChange(e) {
				let file = e.file;
				if (file.status === 'done' && file.response) {
					if (file.response.code === 200) {
						this.fileList.forEach((item, index) => {
							if (item.uid === file.uid) {
								item.url = file.response.data.pdfUrl;
								item.name = this.fileNameFun(item.url);
							}
						})
						if (this.limit > 1) {
							this.fileUrls.push(file.response.data.pdfUrl);
						} else {
							this.fileUrls = [file.response.data.pdfUrl];
						}
						this.$emit('update:value', this.fileUrls);
					} else {
						this.$message.error(file.response.message);
						this.handleRemove(file);
					}
				}
				if (!file.status) {
					this.handleRemove(file);
				}
			},
			handleRemove(file) {
				let index = -1;
				let url = '';
				this.fileList.forEach((item, i) => {
					if (item.uid === file.uid) {
						index = i;
						url = item.url;
					}
				})
				if (index !== -1) {
					this.fileList.splice(index, 1);
				}
				if (url) {
					this.fileUrls.splice(this.fileUrls.indexOf(url), 1);
				}
				if (this.fileList.length === 0){
					this.visible = false;
				}
				// console.log(file)
				// console.log(this.fileList)
				return true;
			},
			handlePreview(file) {
				if (file.url) {
					window.open(file.url);
				}
			},
			handleCancel() {
				this.previewVisible = false;
			},
			fileNameFun(item) { // 截取文件名
				let preIndex = item.lastIndexOf('/')
				// let nextIndex = item.lastIndexOf('.')
				let urlName = item.slice(preIndex+1)
				return urlName
			},
		}
	}
</script>

<style>
	.ui-fileUpload-box {
		/* min-width: 100px; */
	}
	.ui-fileUpload-box .ant-upload-list-text-container {
		/* height: 0px; */
	}
	.ui-fileUpload-box .ant-upload-list-item {
		/* margin-top: 0px; */
	}
	.ui-spin{
		position: absolute;
		top: -18px;
		display: flex;
	}
</style>