import moment from 'moment';
export function baseInfoEcho(item) { // 项目基本信息回显
	let tempObj = {}
	tempObj = {
		name: item.name,
		mangerUnitId: item.mangerUnitId,
		mangerUserPhone: item.mangerUserPhone,
		classify: JSON.parse(item.classify),
		mangerUsername: item.mangerUsername,
		bootTime: item.bootTime || 0,
		description: item.description || '',
		executeUnitId: item.executeUnitId || '',
		headUsername: item.headUsername || '',
		headUserPhone: item.headUserPhone || '',
		leaderPhone: item.leaderPhone || '',
		leaderUsername: item.leaderUsername || ''
	}
	return tempObj
}
export function buildLandEcho(item) { // 建设用地数据回显
	let tempObj = {}
	tempObj = {
		flag:{
			buildLandFlagHasStage: item? item.buildLandFlagHasStage - 0 : 0,//['0:涉及建设用地', '1:不涉及建设用地', '2:存量建设用地']
			buildLandFlagNotes: item?.buildLandFlagNotes || '' //备注
		},
		source: {
			buildLandSourceType: item ? item.buildLandSourceType - 0 : 0,
			buildLandUniType: item ? item.buildLandUniType - 0 : 0, //	['0:是', '1:否'],
			buildLandSourceTotalCost: item?.buildLandSourceTotalCost || 0,
			buildLandSourceSpecialCost: item?.buildLandSourceSpecialCost || 0,
			buildLandSourceCompanyCost: item?.buildLandSourceCompanyCost || 0, //	'县国资公司资金',
			buildLandSourceSourceCost: item?.buildLandSourceSourceCost || 0, //	'县本级财政资金',
			buildLandSourceOtherCost: item?.buildLandSourceOtherCost || 0, //	'其他来源资金',
			buildLandSourcePlanFile: item?.buildLandSourcePlanFile || [],
			buildLandSourceImageFile: item?.buildLandSourceImageFile || [],
			buildLandSourcePaymentFile: item?.buildLandSourcePaymentFile || [],
			buildLandSourceTransferFile: item?.buildLandSourceTransferFile || [], // 
			buildLandSourceHandoverFile: item?.buildLandSourceHandoverFile || [], //
			buildLandSourceSummaryFile: item?.buildLandSourceSummaryFile || [], //
		},
		nature:{
			buildLandNatureHasForestry: item ? item.buildLandNatureHasForestry - 0 : 0,
			buildLandNatureReportFile: item?.buildLandNatureReportFile || [],
			buildLandNatureAuditFile: item?.buildLandNatureAuditFile || [],
			buildLandNatureAuditTime: item?.buildLandNatureAuditTime || '',
			buildLandNatureMapCost: item?.buildLandNatureMapCost || 0,
			buildLandNatureMapSpecialCost: item?.buildLandNatureMapSpecialCost || 0,
			buildLandNatureMapCompanyCost: item?.buildLandNatureMapCompanyCost || 0,
			buildLandNatureMapSourceCost: item?.buildLandNatureMapSourceCost || 0,
			buildLandNatureMapOtherCost: item?.buildLandNatureMapOtherCost || 0,
			buildLandNatureMapTicket: item?.buildLandNatureMapTicket || [],
			buildLandNatureMapPaymentFile: item?.buildLandNatureMapPaymentFile || [],
			buildLandNatureApprovalCost: item?.buildLandNatureApprovalCost || 0,
			buildLandNatureApprovalSpecialCost: item?.buildLandNatureApprovalSpecialCost || 0,
			buildLandNatureApprovalCompanyCost: item?.buildLandNatureApprovalCompanyCost || 0,
			buildLandNatureApprovalSourceCost: item?.buildLandNatureApprovalSourceCost || 0,
			buildLandNatureApprovalOtherCost: item?.buildLandNatureApprovalOtherCost || 0,
			buildLandNatureApprovalTicket: item?.buildLandNatureApprovalTicket || [],
			buildLandNatureApprovalPaymentFile: item?.buildLandNatureApprovalPaymentFile || []
		},
		approval: {
			// buildLandApprovalCostSource: item?.buildLandApprovalCostSource - 0 || 0,
			buildLandApprovalSpecialCost: item?.buildLandApprovalSpecialCost || 0, //'土地报批上级专项资金'
			buildLandApprovalCompanyCost: item?.buildLandApprovalCompanyCost || 0, //'土地报批县国资公司资金',
			buildLandApprovalSourceCost: item?.buildLandApprovalSourceCost || 0, //'土地报批县本级财政资金',
			buildLandApprovalOtherCost: item?.buildLandApprovalOtherCost || 0,  //'土地报批其他来源资金',
			buildLandApprovalCost: item?.buildLandApprovalCost || null, //	'报批费用金额',
			buildLandApprovalTicket: item?.buildLandApprovalTicket || [], // 发票
			buildLandApprovalPaymentFile: item?.buildLandApprovalPaymentFile || [] // 支付凭证
		},
		gain: { // 取得方式
			buildLandGainType: item?.buildLandGainType - 0 || 0,
			buildLandGainTotalCost: item?.buildLandGainTotalCost || 0, //	'转让总金额',
			buildLandGainSpecialCost: item?.buildLandGainSpecialCost || 0, //	'上级专项资金',
			buildLandGainCompanyCost: item?.buildLandGainCompanyCost || 0, //	'县国资公司资金',
			buildLandGainSourceCost: item?.buildLandGainSourceCost || 0, //	'县本级财政资金',
			buildLandGainOtherCost: item?.buildLandGainOtherCost || 0, //	'其他来源资金',
			buildLandGainConfirmFile: item?.buildLandGainConfirmFile || [], // 土地成交确认书
			buildLandGainContractFile: item?.buildLandGainContractFile || [], // 土地出让/转让合同,
			buildLandGainChangeFile: item?.buildLandGainChangeFile || [], // '国有建设用地使用权划拨决定书'
			buildLandGainTicketFile: item?.buildLandGainTicketFile || [], // '发票URL',
			buildLandGainPaymentFile: item?.buildLandGainPaymentFile || [], //  '支付凭证URL',
			// fileList: []
		},
		register: { //  前置办理不动产登记证
			buildLandRegisterStatus: item?.buildLandRegisterStatus - 0 || 0,
			buildLandRegisterTotalCost: item?.buildLandRegisterTotalCost || 0, //	'办证费用总金额',
			buildLandRegisterSpecialCost: item?.buildLandRegisterSpecialCost || 0, //	'上级专项资金',
			buildLandRegisterCompanyCost: item?.buildLandRegisterCompanyCost || 0, //	'县国资公司资金',
			buildLandRegisterSourceCost: item?.buildLandRegisterSourceCost || 0, //	'县本级财政资金',
			buildLandRegisterOtherCost: item?.buildLandRegisterOtherCost || 0, //	'其他来源资金',
			buildLandRegisterPermitFile: item?.buildLandRegisterPermitFile || [], // '用地规划许可证'
			buildLandRegisterRegisterFile: item?.buildLandRegisterRegisterFile || [], // '不动产登记证',
			buildLandRegisterTicketFile: item?.buildLandRegisterTicketFile || [], // '发票URL'
			buildLandRegisterPaymentFile: item?.buildLandRegisterPaymentFile || [], // '支付凭证URL'
			buildLandRegisterPermitTime: item?.buildLandRegisterPermitTime || '',//用地规划许可证办证时间
			buildLandRegisterRegisterTime: item?.buildLandRegisterRegisterTime || ''//不动产登记时间
			// fileList: []
		},
		speed: { //  三通一平支出
			buildLandSpeedStatus: (item?.buildLandSpeedStatus && Number(item?.buildLandSpeedStatus)) || 0,
			// buildLandSpeedUnitName: item?.buildLandSpeedUnitName || '',
			// buildLandSpeedTotalCost: item?.buildLandSpeedTotalCost || null, //	'合同金额',
			// buildLandSpeedCompletedPercent: item?.buildLandSpeedCompletedPercent || null, //	'工程完成量',
			// buildLandSpeedPayPercent: item?.buildLandSpeedPayPercent ? Number(item?.buildLandSpeedPayPercent) : 0.7, //	'项目办待定付款比例',
			// buildLandSpeedContractFile: item?.buildLandSpeedContractFile || [], // '施工合同'
			// buildLandSpeedNoticeFile: item?.buildLandSpeedNoticeFile || [], // '中标通知书',
			// buildLandSpeedSummaryFile: item?.buildLandSpeedSummaryFile || [], // '会议纪要或文件'	
			// paymentPercentage: item?.paymentPercentage || null, // '工程进度百分比'
			buildLandSpeed:(item && item.buildLandSpeed && JSON.parse(item.buildLandSpeed).map(item => {
				item.isDisabled = true;
				item.payPercent = item.payPercent ? Number(item.payPercent) : 0.7;
				if(item.payment && item.payment.length){
					item.payment.map(paymentItem=>{
						paymentItem.isDisabled = true;
						return paymentItem;
					})
				}
				return item;
			})) || [ {
				// status: 0,//['0:有支出','1:无支出','2:不需要'],
				unitName: '',//单位名称
				notice: [],//中标通知书
				contract: [],//施工合同
				summary: [],//会议纪要文件
				cost: null,//合同金额
				completedPercent: null,//工程完工量
				payPercent: 0.7,//付款比例
				paymentPercentage: '',//完工进度
				payment: [
					{
						type: 0,//['0.进度款记录' , '1.结算款记录', '2.尾款记录'],
						amount: null,//'付款金额',
						source: null,//['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
						scheduleFile:[],// '工程进度报表',
						applyFile:[],// '请款审批表',
						ticket: [],//'发票',
						voucher: [],//'支付凭证',
						auditCost: 0,//'审计金额',
						auditReport: [],//'审计报告'
					}
				]
		}],



			// buildLandSpeedPaymentFile: (item && item.buildLandSpeedPaymentFile && JSON.parse(item.buildLandSpeedPaymentFile).map(item => {item.isDisabled = true; return item;})) || [{amount: null,
			// 	type: 0,
			// 	auditCost: 0, //	'审计金额',
			// 	source: null,
			// 	voucher: [], //	'支付凭证',
			// 	applyFile: [], //	'请款审批表',
			// 	scheduleFile: [], // '工程进度报表',
			// 	ticket: [], //'发票',
			// 	auditReport: [], // '审计报告'
			// }],
			// buildLandSpeedPaymentFile: [{
			// 	amount: null,
			// 	type: 0,
			// 	auditCost: 0, //	'审计金额',
			// 	source: null,
			// 	voucher: [], //	'支付凭证',
			// 	applyFile: [], //	'请款审批表',
			// 	scheduleFile: [], // '工程进度报表',
			// 	ticket: [], //'发票',
			// 	auditReport: [], // '审计报告'
			// }],
		},
		costAudit: { // 三通一平审计
			buildLandCostAuditStatus: item?.buildLandCostAuditStatus ? Number(item.buildLandCostAuditStatus) : 0,
			buildLandCostAuditCompanyName: item?.buildLandCostAuditCompanyName || '',
			buildLandCostAuditTotalCost: item?.buildLandCostAuditTotalCost || null, //	'合同金额',
			buildLandCostAuditContractFile: item?.buildLandCostAuditContractFile || [], //'施工合同',
			buildLandCostAuditNoticeFile: item?.buildLandCostAuditNoticeFile || [], // '中标通知书/成交确认书',
			buildLandCostAuditSummaryFile: item?.buildLandCostAuditSummaryFile || [], // '会议纪要或文件',
			buildLandCostAuditPaymentFile: (item && item.buildLandCostAuditPaymentFile && JSON.parse(item.buildLandCostAuditPaymentFile).map(item => {item.isDisabled = true; return item;})) || [{amount:null,
					source:null,
					voucher: [],	// '支付凭证',
					applyFile: [],	// '请款审批表',
					ticket: [],	//	'发票',
				}],
			// buildLandCostAuditPaymentFile:[{
			//     amount:null,
			//     source:null,
			// 	voucher: [],	// '支付凭证',
			// 	applyFile: [],	// '请款审批表',
			// 	ticket: [],	//	'发票',
			// 	// fileList: []
			// }]
		},
		result: { // 污染状况调查
			buildLandResultUnitName: item?.buildLandResultUnitName || '',
			buildLandResultStatus: item?.buildLandResultStatus - 0 || 0,
			buildLandResultTotalCost: item?.buildLandResultTotalCost || null, //	'合同金额',
			buildLandResultContractFile: item?.buildLandResultContractFile || [], // '施工合同',
			buildLandResultNoticeFile: item?.buildLandResultNoticeFile || [], //	'中标通知书',
			buildLandResultSummaryFile: item?.buildLandResultSummaryFile || [], //	'会议纪要或文件',
			buildLandResultSurveyFile: item?.buildLandResultSurveyFile || [], // '调查成功文件',
			buildLandResultPaymentFile: (item && item.buildLandResultPaymentFile) ? JSON.parse(item.buildLandResultPaymentFile).map(item => {item.isDisabled = true; return item;}) : [{
				amount:null,
				source:null,
				type: 0,
				applyFile: [],	// '请款审批表',
				ticket: [],	// '发票',
				voucher: [],	// '支付凭证',
			}],
			// buildLandResultPaymentFile:[{
			//     amount:null,
			//     source:null,
			// 	type: 0,
			// 	applyFile: [],	// '请款审批表',
			// 	ticket: [],	// '发票',
			// 	voucher: [],	// '支付凭证',
			// }]
		},

	}
	return tempObj
}
export function approvalEcho(item) {
	// console.log(JSON.parse(item?.projectApprovalSpeedFeasibilityPayment));
	let tempObj = {}
	tempObj = {
		important: {
			projectApprovalIsImportant: item.projectApprovalIsImportant ? Number(item.projectApprovalIsImportant) : 1,
			projectApprovalIsPlan: item.projectApprovalIsPlan ? Number(item.projectApprovalIsPlan) : 0
		},
		//  2.资金来源
		source: {
			projectApprovalSourceSpecialCost: item.projectApprovalSourceSpecialCost ? Number(item.projectApprovalSourceSpecialCost) : 0, //	'上级专项资金',
			projectApprovalSourceFileNum: item.projectApprovalSourceFileNum || '', //	'指标文件号',
			projectApprovalSourceCompanyCost: item.projectApprovalSourceCompanyCost ? Number(item.projectApprovalSourceCompanyCost) : 0, //	'县国资公司资金',
			projectApprovalSourceSourceCost: item.projectApprovalSourceSourceCost ? Number(item.projectApprovalSourceSourceCost) : 0, //	'县本级财政资金',
			projectApprovalSourceOtherCost: item.projectApprovalSourceOtherCost ? Number(item.projectApprovalSourceOtherCost) : 0, //	'其他来源资金',
			// projectApprovalSourceGovInvestPlan: item.projectApprovalSourceGovInvestPlan || [], //	'政府投资计划文件',
			projectApprovalSourceSpecialFile: item.projectApprovalSourceSpecialFile || [], //	'专项资金文件',
			projectApprovalSourceSummaryFile: item.projectApprovalSourceSummaryFile || [], //	'县政府常务会纪要文件',
			projectApprovalSourceCountyFile: item.projectApprovalSourceCountyFile || [], //	'县领导审批文件',
			projectApprovalSourceOtherFile: item.projectApprovalSourceOtherFile || [] //	'其他审批文件'
		},
		review: { //  3.项目建议书批复文件
			projectApprovalReviewStatus: item.projectApprovalReviewStatus ? Number(item.projectApprovalReviewStatus) : 0,
			projectApprovalReviewTotalCost: item.projectApprovalReviewTotalCost || null, //	'批复概算金额',
			projectApprovalReviewReviewFile: item.projectApprovalReviewReviewFile || [], //	'立项批复文件',
			projectApprovalReviewReviewBook: item.projectApprovalReviewReviewBook || [], //	'项目建议书',
			projectApprovalReviewCode: item.projectApprovalReviewCode || '', //项目代码
		},
		//  4.项目选址意见书
		opinion: {
			projectApprovalOpinionFlag: item.projectApprovalOpinionFlag ? Number(item.projectApprovalOpinionFlag) : 0, //是否需要用地选址意见书，0需要，1不需要
			projectApprovalOpinionFile: item.projectApprovalOpinionFile || [], //	'用地选址意见书'
			projectApprovalOpinionTime: item?.projectApprovalOpinionTime || '', //用地选址意见书办证时间
		},
		//  5.科研批复
		feasibility: {
			projectApprovalHasFeasibility: item.projectApprovalHasFeasibility ? Number(item.projectApprovalHasFeasibility) : 0, //	['0：有可研','1：无可研', '1：不需要',],
			projectApprovalFeasibilityFile: item.projectApprovalFeasibilityFile || [], //	'可研批复文件' 
			projectApprovalFeasibilityTime: item.projectApprovalFeasibilityTime || '', //可研批复文件办证时间
		},
		//  6.用地许可证、工程规划许可证
		permit: {
			projectApprovalPermitLandFile: item.projectApprovalPermitLandFile || [], //	'用地许可证',
			projectApprovalPermitProjectFIle: item.projectApprovalPermitProjectFIle || [], //	'工程规划许可证'
			projectApprovalPermitProjectTime: item.projectApprovalPermitProjectTime || '',//工程规划许可证办证时间
			projectApprovalPermitLandTime:item.projectApprovalPermitLandTime || '',//'用地规划许可证时间'
		},
		//  7.社会最风险评估
		risk: {
			projectApprovalHasRisk: item.projectApprovalHasRisk ? Number(item.projectApprovalHasRisk) : 0, //	['0：有备案表','1：无备案表','1：不需要',],
			projectApprovalRiskFile: item.projectApprovalRiskFile || [], //	'社会风险稳定评估备案表'
			projectApprovalRiskTime: item.projectApprovalRiskTime || '',//社会风险稳定评估备案时间
		},
		//  8.初步设计批复
		design: {
			projectApprovalHasDesign: item.projectApprovalHasDesign ? Number(item.projectApprovalHasDesign) : 0, //	['0：有初设批复','1：无初设批复','1：不需要',],
			projectApprovalDesignFile: item.projectApprovalDesignFile || [], //	'初步设计批复'
			projectApprovalDesignTime: item.projectApprovalDesignTime || '',//初步设计批复时间
		},
		//  9.环境影响评估
		env: {
			projectApprovalHasEnv: item.projectApprovalHasEnv ? Number(item.projectApprovalHasEnv) : 0, //	['0：有环评','1：无环评','1：不需要',],
			projectApprovalEnvApprovalFile: item.projectApprovalEnvApprovalFile || [], //	'环评批复文件',
			projectApprovalEnvRecordFile: item.projectApprovalEnvRecordFile || [], //	'环评备案文件'
			projectApprovalEnvApprovalTime: item.projectApprovalEnvApprovalTime || '',//'环评批复时间',
		},
		//  10.水土保持方案
		keep: {
			projectApprovalHasKeep: item.projectApprovalHasKeep ?Number(item.projectApprovalHasKeep) : 0, //	['0：有水保方案','1：无水保方案','1：不需要',],
			projectApprovalKeepFile: item.projectApprovalKeepFile || [], //	'水保方案'
		},
		//  11.防洪影响评价
		evaluate: {
			projectApprovalHasEvaluate: item.projectApprovalHasEvaluate ? Number(item.projectApprovalHasEvaluate) : 0, //	['0：有防洪评价','1：无防洪评价','1：不需要',],
			projectApprovalEvaluateFile: item.projectApprovalEvaluateFile || [], //	'防洪评价文件',
		},
		//  12.节能审批
		ec: {
			projectApprovalHasEc: item.projectApprovalHasEc ? Number(item.projectApprovalHasEc) : 0, //	['0：有节能审批','1：无节能审批','1：不需要',],
			projectApprovalEcFIle: item.projectApprovalEcFIle || [], //	'节能审批文件'
			projectApprovalEcTime: item.projectApprovalEcTime || '',//节能审批文件办证时间
		},
		//  13.消防审批
		fire: {
			projectApprovalHasFire: item.projectApprovalHasFire ? Number(item.projectApprovalHasFire) : 0, //	['0：有消防审批','1：无消防审批','1：不需要',],
			projectApprovalFireFile: item.projectApprovalFireFile || [], //	'消防审批意见文书' ？？
			projectApprovalFireTime: item.projectApprovalFireTime || '',//消防审批意见办证时间
		},
		//  14.人防审批
		pad: {
			projectApprovalHasPad: item.projectApprovalHasPad ? Number(item.projectApprovalHasPad) : 0, //	['0：有人防审批','1：无人防审批','1：不需要',],
			projectApprovalPadFile: item.projectApprovalPadFile || [], //	'人防审批意见文书' ？？
			projectApprovalPadTime: item.projectApprovalPadTime || '',//人防审批意见办证时间
		},
		//  15.预算评审
		budget: {
			projectApprovalBudgetReport: item.projectApprovalBudgetReport || [], //	'预算评审报告'
		},
		//  16.班子会会议纪要
		meeting: {
			projectApprovalMeetingSelected: (item && item.projectApprovalMeetingSelected && item.projectApprovalMeetingSelected.split(',').map(item => Number(item))) || [], //	['0：项目立项','1：聘请可研编制','2：聘请施工图设计','3：聘请测绘','4：聘请地勘','5：聘请预算编制','6：聘请招标代理公司','7：其他',],
			projectApprovalMeetingSummary: item.projectApprovalMeetingSummary || [], //	'会议纪要'
			projectApprovalMeetingContent: item.projectApprovalMeetingContent || ''
		},
		//  17.本阶段聘请中介的中标通知书(或电子卖场或中介超市成交通知书) 、合同、费用支付情况
		speed: {
			//  17-1.可研编制
			feasibility: {
				projectApprovalSpeedFeasibilityNotice:  item.projectApprovalSpeedFeasibilityNotice || [], //	'中标通知书或成交确认书',
				projectApprovalSpeedFeasibilityContract: item.projectApprovalSpeedFeasibilityContract|| [], //	'合同',
				projectApprovalSpeedFeasibilityCost: item.projectApprovalSpeedFeasibilityCost || null, //	'合同金额',
				projectApprovalSpeedFeasibilityPayment: item.projectApprovalSpeedFeasibilityPayment ? JSON.parse(item?.projectApprovalSpeedFeasibilityPayment).map((item) => {item.isDisabled = true;return item;}) : [{
				        amount: null, //	'支付金额',
				        source: null, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
				        ticket: [], //	'发票',
				        voucher: [], //	'支付凭证'
				    }],
				// projectApprovalSpeedFeasibilityPayment: [
				//     {
				//         amount: null, //	'支付金额',
				//         source: null, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
				//         ticket: [], //	'发票',
				//         voucher: [], //	'支付凭证'
				//     },
				// ]
			},
			//  17-2.施工图设计
			designer: {
				projectApprovalSpeedDesignerNotice: item.projectApprovalSpeedDesignerNotice || [], //	'中标通知书或成交确认书',
				projectApprovalSpeedDesignerContract: item.projectApprovalSpeedDesignerContract || [], //	'合同',
				projectApprovalSpeedDesignerCost: item.projectApprovalSpeedDesignerCost || null, //	'合同金额',
				projectApprovalSpeedDesignerPayment: item.projectApprovalSpeedDesignerPayment ? JSON.parse(item.projectApprovalSpeedDesignerPayment).map(item => {item.isDisabled = true; return item;}) : [{
				        amount: null, //	'支付金额',
				        source: null, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
				        ticket: [], //	'发票',
				        voucher:  [], //'支付凭证'
				    }],
				// projectApprovalSpeedDesignerPayment: [
				//     {
				//         amount: null, //	'支付金额',
				//         source: null, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
				//         ticket: [], //	'发票',
				//         voucher:  [], //'支付凭证'
				//     }
				// ]
			},
			// //  17-3.图审
			checker: {
				projectApprovalSpeedCheckerNotice: item.projectApprovalSpeedCheckerNotice || [], //	'中标通知书或成交确认书',
				projectApprovalSpeedCheckerContract: item.projectApprovalSpeedCheckerContract || [], //	'合同',
				projectApprovalSpeedCheckerCost: item.projectApprovalSpeedCheckerCost || null, //	'合同金额',
				projectApprovalSpeedCheckerPayment: item.projectApprovalSpeedDesignerPayment ? JSON.parse(item.projectApprovalSpeedCheckerPayment).map(item => {item.isDisabled = true; return item;}) : [{
					amount: 0, //	'支付金额',
					source: undefined, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
					ticket: [], //	'发票',
					voucher: [], //	'支付凭证'
				}]
				// projectApprovalSpeedCheckerPayment: [
				//     {
				//         amount: null, //	'支付金额',
				//         source: null, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
				//         ticket: [], //	'发票',
				//         voucher: [], //	'支付凭证'
				//     }
				// ]
			},
			// //  17-4.绘测
			surveyor: {
				projectApprovalSpeedSurveyorNotice: item.projectApprovalSpeedSurveyorNotice || [], //	'中标通知书或成交确认书',
				projectApprovalSpeedSurveyorContract: item.projectApprovalSpeedSurveyorContract || [], //	'合同',
				projectApprovalSpeedSurveyorCost: item.projectApprovalSpeedSurveyorCost || null, //	'合同金额',
				projectApprovalSpeedSurveyorPayment: item.projectApprovalSpeedDesignerPayment ? JSON.parse(item.projectApprovalSpeedSurveyorPayment).map(item => {item.isDisabled = true; return item;}) : [{
				        amount: null, //	'支付金额',
				        source: null, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
				        ticket: [], //	'发票',
				        voucher: [], //	'支付凭证'
				    }],
				// projectApprovalSpeedSurveyorPayment: [
				//     {
				//         amount: null, //	'支付金额',
				//         source: null, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
				//         ticket: [], //	'发票',
				//         voucher: [], //	'支付凭证'
				//     }
				// ]
			},
			// //  17-5.地勘
			geological: {
				projectApprovalSpeedGeologicalNotice: item.projectApprovalSpeedGeologicalNotice || [], //	'中标通知书或成交确认书',
				projectApprovalSpeedGeologicalContract: item.projectApprovalSpeedGeologicalContract || [], //	'合同',
				projectApprovalSpeedGeologicalCost: item.projectApprovalSpeedGeologicalCost || null, //	'合同金额',
				projectApprovalSpeedGeologicalPayment: item.projectApprovalSpeedGeologicalPayment ? JSON.parse(item.projectApprovalSpeedGeologicalPayment).map(item => {item.isDisabled = true; return item;}) : [{
				              amount: 0, //	'支付金额',
				              source: undefined, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
				              ticket: [], //	 '发票',
				              voucher: [], //	'支付凭证'
				          }],
				// projectApprovalSpeedGeologicalPayment: [
				//           {
				//               amount: null, //	'支付金额',
				//               source: null, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
				//               ticket: [], //	 '发票',
				//               voucher: [], //	'支付凭证'
				//           }
				//       ]
			},
			// // 17-6.预算编制
			budgeting: {
				projectApprovalSpeedBudgetingNotice: item.projectApprovalSpeedBudgetingNotice || [], //	'中标通知书或成交确认书',
				projectApprovalSpeedBudgetingContract: item.projectApprovalSpeedBudgetingContract || [], //	'合同',
				projectApprovalSpeedBudgetingCost: item.projectApprovalSpeedBudgetingCost || null, //	'合同金额',
				projectApprovalSpeedBudgetingPayment: item.projectApprovalSpeedBudgetingPayment ? JSON.parse(item.projectApprovalSpeedBudgetingPayment).map(item => {item.isDisabled = true; return item}) : [{
				              amount: null, //	'支付金额',
				              source: null, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
				              ticket: [], //	'发票',
				              voucher: [], //	'支付凭证'
				          }],
				// projectApprovalSpeedBudgetingPayment: [
				//           {
				//               amount: null, //	'支付金额',
				//               source: null, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
				//               ticket: [], //	'发票',
				//               voucher: [], //	'支付凭证'
				//           }
				//       ]
			},
			// //  17-7.预算评审
			budget: {
				projectApprovalSpeedBudgetNotice: (item.projectApprovalSpeedBudgetNotice) || [], //	'中标通知书或成交确认书',
				projectApprovalSpeedBudgetContract: (item.projectApprovalSpeedBudgetContract) || [], //	'合同',
				projectApprovalSpeedBudgetCost: (item.projectApprovalSpeedBudgetCost) || null, //	'合同金额',
				projectApprovalSpeedBudgetPayment: item.projectApprovalSpeedBudgetPayment ? JSON.parse(item.projectApprovalSpeedBudgetPayment).map(item => {item.isDisabled = true; return item}) : [{
				        amount: null, //	'支付金额',
				        source: null, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
				        ticket: [], //	'发票',
				        voucher: [], //	'支付凭证'
				    }],
				// projectApprovalSpeedBudgetPayment: [
				//     {
				//         amount: null, //	'支付金额',
				//         source: null, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
				//         ticket: [], //	'发票',
				//         voucher: [], //	'支付凭证'
				//     }
				// ]
			},
			// //  17-8.招标代理
			agent: {
				projectApprovalSpeedAgentNotice: (item.projectApprovalSpeedAgentNotice) || [], //	'中标通知书或成交确认书',
				projectApprovalSpeedAgentContract: (item.projectApprovalSpeedAgentContract) || [], //	''合同',
				projectApprovalSpeedAgentCost: (item.projectApprovalSpeedAgentCost) || null, //	''合同金额',
				projectApprovalSpeedAgentPayment: item.projectApprovalSpeedAgentPayment ? JSON.parse(item.projectApprovalSpeedAgentPayment).map(item => {item.isDisabled = true; return item}) : [{
				              amount: null, //	''支付金额',
				              source: null, //	'['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
				              ticket: [], //	''发票',
				              voucher: [], //	''支付凭证'
				          }],
				// projectApprovalSpeedAgentPayment: [
				//           {
				//               amount: null, //	''支付金额',
				//               source: null, //	'['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
				//               ticket: [], //	''发票',
				//               voucher: [], //	''支付凭证'
				//           }
				//       ]
			},
			// //  17-9.其他中介服务
			other: {
				projectApprovalSpeedOther: item.projectApprovalSpeedOther ? JSON.parse(item.projectApprovalSpeedOther).map(item => {
					item.isDisabled = true;
					if(item.payment && item.payment.length){
						item.payment.map((paymentItem)=>{
							paymentItem.isDisabled = true;
						})
					}
					return item
				}) : [    {
				        service: '', //	'服务名称',
				        notice: [], //	'中标通知书或成交确认书',
				        contract: [], //	'合同',
				        cost: null, //	'合同金额',
				        payment: [
				            {
				                amount: null, //	'支付金额',
				                source:null, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
				                ticket: [], //	'发票',
				                voucher: [], //	'支付凭证'
				            },
				        ]
				    }],
				// projectApprovalSpeedOther: [
				//     {
				//         service: '', //	'服务名称',
				//         notice: [], //	'中标通知书或成交确认书',
				//         contract: [], //	'合同',
				//         cost: null, //	'合同金额',
				//         payment: [
				//             {
				//                 amount: null, //	'支付金额',
				//                 source:null, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
				//                 ticket: [], //	'发票',
				//                 voucher: [], //	'支付凭证'
				//             },
				//         ]
				//     }
				// ]
			},
		}
	}
	return tempObj
}
export function inviteTendersEcho(item) { // 项目招标信息回显
	let tempObj = {}
	tempObj = {
		//  1.县政府常务会议纪要
		summary: {
			inviteTendersSummaryType: item.inviteTendersSummaryType ? Number(item.inviteTendersSummaryType) : 1, //	 ['1.EPC项目' , '0.非EPC项目'],
			inviteTendersSummaryFile: item.inviteTendersSummaryFile || [], //	'会议纪要'
		},
		//  2.班子会会议纪要
		meeting: {
			inviteTendersMeetingSummary: item.inviteTendersMeetingSummary || [], //	'会议纪要'
		},
		//  3.中标信息
		success: {
			inviteTendersSuccessCompany: item.inviteTendersSuccessCompany || '', // '中标公司名称',
			inviteTendersSuccessPrice: item.inviteTendersSuccessPrice || 0, //	'工程中标价',
			inviteTendersSuccessNoticeFile: item.inviteTendersSuccessNoticeFile || [], //	'中标通知书/交易确认书',
			inviteTendersSuccessContract: item.inviteTendersSuccessContract || [], //	'中标合同',
			inviteTendersSuccessWay: item?.inviteTendersSuccessWay || null //  '招标方式'
		},
		//  4.监理信息
		watcher: {
			inviteTendersHasWatcher: item.inviteTendersHasWatcher - 0 ||0, //	['0：有监理','1：有监理(包含在全过程咨询服务中)','2：无监理','3：不需要',],
			inviteTendersWatcherCompany: item.inviteTendersWatcherCompany || '', //	'监理公司名称',
			inviteTendersWatcherNoticeFile: item.inviteTendersWatcherNoticeFile || [], //	'通知书或成交确认书',
			inviteTendersWatcherSummary: item.inviteTendersWatcherSummary || [], //	'会议纪要',
			inviteTendersWatcherContract: item.inviteTendersWatcherContract || [], //	'合同',
			inviteTendersWatcherPrice: item.inviteTendersWatcherPrice || null, //	'合同金额',
			inviteTendersWatcherPayment: (item.inviteTendersWatcherPayment && JSON.parse(item.inviteTendersWatcherPayment).map(item => { item.isDisabled = true; return item })) || [{
					amount: null, //	'付款金额',
					source: 0, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
					applyFile: [], //	'请款审批表',
					ticket: [], //	'发票',
					voucher: [], //	'支付凭证',
				}]
			// inviteTendersWatcherPayment: [
			// 	{
			// 		amount: null, //	'付款金额',
			// 		source: 0, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
			// 		applyFile: [], //	'请款审批表',
			// 		ticket: [], //	'发票',
			// 		voucher: [], //	'支付凭证',
			// 	}
			// ],
			// /this.changeDefaultAduit()
		},
		//  5.跟踪审计信息
		flowable: {
			inviteTendersHasFlowable: item.inviteTendersHasFlowable - 0 || 0, //	['0：有跟踪审计','1：无跟踪审计','2：不需要'],
			inviteTendersFlowableCompany: item.inviteTendersFlowableCompany || '', //	'跟踪审计名称',
			inviteTendersFlowableNoticeFile: item.inviteTendersFlowableNoticeFile || [], //	'通知书或成交确认书',
			inviteTendersFlowableSummary: item.inviteTendersFlowableSummary || [], //	'会议纪要',
			inviteTendersFlowableContract: item.inviteTendersFlowableContract || [], //	'合同',
			inviteTendersFlowablePrice: item.inviteTendersFlowablePrice || null, //	'合同金额',
			inviteTendersFlowablePayment: (item.inviteTendersFlowablePayment && JSON.parse(item.inviteTendersFlowablePayment) && JSON.parse(item.inviteTendersFlowablePayment).map(item => {item.isDisabled = true; return item;})) || [{
					amount: null, //	'付款金额',
					source: null, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
					applyFile: [], //	'请款审批表',
					ticket: [], //	'发票',
					voucher: [], //	'支付凭证',
				}]
			// inviteTendersFlowablePayment: [
			// 	{
			// 		amount: null, //	'付款金额',
			// 		source: null, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
			// 		applyFile: [], //	'请款审批表',
			// 		ticket: [], //	'发票',
			// 		voucher: [], //	'支付凭证',
			// 	}
			// ],
		},
		//  6.全过程咨询信息
		saf: {
			inviteTendersHasSaf: item.inviteTendersHasSaf - 0 ||
				0, //	['0：有全过程咨询','1：有全过程咨询(含监理服务)','2：无全过程咨询','3：不需要',],
			inviteTendersSafCompany: item.inviteTendersSafCompany || '', //	'全过程咨询公司名称',
			inviteTendersSafNoticeFile: item.inviteTendersSafNoticeFile || [], //	'通知书或成交确认书',
			inviteTendersSafSummary: item.inviteTendersSafSummary || [], //	'会议纪要',
			inviteTendersSafContract: item.inviteTendersSafContract || [], //	'合同',
			inviteTendersSafPrice: item.inviteTendersSafPrice || null, //	'合同金额',
			inviteTendersSafPayment: (item.inviteTendersSafPayment && JSON.parse(item.inviteTendersSafPayment).map(item => { item.isDisabled = true; return item })) || [{
					amount:null, //	'付款金额',
					source: null, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
					applyFile: [], //	'请款审批表',
					ticket: [], //	'发票',
					voucher: [], //	'支付凭证',
				}]
			// inviteTendersSafPayment: [
			// 	{
			// 		amount:null, //	'付款金额',
			// 		source: null, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
			// 		applyFile: [], //	'请款审批表',
			// 		ticket: [], //	'发票',
			// 		voucher: [], //	'支付凭证',
			// 	}
			// ],
		},

	}
	return tempObj
}
export function constructionEcho(item) { // 项目施工信息回显
	let tempObj = {}
	tempObj = {
		//  1 许可证或开工报告
		permit: {
			constructionHasPermit: item.constructionHasPermit - 0 || 0, //	['0：有许可证或开工报告','1：无许可证或开工报告'],
			constructionPermitBuildFile: item.constructionPermitBuildFile || [], //	'施工许可证',
			constructionPermitStartReport: item.constructionPermitStartReport || [], //	'开工报告',
			constructionPermitBuildTime: item.constructionPermitBuildTime || '',//施工许可证办证时间
			constructionPermitStartReportTime: item.constructionPermitStartReportTime || '',//开工报告办证时间
		},
		//  2 项目变更
		change: {
			constructionHasChange: item.constructionHasChange - 0 || 0, //	['0：有变更','1：无变更'],
			constructionChangeAddAmount:item.constructionChangeAddAmount || null, //增量金额
			constructionChangeRequest: item.constructionChangeRequest || [], //	'增量申请文件',
			constructionChangeResponse: item.constructionChangeResponse || [], //	'增量申请文件'
			constructionChangeResponseTime: item.constructionChangeResponseTime || '',//增量批复办证时间
		},
		//  3 项目设备询价
		inquiry: {
			constructionHasInquiry: item.constructionHasInquiry - 0 || 0, //	['0：有设备询价','1：有设备询价(无询价公司)','2：无设备询价'],
			constructionInquiryCompany: item.constructionInquiryCompany || '', //	'询价公司名称',
			constructionInquiryNoticeFile: item.constructionInquiryNoticeFile || [], //	'中标通知书或成交确认书',
			constructionInquirySummary: item.constructionInquirySummary || [], //	'会议纪要',
			constructionInquiryContract: item.constructionInquiryContract || [], //	'合同',
			constructionInquiry: item.constructionInquiry || [], //	'同意询价批复',
			constructionInquiryResult: item.constructionInquiryResult || [], //	'询价结果',
			constructionInquiryCost: item.constructionInquiryCost || null, //	'合同金额',
			constructionInquiryTime: item.constructionInquiryTime || '',//同意询价批复办证时间
			constructionInquiryPayment: (item.constructionInquiryPayment && JSON.parse(item.constructionInquiryPayment).map(item => {item.isDisabled = true; return item;})) || [{
			           amount: null, //	'付款金额',
			           source: null, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
			           applyFile: [], //	'请款审批表',
			           ticket: [], //	'发票',
			           voucher: [], //	'支付凭证',
			       }]
			// constructionInquiryPayment: [
			//        {
			//            amount: null, //	'付款金额',
			//            source: null, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
			//            applyFile: [], //	'请款审批表',
			//            ticket: [], //	'发票',
			//            voucher: [], //	'支付凭证',
			//        },
			//    ]
		},
		//  工程进度
		schedule: {
			constructionSchedulePercent: item.constructionSchedulePercent || null, //	'工程完成量/元',
			constructionSchedulePayPercent: item.constructionSchedulePayPercent ? Number(item.constructionSchedulePayPercent) : 0.7, //	'项目办付款比例', ??
		},
		//  工程款付款
		payment: {
			constructionPayment: (item.constructionPayment && JSON.parse(item.constructionPayment).map((item)=>{
				item.isDisabled = true;
				return item;
			})) || [{type: 0,amount: null,source: null,applyFile: [],ticket:  [],voucher: [],progress: [],audit: []}] // 后端返回异常
			// constructionPayment: [{
			// 	type: 0,
			// 	amount: null,
			// 	source: null,
			// 	applyFile: [],
			// 	ticket: [],
			// 	voucher: [],
			// 	progress: [],
			// 	audit: []
			// }]
		},
		//  审计
		audit: {
			constructionHasAudit: item.constructionHasAudit - 0 || 1, //	['0：有审计','1：有审计(无询价公司)','2：无审计','3：不需要'],
			constructionAuditCompany: item.constructionAuditCompany || '', //	'审计公司名称',
			constructionAuditNoticeFile: item.constructionAuditNoticeFile || [], //	'中标通知书或成交确认书',
			constructionAuditSummary: item.constructionAuditSummary || [], //	'会议纪要',
			constructionAuditContract: item.constructionAuditContract || [], //	'合同',
			constructionAuditCost: item.constructionAuditCost || null, //	'合同金额',
			constructionAuditPayment: item.constructionAuditPayment ? JSON.parse(item
				.constructionAuditPayment).map((item)=>{item.isDisabled = true; return item;}) : [{
				amount: null,
				source: null,
				applyFile: [],
				ticket: [],
				voucher: []
			}]
			// constructionAuditPayment: [{amount: null, source: null,applyFile: [],ticket: [],voucher: []}]
		},
	}
	return tempObj
}
export function completionEcho(item) { // 竣工验收信息回显
	let tempObj = {}
	tempObj = {
		//  1.避雷检查
		lpi: {
			completionHasLpi: item.completionHasLpi - 0 || 0, //	['0：有避雷检查', '1：无避雷检查','2：不需要'],
			completionLpiReport: item.completionLpiReport || [], //	'避雷检查报告',
		},
		//  2.消防验收报告
		fire: {
			completionHasFire: item.completionHasFire - 0 || 0, //	['0：有消防验收', '1：无消防验收','2：不需要'],
			completionFireReport: item.completionFireReport || [], //	'消防验收报告'
		},
		//  3.建设规划验收报告
		build: {
			completionHasBuild: item.completionHasBuild - 0 || 0, //	['0：有建设规划验收', '1：无建设规划验收','2：不需要'],
			completionBuildReport: item.completionBuildReport || [], //	'建设规划验收报告'
		},
		//  4.环评验收报告
		eia: {
			completionHasEia: item.completionHasEia - 0 || 0, //	['0：有环评验收', '1：无环评验收','2：不需要'],
			completionEiaReport: item.completionEiaReport || [], //	'.环评验收报告'
		},
		//  5.水土保持验收报告
		keep: {
			completionHasKeep: item.completionHasKeep  - 0|| 0, //	['0：有水保验收', '1：无水保验收','2：不需要'],
			completionKeepReport: item.completionKeepReport || [], //	'水土保持验收报告'
		},
		//  6.竣工验收报告
		completed: {
			completionReport: item.completionReport || [], //	交工验收报告,
			completionCompletedReport: item.completionCompletedReport || [], //	'.竣工验收报告',
		},
		//  7.竣工验收备案表
		filing: {
			completionHasFiling: item.completionHasFiling - 0 || 0, //	['0：有竣工验收备案', '1：无竣工验收备案','2：不需要'],
			completionFilingReport: item.completionFilingReport || [], //	'.竣工验收备案',
		},
		//  8.本阶聘请第三方报告
		other: {
			completionHasOrder: item.completionHasOrder - 0 || 0, //['0有第三方相关资料，1无第三方相关资料‘]
			completionOther: (item.completionOther && JSON.parse(item.completionOther).map(item=>{
				item.isDisabled = true;
				if(item.payment && item.payment.length){
					item.payment.map(paymentItem=>{
						paymentItem.isDisabled = true;
						return paymentItem;
					})
				}
				return item;
			})) || [{
			          costType: 0, //	['0.避雷检查', '1.消防验收', '2.建设规划验收', '3.环评验收水', '4.保验收交', '5.竣工验收', '6.竣工备案', '7.其他',],
			          costName: '', //	'费用名称',
			          company: '', //	'第三方公司名称',
			          summary: [], //	'聘请第三方会议纪要',
			          procedures: [], //	'聘请第三方的确认手续',
			          contract: [], //	'聘请第三方的合同',
			          cost: null, //	'合同总金额',
			          payment: [
			              {
			                  amount: null, //	'付款金额',
			                  source: null, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
			                  ticket: [], //	'发票',
			                  voucher: [], //	'支付凭证'
			              },
			          ]
			      }]
			//   completionOther: [
			//       {
			//           costType: 0, //	['0.避雷检查', '1.消防验收', '2.建设规划验收', '3.环评验收水', '4.保验收交', '5.竣工验收', '6.竣工备案', '7.其他',],
			//           costName: '', //	'费用名称',
			//           company: '', //	'第三方公司名称',
			//           summary: [], //	'聘请第三方会议纪要',
			//           procedures: [], //	'聘请第三方的确认手续',
			//           contract: [], //	'聘请第三方的合同',
			//           cost: null, //	'合同总金额',
			//           payment: [
			//               {
			//                   amount: null, //	'付款金额',
			//                   source: null, //	['0.上级专项资金' ,'1.县国资公司', '2.县本级财政资金', '3.其他'],
			//                   ticket: [], //	'发票',
			//                   voucher: [], //	'支付凭证'
			//               },
			// 		// {amount: null,source: null,ticket: [],voucher: []}
			//           ]
			//       },
			// // {costType: 0,costName: '',company: '',summary: [],procedures: [],contract: [],cost: null,payment: [{amount: null,source: null,ticket: [],voucher: []}]}
			//   ]
		},

		//  9.项目财务决算报告
		accounts: {
			completionAccountsCost: item.completionAccountsCost || null, //	'决算金额',
			completionAccountsReport: item.completionAccountsReport || [], //	'项目财务决算报告'
		},
	}
	return tempObj
}